import { Icon } from "@chakra-ui/react";

export const CustomDoubleChevronIcon = (props) => (
  <Icon viewBox="0 0 14 12" fill="none" {...props}>
    <path
      d="M12.6805 11L7.68048 6L12.6805 1M5.68048 11L0.680481 6L5.68048 1"
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
