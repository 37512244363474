import Pusher from "pusher-js";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../../context/AuthContext";
import ClientCandidateChatContext from "../../../../../context/ClientCandidateChatContext";
import UnreadMessagesEvent from "./UnreadMessagesEvent";

const appUrl = process.env.REACT_APP_API_URL;
const pusherKey = process.env.REACT_APP_PUSHER_KEY;

const MessagesSubscribe = () => {
  const {
    authState: {
      token,
      user: { type, uuid, membershipStatus },
    },
  } = useContext(AuthContext);
  const { updatePusherObj, updateChannelValue } = useContext(
    ClientCandidateChatContext
  );

  const [channel, setChannel] = useState(null);

  useEffect(() => {
    if (type === "type_client" && !membershipStatus) return;
    const pusher = new Pusher(pusherKey, {
      channelAuthorization: {
        endpoint: `${appUrl}/realtime/pusher/authorization`,
        headers: { "X-Auth-Token": token },
      },
      cluster: "eu",
    });
    const channelValue = pusher.subscribe(`private-messages${uuid}`);

    updatePusherObj(pusher);
    updateChannelValue(channelValue);

    setChannel(channelValue);

    channelValue.bind("pusher:subscription_error", (err) => {
      if (err.status === 401) {
        console.log("pusher 401");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <UnreadMessagesEvent channel={channel} />;
};

export default MessagesSubscribe;
