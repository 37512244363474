import React, { useContext, useEffect, useRef, useState } from "react";
import { Outlet } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import ClientCandidateChatContext from "../../../../../context/ClientCandidateChatContext";
import NotificationSoundChromeMozilla from "../../../../../assets/sounds/new-message-notification.ogg";
import NotificationSound from "../../../../../assets/sounds/new-message-notification.mp3";
import { getAllThreadsFn } from "../../../../../services/apiServices";
import { getErrorResponsePayload } from "../../../../../utils/helpers";
import CustomToast from "../../UI/CustomToast";

const NewMessageEvent = ({ channel }) => {
  // const [channel] = useOutletContext();
  const {
    newMessage,
    updateNewMessage,
    chatListState,
    updateChatList,
    chatType,
    itemsPerPage,
    pageNumber,
  } = useContext(ClientCandidateChatContext);
  const [firstPageRefreshEnabled, setFirstPageRefreshEnabled] = useState(false);
  const toast = useToast();
  const audioPlayer = useRef(null);

  function playNotificationSound() {
    audioPlayer.current.play();
  }

  //const pageSize = chatListState.items?.length;
  useQuery({
    queryKey: ["refetchThreadListFirstPage"],
    queryFn: () =>
      getAllThreadsFn({
        pageSize: itemsPerPage,
        pageIndex: 0,
        chatType: chatType.status,
      }),
    onSuccess: ({ data, meta }) => {
      setFirstPageRefreshEnabled(false);
      updateChatList({
        items: [...data],
        total: meta?.total,
        pageCount: Math.ceil(meta?.total / itemsPerPage),
      });
    },
    onError: (error) => {
      const { message } = getErrorResponsePayload(error);
      toast({
        position: "top",
        render: (props) => (
          <CustomToast
            status="error"
            title={message ? message : `Something went wrong`}
            description={"Please try again later"}
            onClose={props.onClose}
            id={props.id}
          />
        ),
      });
    },
    staleTime: 0,
    enabled: firstPageRefreshEnabled,
  });

  // useEffect(() => {
  //   function unlockAudio() {
  //     const sound = new Audio(NotificationSound);

  //     sound.play();
  //     sound.pause();
  //     sound.currentTime = 0;

  //     document.body.removeEventListener("click", unlockAudio);
  //     document.body.removeEventListener("touchstart", unlockAudio);
  //   }

  //   document.body.addEventListener("click", unlockAudio);
  //   document.body.addEventListener("touchstart", unlockAudio);
  // }, []);

  // function soundNotification() {
  //   const sound = new Audio(NotificationSound);

  //   const promise = sound.play();

  //   if (promise !== undefined) {
  //     promise.then(() => {}).catch((error) => console.error);
  //   }
  // }
  // const audioCtxContainer = useRef(null);
  // const [test, setTest] = useState(null);
  // const [test2, setTest2] = useState(0);
  // const testRef = useRef(null);
  // useEffect(() => {
  //   const AudioContext = window.AudioContext || window.webkitAudioContext;
  //   audioCtxContainer.current = new AudioContext();

  //   fetch("https://images.skidos.com/video-js/button_pressed.mp3")
  //     .then((response) => response.arrayBuffer())
  //     .then((buffer) => audioCtxContainer.current.decodeAudioData(buffer))
  //     .then((buffer) => {
  //       console.log("buffer123123", buffer);
  //       var track = audioCtxContainer.current.createBufferSource();
  //       track.buffer = buffer;
  //       track.connect(audioCtxContainer.current.destination);
  //       track.start(0);
  //       console.log(buffer);
  //       setTest(buffer);
  //     });
  // }, [test2]);

  // const onPlayPause = () => {
  //   alert("play");
  //   audioCtxContainer.current.resume();
  //   playSound(test);
  //   setTest2((prev) => prev + 1);
  // };

  // const playSound = (buffer) => {
  //   const sourceNode = audioCtxContainer.current.createBufferSource();
  //   sourceNode.buffer = buffer;
  //   sourceNode.connect(audioCtxContainer.current.destination);
  // };

  //fetch new message from pusher
  useEffect(() => {
    if (channel) {
      channel.bind("new_message_event", function (data) {
        updateNewMessage(data);
        playNotificationSound();
      });
    }

    return () => {
      if (channel) {
        channel.unbind("new_message_event");
      }
    };
    // eslint-disable-next-line
  }, [channel]);

  useEffect(() => {
    if (chatListState.items.length === 0) {
      if (
        (chatType.status === "pending" &&
          newMessage?.thread?.status !== "pending") ||
        (chatType.status === "declined" &&
          newMessage?.thread?.status !== "declined") ||
        (chatType.status === "accepted" &&
          newMessage?.thread?.status !== "accepted")
      ) {
        return;
      }
      //queryClient.invalidateQueries({ queryKey: ["getAllThreadsFn"] });
      setFirstPageRefreshEnabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newMessage]);

  // find and update that message thread in the list
  useEffect(() => {
    // initially there are 5 items in the list that are empty - because of skeleton loader
    if (chatListState?.items.length !== 0 && chatListState?.items[0]?.status) {
      const newMessageThreadUUID = newMessage?.thread.uuid;
      const newLastMessageBody = newMessage?.message.body;
      const newLastMessageCreatedAt = newMessage?.message.createdAt;

      const thread = chatListState.items.filter(
        (item) => item.uuid === newMessageThreadUUID
      );

      if (thread.length === 0) {
        // if there is a new message but cant find that message thread while you are on the first page - reload first page and it will show up

        if (
          (chatType.status === "pending" &&
            newMessage?.thread?.status !== "pending") ||
          (chatType.status === "declined" &&
            newMessage?.thread?.status !== "declined") ||
          (chatType.status === "accepted" &&
            newMessage?.thread?.status !== "accepted")
        ) {
          return;
        }

        if (pageNumber === 0) {
          setFirstPageRefreshEnabled(true);
        }
        return;
      }

      const status =
        thread[0].status === "pending"
          ? newMessage.thread.status === "declined"
            ? "declined"
            : "accepted"
          : thread[0].status;
      const numberOfUnreadMessages = thread[0].numberOfUnreadMessages + 1;
      const updatedThread = {
        ...thread[0],
        lastMessageBody: newLastMessageBody,
        lastMessageCreatedAt: newLastMessageCreatedAt,
        numberOfUnreadMessages,
        status,
      };

      if (pageNumber === 0) {
        // if you are on the first page put new message thread on the top of the page
        const unchangedChatListItems = chatListState.items.filter(
          (item) => item.uuid !== newMessageThreadUUID
        );

        updateChatList({
          ...chatListState,
          items: [updatedThread, ...unchangedChatListItems],
        });

        return;
      }

      const updatedChatListItems = chatListState.items.map((item) => {
        if (updatedThread.uuid === item.uuid) {
          return updatedThread;
        }
        return item;
      });

      updateChatList({
        ...chatListState,
        items: [...updatedChatListItems],
      });
    }

    // eslint-disable-next-line
  }, [newMessage]);

  return (
    <>
      <audio ref={audioPlayer}>
        {/* AAC file (Chrome/Safari/IE9) */}
        <source src={NotificationSound} type="audio/mpeg" />
        {/* Ogg Vorbis (Firefox) */}
        <source src={NotificationSoundChromeMozilla} type="audio/ogg" />
      </audio>
      {/* <Box
        position="absolute"
        top="50%"
        left="0"
        width="200px"
        bg="red"
        zIndex="100"
      >
        <Button onClick={onPlayPause} ref={testRef}>
          TEST
        </Button>
      </Box> */}
      <Outlet />
    </>
  );
};

export default NewMessageEvent;
