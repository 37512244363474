import { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { AuthContext } from "../context/AuthContext";
import { getMeFn } from "../services/apiServices";

const GetLoggedUser = ({ children }) => {
  const { setAuthUser, getUser } = useContext(AuthContext);

  const fetchDataFlag = !getUser();

  const { isSuccess } = useQuery({
    queryKey: ["GetMe"],
    queryFn: getMeFn,
    enabled: fetchDataFlag,
    onSuccess: (response) => {
      setAuthUser(response?.data);
    },
    onError: (error) => {},
    staleTime: 1000 * 60 * 5,
  });

  if (isSuccess || !fetchDataFlag) return <>{children}</>;
  return null;
};

export default GetLoggedUser;
