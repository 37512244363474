import { Icon } from "@chakra-ui/react";

export const CustomExperienceIcon = (props) => (
  <Icon viewBox="0 0 59 59" fill="none" {...props}>
    <circle cx="29.5781" cy="29.6431" r="28.8848" fill={props.bgcircle} />
    <rect
      x="15.5508"
      y="21.1333"
      width="25.7812"
      height="18.5312"
      fill={props.whitebg}
    />
    <g clipPath="url(#clip0_1666_7197)">
      <path
        d="M40.0338 20.3912H34.2005V19.2245C34.2005 17.9376 33.1541 16.8911 31.8672 16.8911H24.8672C23.5803 16.8911 22.5338 17.9376 22.5338 19.2245V20.3911H16.7005C15.4136 20.3912 14.3672 21.4376 14.3672 22.7245V37.8911C14.3672 39.178 15.4136 40.2245 16.7005 40.2245H40.0339C41.3207 40.2245 42.3672 39.178 42.3672 37.8911V22.7245C42.3672 21.4376 41.3207 20.3912 40.0338 20.3912ZM23.7005 19.2245C23.7005 18.5813 24.2241 18.0578 24.8672 18.0578H31.8672C32.5103 18.0578 33.0338 18.5813 33.0338 19.2245V20.3911H23.7005V19.2245ZM41.2005 37.8912C41.2005 38.5343 40.677 39.0578 40.0339 39.0578H16.7005C16.0574 39.0578 15.5339 38.5343 15.5339 37.8912V29.4C15.8785 29.6009 16.2736 29.7245 16.7005 29.7245H26.0339V31.4745C26.0339 31.797 26.2948 32.0579 26.6172 32.0579H30.1172C30.4397 32.0579 30.7006 31.797 30.7006 31.4745V29.7245H40.0339C40.4608 29.7245 40.8561 29.601 41.2006 29.4V37.8912H41.2005ZM27.2005 30.8912V28.5578H29.5339V30.8912H27.2005ZM41.2005 27.3912C41.2005 28.0343 40.677 28.5578 40.0339 28.5578H30.7005V27.9745C30.7005 27.652 30.4396 27.3911 30.1172 27.3911H26.6172C26.2947 27.3911 26.0338 27.652 26.0338 27.9745V28.5578H16.7005C16.0574 28.5578 15.5339 28.0343 15.5339 27.3912V22.7245C15.5339 22.0814 16.0574 21.5579 16.7005 21.5579H40.0339C40.677 21.5579 41.2005 22.0814 41.2005 22.7245V27.3912Z"
        fill={props.blackstroke}
        stroke={props.blackstroke}
        strokeWidth="0.3"
      />
    </g>
    <circle
      cx="41.7292"
      cy="39.6638"
      r="6.30984"
      fill={props.bglime}
      stroke={props.blackstroke}
      strokeWidth="1.5"
    />
    <path
      d="M44.8569 40.4887L41.732 40.4878L41.7294 35.9355"
      stroke={props.blackstroke}
      strokeWidth="1.5"
    />
    <defs>
      <clipPath id="clip0_1666_7197">
        <rect
          width="28"
          height="28"
          fill="white"
          transform="translate(14.3672 14.5591)"
        />
      </clipPath>
    </defs>
  </Icon>
);
