import { Icon } from "@chakra-ui/react";

export const CustomDocIcon = (props) => (
  <Icon viewBox="0 0 31 33" fill="none" {...props}>
    <path
      d="M30.314 9.10092L22.1824 0.651444C22.0666 0.531253 21.9069 0.463303 21.7401 0.463303H9.62064C8.26431 0.463303 7.16083 1.56634 7.16083 2.92211V11.5118H2.24927C1.2345 11.5118 0.408936 12.3372 0.408936 13.3517V22.562C0.408936 23.5765 1.2345 24.4018 2.24927 24.4018H7.16083V29.9268C7.16083 31.2802 8.26431 32.3813 9.62064 32.3813H28.0257C29.382 32.3813 30.4855 31.2808 30.4855 29.9281V9.52657C30.4855 9.36785 30.424 9.21531 30.314 9.10092ZM21.8922 2.12036L28.5675 9.05659H21.8922V2.12036ZM2.24927 23.1742C1.91139 23.1742 1.63659 22.8996 1.63659 22.562V13.3517C1.63659 13.0141 1.91145 12.7394 2.24927 12.7394H18.8243C19.1622 12.7394 19.437 13.0141 19.437 13.3517V22.562C19.437 22.8996 19.1621 23.1742 18.8243 23.1742H2.24927ZM29.2579 29.9281C29.2579 30.6039 28.7051 31.1537 28.0256 31.1537H9.62064C8.94119 31.1537 8.38843 30.6033 8.38843 29.9268V24.4018H18.8243C19.8391 24.4018 20.6646 23.5765 20.6646 22.562V13.3517C20.6646 12.3372 19.8391 11.5118 18.8243 11.5118H8.38843V2.92211C8.38843 2.24317 8.94119 1.6909 9.62064 1.6909H20.6646V9.67039C20.6646 10.0094 20.9394 10.2842 21.2784 10.2842H29.2579V29.9281Z"
      fill="#333333"
    />
    <rect
      x="0.408936"
      y="11.4438"
      width="20.3516"
      height="13.3125"
      rx="1"
      fill="#CDEE24"
    />
    <path
      d="M4.91577 20.6H3.11108V15.5091H4.93069C5.44276 15.5091 5.88357 15.611 6.25313 15.8149C6.62268 16.017 6.90689 16.3079 7.10576 16.6874C7.30628 17.0669 7.40654 17.521 7.40654 18.0496C7.40654 18.5799 7.30628 19.0356 7.10576 19.4168C6.90689 19.7979 6.62103 20.0904 6.24816 20.2943C5.87695 20.4981 5.43282 20.6 4.91577 20.6ZM4.18743 19.6778H4.87103C5.18921 19.6778 5.45685 19.6215 5.67394 19.5088C5.89269 19.3944 6.05675 19.2179 6.16613 18.9793C6.27716 18.739 6.33267 18.4291 6.33267 18.0496C6.33267 17.6734 6.27716 17.366 6.16613 17.1274C6.05675 16.8887 5.89352 16.7131 5.67642 16.6004C5.45933 16.4877 5.19169 16.4313 4.87351 16.4313H4.18743V19.6778Z"
      fill="#333333"
    />
    <path
      d="M12.8821 18.0546C12.8821 18.6097 12.7769 19.082 12.5664 19.4715C12.3576 19.8609 12.0726 20.1584 11.7113 20.3639C11.3517 20.5677 10.9473 20.6696 10.4982 20.6696C10.0458 20.6696 9.63982 20.5669 9.28021 20.3614C8.9206 20.1559 8.63639 19.8584 8.42758 19.469C8.21877 19.0795 8.11437 18.6081 8.11437 18.0546C8.11437 17.4994 8.21877 17.0271 8.42758 16.6377C8.63639 16.2482 8.9206 15.9516 9.28021 15.7478C9.63982 15.5423 10.0458 15.4395 10.4982 15.4395C10.9473 15.4395 11.3517 15.5423 11.7113 15.7478C12.0726 15.9516 12.3576 16.2482 12.5664 16.6377C12.7769 17.0271 12.8821 17.4994 12.8821 18.0546ZM11.7909 18.0546C11.7909 17.695 11.737 17.3917 11.6293 17.1448C11.5232 16.8978 11.3732 16.7106 11.1794 16.583C10.9855 16.4554 10.7584 16.3916 10.4982 16.3916C10.2381 16.3916 10.011 16.4554 9.81714 16.583C9.62325 16.7106 9.47244 16.8978 9.36472 17.1448C9.25866 17.3917 9.20563 17.695 9.20563 18.0546C9.20563 18.4142 9.25866 18.7174 9.36472 18.9644C9.47244 19.2113 9.62325 19.3986 9.81714 19.5262C10.011 19.6538 10.2381 19.7176 10.4982 19.7176C10.7584 19.7176 10.9855 19.6538 11.1794 19.5262C11.3732 19.3986 11.5232 19.2113 11.6293 18.9644C11.737 18.7174 11.7909 18.4142 11.7909 18.0546Z"
      fill="#333333"
    />
    <path
      d="M18.1713 17.2914H17.0825C17.0626 17.1506 17.022 17.0255 16.9607 16.9161C16.8994 16.805 16.8207 16.7106 16.7245 16.6327C16.6284 16.5548 16.5174 16.4951 16.3914 16.4537C16.2672 16.4123 16.1321 16.3916 15.9863 16.3916C15.7228 16.3916 15.4932 16.457 15.2977 16.588C15.1021 16.7172 14.9505 16.9061 14.8428 17.1547C14.7351 17.4016 14.6812 17.7016 14.6812 18.0546C14.6812 18.4175 14.7351 18.7224 14.8428 18.9693C14.9522 19.2163 15.1046 19.4027 15.3002 19.5286C15.4957 19.6546 15.7219 19.7176 15.9788 19.7176C16.123 19.7176 16.2564 19.6985 16.379 19.6604C16.5033 19.6223 16.6135 19.5668 16.7096 19.4938C16.8057 19.4193 16.8853 19.329 16.9483 19.2229C17.0129 19.1168 17.0576 18.9959 17.0825 18.86L18.1713 18.8649C18.1431 19.0986 18.0727 19.324 17.96 19.5411C17.849 19.7565 17.699 19.9496 17.5101 20.1203C17.3228 20.2893 17.0991 20.4235 16.8389 20.523C16.5804 20.6207 16.2879 20.6696 15.9614 20.6696C15.5073 20.6696 15.1013 20.5669 14.7434 20.3614C14.3871 20.1559 14.1053 19.8584 13.8982 19.469C13.6927 19.0795 13.59 18.6081 13.59 18.0546C13.59 17.4994 13.6944 17.0271 13.9032 16.6377C14.112 16.2482 14.3954 15.9516 14.7533 15.7478C15.1113 15.5423 15.514 15.4395 15.9614 15.4395C16.2564 15.4395 16.5298 15.4809 16.7817 15.5638C17.0353 15.6467 17.2598 15.7676 17.4554 15.9267C17.6509 16.0842 17.81 16.2772 17.9326 16.5059C18.0569 16.7346 18.1365 16.9965 18.1713 17.2914Z"
      fill="#333333"
    />
  </Icon>
);
