import { Textarea } from "@chakra-ui/react";
import ResizeTextarea from "react-textarea-autosize";
import React from "react";

export const CustomTextarea = React.forwardRef((props, ref) => {
  return (
    <Textarea
      overflow={props.overflow ? props.overflow : "hidden"}
      w="100%"
      resize="none"
      ref={ref}
      as={ResizeTextarea}
      {...props}
    />
  );
});
