import { Box, Circle, Heading, Link, Text } from '@chakra-ui/react';
import React, { Fragment } from 'react';
import Slider from 'react-slick';
import news1 from "../../assets/images/public/latest_news/news1.jfif"
import news2 from "../../assets/images/public/latest_news/news2.jfif"
import news3 from "../../assets/images/public/latest_news/news3.jfif"
import news4 from "../../assets/images/public/latest_news/news4.png"
import news5 from "../../assets/images/public/latest_news/news5.png"
import news6 from "../../assets/images/public/latest_news/news6.jfif"
import news7 from "../../assets/images/public/latest_news/news7.jfif"
import news8 from "../../assets/images/public/latest_news/news8.jfif"
import news9 from "../../assets/images/public/latest_news/news9.jfif"
import { CalendarIcon } from '@chakra-ui/icons';
const LatestNews = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        arrows: true,
        responsive: [
            {
              breakpoint: 991,
              settings: {
                slidesToShow:2,
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
              }
            }
          ]
    };

    const newsItems = [
        {
            image: news1,
            date: 'March 15, 2024',
            title: 'Interview Etiquette',
            url: 'https://www.linkedin.com/pulse/interview-etiquette-searchiveapp-2qctf'
        },
        {
            image: news2,
            date: 'October 17, 2023',
            title: 'Remote Work in Finance: Pros, Cons, and How to Succeed',
            url: 'https://www.linkedin.com/pulse/remote-work-finance-pros-cons-how-succeed-searchiveapp'
        },
        {
            image: news3,
            date: 'September 12, 2023',
            title: 'Navigating the Ever-Changing Landscape of Finance Recruitment',
            url: 'https://www.linkedin.com/pulse/navigating-ever-changing-landscape-finance-recruitment-searchiveapp/'
        },
        {
            image: news4,
            date: 'September 4, 2023',
            title: 'Tailoring Your CV for Success: Tips for Landing Your Dream Job',
            url: 'https://www.linkedin.com/pulse/tailoring-your-cv-success-tips-landing-dream-job-searchiveapp/'
        },
        {
            image: news5,
            date: 'August 22, 2023',
            title: 'Empowering Remote Finance Teams: Fostering Trust and Empathy in Leadership',
            url: 'https://www.linkedin.com/pulse/empowering-remote-finance-teams-fostering-trust-empathy-leadership/'
        },
        {
            image: news6,
            date: 'July 27, 2023',
            title: 'Empowering Finance Professionals with Technology-Driven Alternative Recruitment',
            url: 'https://www.linkedin.com/pulse/empowering-finance-professionals-technology-driven-alternative-1f/'
        },
        {
            image: news7,
            date: 'July 27, 2023',
            title: 'Empowering Finance Professionals with Technology-Driven Alternative Recruitment',
            url: 'https://www.linkedin.com/pulse/empowering-finance-professionals-technology-driven-alternative/'
        },
        {
            image: news8,
            date: 'May 26, 2023',
            title: '5 Unconventional Interview Tips for Forward Thinking Professionals',
            url: 'https://www.linkedin.com/pulse/5-unconventional-interview-tips-high-level-professionals/'
        },
        {
            image: news9,
            date: 'June 16, 2023',
            title: 'Unconventional Strategies for Creating an Irresistible Work Environment',
            url: 'https://www.linkedin.com/pulse/unconventional-strategies-creating-irresistible-work-environment/'
        },
    ];

    return (
        <Fragment>
            <Box className='lt_news_heading'>
                <Heading
                    fontSize={{ base: "2rem", lg: "3.125rem" }} //50px
                    lineHeight={{ base: "2.5rem", lg: "3.9375rem" }} //63px
                    textTransform="uppercase"
                    position="relative"
                >
                    <Text as="span" position="relative">
                        Latest Newsletter
                        <Box position="absolute" w="full" h="full" top="0" left="0">
                            <Circle
                                boxSize="1.125rem"
                                bgColor="electricLime"
                                position="absolute"
                                bottom="15%"
                                right="-25px"
                            />
                        </Box>
                    </Text>
                </Heading>
            </Box>
            <Box className='lt_news_slider' >
                <Slider {...settings}>
                    {newsItems.map((item, index) => (
                        <Link key={index} className="lt_news-item" href={item.url} target='_blank'>
                            <div className="lt_image-container">
                                <img src={item.image} alt={item.title} />
                                <div className="lt_overlay"></div>
                                <div className="lt_bottom-info">
                                    <div className="lt_date-overlay">
                                        <CalendarIcon />
                                        {item.date}</div>
                                    <h2 className="lt_title">{item.title}</h2>
                                </div>
                            </div>
                        </Link>
                    ))}
                </Slider>
            </Box>
        </Fragment>
    );
};

export default LatestNews;
