import { defineStyle, defineStyleConfig } from "@chakra-ui/styled-system";
import { Input } from "./input";

const baseStyle = defineStyle({
  //   ...Input.baseStyle?.field,
  borderRadius: "15px",
  paddingY: "2",
  minHeight: "20",
  lineHeight: "short",
  verticalAlign: "top",
});

const sizes = {
  md: defineStyle({
    fontSize: "1rem", // 16px
    px: "4",
    h: "10rem",
    borderRadius: "15px",
  }),
};

const variants = {
  outline: Input.variants.outline.field,
};

export const Textarea = defineStyleConfig({
  baseStyle,
  sizes,
  variants,
  defaultProps: {
    size: "md",
    variant: "outline",
  },
});
