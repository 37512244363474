import { Icon } from "@chakra-ui/react";

export const CustomPencilIcon = (props) => (
  <Icon viewBox="0 0 18 18" fill="none" {...props}>
    <g clipPath="url(#clip0_1997_13632)">
      <path
        d="M11.116 3.02344L1.21212 12.928C1.16229 12.978 1.12631 13.0411 1.10918 13.1089L0.0114342 17.515C-0.0214026 17.6477 0.0175732 17.7887 0.11437 17.8855C0.187611 17.9587 0.287406 17.9993 0.389485 17.9993C0.420752 17.9993 0.452732 17.9954 0.483855 17.9876L4.88998 16.8897C4.95865 16.8726 5.02104 16.8367 5.07087 16.7869L14.9756 6.88304L11.116 3.02344Z"
        fill="#CDEE24"
      />
      <path
        d="M17.4296 1.67239L16.3272 0.569932C15.5903 -0.166897 14.3061 -0.166183 13.5702 0.569932L12.2197 1.92038L16.0792 5.77984L17.4296 4.4294C17.7977 4.06148 18.0004 3.57178 18.0004 3.05096C18.0004 2.53014 17.7977 2.04045 17.4296 1.67239Z"
        fill="#CDEE24"
      />
    </g>
    <defs>
      <clipPath id="clip0_1997_13632">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </Icon>
);
