import { RegularText } from "./Texts";

const lastUpdatedDate = "March 14, 2023";

const Intro = () => {
  return (
    <>
      <RegularText text={`Last updated: ${lastUpdatedDate}`} />
      <RegularText text="This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You. " />
    </>
  );
};

export default Intro;
