import { Icon } from "@chakra-ui/react";

export const CustomBasicInfoIcon = (props) => (
  <Icon viewBox="0 0 59 59" fill="none" {...props}>
    <circle cx="29.5781" cy="29.6431" r="28.8848" fill={props.bgcircle} />
    <path
      d="M23.562 28.6656C21.2659 30.1445 19.7438 32.7241 19.7438 35.6604C19.7438 35.977 19.7615 36.2899 19.7961 36.5978C19.8689 37.2455 20.4236 37.6824 21.0227 37.6824H35.0934C35.6925 37.6824 36.2472 37.2455 36.32 36.5978C36.3546 36.2898 36.3723 35.977 36.3723 35.6604C36.3723 32.7248 34.851 30.1458 32.5558 28.6667C33.7662 27.4931 34.5198 25.8487 34.5198 24.028C34.5198 20.4601 31.6274 17.5677 28.0595 17.5677C24.4916 17.5677 21.5992 20.4601 21.5992 24.028C21.5992 25.8481 22.3523 27.4921 23.562 28.6656Z"
      fill="white"
      stroke="#333333"
      strokeWidth="1.5"
    />
    <circle
      cx="37.4325"
      cy="37.6806"
      r="6.30984"
      fill="#CDEE24"
      stroke="#333333"
      strokeWidth="1.5"
    />
    <path
      d="M34.4597 37.9274L36.028 39.1596C36.4453 39.4875 37.046 39.4323 37.3965 39.0339L40.4049 35.6154"
      stroke="#333333"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </Icon>
);
