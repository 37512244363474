import { Icon } from "@chakra-ui/react";

export const CustomSingleUserActivate = (props) => (
  <Icon viewBox="0 0 20 19" {...props}>
    <path
      d="M9.05657 10.6708C8.05854 10.6708 7.08286 10.3748 6.25299 9.82035C5.42313 9.26578 4.77633 8.47768 4.39439 7.55559C4.01244 6.6335 3.9125 5.61884 4.10722 4.63996C4.30193 3.66107 4.78255 2.7619 5.48829 2.05616C6.19403 1.35042 7.09319 0.869802 8.07206 0.675094C9.05096 0.480376 10.0657 0.580311 10.9877 0.96226C11.9098 1.3442 12.6979 1.991 13.2525 2.82086C13.807 3.65073 14.1029 4.62638 14.1029 5.62444C14.1006 6.9621 13.5682 8.24432 12.6223 9.19013C11.6764 10.136 10.3942 10.6685 9.05657 10.6708ZM9.05657 1.89456C8.31885 1.89456 7.59774 2.11331 6.98436 2.52316C6.37098 2.933 5.89292 3.51553 5.6106 4.19708C5.3283 4.87862 5.25444 5.62859 5.39836 6.35211C5.54227 7.07564 5.89752 7.74024 6.41915 8.26187C6.94078 8.78353 7.60538 9.13879 8.32894 9.28263C9.05245 9.42656 9.80237 9.35275 10.4839 9.07042C11.1655 8.78809 11.7481 8.31005 12.1578 7.69666C12.5677 7.08329 12.7865 6.36215 12.7865 5.62444C12.7842 4.63593 12.3904 3.68856 11.6915 2.98958C10.9924 2.29059 10.0451 1.89687 9.05657 1.89456Z"
      fill="#CDEE24"
      stroke="#CDEE24"
      strokeWidth="0.5"
    />
    <path
      d="M1.15822 17.6919C0.98435 17.6896 0.818252 17.6196 0.695297 17.4966C0.572351 17.3737 0.502273 17.2075 0.5 17.0337C0.504634 14.9985 1.31518 13.048 2.7543 11.6088C4.19342 10.1697 6.14396 9.35914 8.17918 9.35449H9.93442C10.109 9.35449 10.2764 9.42382 10.3998 9.54731C10.5233 9.6707 10.5926 9.83815 10.5926 10.0127C10.5926 10.1873 10.5233 10.3547 10.3998 10.4781C10.2764 10.6016 10.109 10.6709 9.93442 10.6709H8.17918C6.49238 10.6732 4.87534 11.3443 3.6826 12.5371C2.48986 13.7299 1.81876 15.3469 1.81643 17.0337C1.81416 17.2075 1.74408 17.3737 1.62113 17.4966C1.49818 17.6196 1.33208 17.6896 1.15822 17.6919Z"
      fill="#CDEE24"
      stroke="#CDEE24"
      strokeWidth="0.5"
    />
    <path
      d="M11.6898 18.5695C11.5208 18.5556 11.3635 18.4771 11.251 18.3501L7.74052 14.8396C7.68187 14.7167 7.66273 14.5787 7.68575 14.4446C7.70877 14.3105 7.77282 14.1867 7.86909 14.0904C7.96528 13.9943 8.08903 13.9302 8.22321 13.9072C8.35732 13.8842 8.49537 13.9033 8.61814 13.962L11.6284 16.9722L17.3417 10.4515C17.3994 10.387 17.4691 10.3344 17.5471 10.2968C17.625 10.2592 17.7096 10.2373 17.7959 10.2325C17.8823 10.2276 17.9688 10.2398 18.0505 10.2683C18.1322 10.2968 18.2075 10.3412 18.272 10.3988C18.3366 10.4565 18.389 10.5262 18.4267 10.6042C18.4643 10.6821 18.4861 10.7666 18.4909 10.8531C18.4959 10.9394 18.4837 11.026 18.4551 11.1076C18.4266 11.1893 18.3823 11.2646 18.3246 11.3291L12.1813 18.3501C12.1239 18.4184 12.0529 18.474 11.9728 18.5133C11.8927 18.5525 11.8053 18.5747 11.7161 18.5782L11.6898 18.5695Z"
      fill="#CDEE24"
      stroke="#CDEE24"
      strokeWidth="0.5"
    />
  </Icon>
);
