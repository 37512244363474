import {
  Box,
  HStack,
  Image,
  Text,
  Heading,
  Flex,
  Center,
} from "@chakra-ui/react";
import membershipOneUrl from "../../../assets/vectors/membershipOne.svg";
import membershipTwoUrl from "../../../assets/vectors/membershipTwo.svg";
import membershipThreeUrl from "../../../assets/vectors/membershipThree.svg";
import membershipFourUrl from "../../../assets/vectors/membershipFour.svg";
import membershipSixUrl from "../../../assets/vectors/membershipSix.svg";
import membershipFiveUrl from "../../../assets/vectors/membershipFive.svg";



const featuresList = [
  {
    img: membershipFiveUrl,
    text: "Game changing features to access your perfect hire, saving time and money",
  },
  {
    img: membershipOneUrl,
    text: "Instant access to a pool of pre-screened, actively looking top talent",
  },
  {
    img: membershipTwoUrl,
    text: "Search and filtering options which include 50 in-demand skills to choose from",
  },
  {
    img: membershipThreeUrl,
    text: "Direct communication with candidates all centralised for easy access",
  },
  {
    img: membershipFourUrl,
    text: "Zero recruitment fees meaning you have more flexibility to pay market rate salaries",
  },
   {
    img: membershipSixUrl,
    text: "Unlimited hires to support both growing businesses and large corporates who need multiple hires",
  },

];

const MembershipFeatures = () => {
  return (
    <Box
      bgColor="#FFFFFF"
      pt={{ base: "3.5rem", md: "5rem" }}
      pb={{ base: "2rem", sm: "10rem", md: "13rem" }}
      px="1.5rem"
    >
      <Center>
        <Box maxW="63.8rem">
          <Heading
            as="h3"
            fontSize={{ base: "1.5rem", md: "2.125rem" }}
            lineHeight="2.6875rem"
            textAlign="center"
            mb={{ base: "3rem", md: "4.4rem" }}
          >
            What we offer    
          </Heading>
          <Flex
            flexDir='column'
            gap={{ base: "2rem", md: "3.125rem" }}
            justifyItems="center"
            alignItems='center'
          // mb={{ base: 0, sm: "4rem", lg: "8.75rem" }}
          >
            <Flex w='full' alignItems='center' justifyContent='center' flexDir={{ base: 'column', md: 'row' }} gap={{ base: "2rem", md: "3.125rem" }}
            >
              {featuresList.slice(0, 3).map((feature, i) => {
                return (
                  <HStack key={feature.img} gap="0.5rem" w="full" maxW="19.2rem">
                    <Image src={feature.img} boxSize="4.5rem" loading="lazy" />
                    <Text lineHeight="1.3125rem" color="darkGrey">
                      {feature.text}
                    </Text>
                  </HStack>
                )
              }
              )}
            </Flex>
            <Flex w='full' alignItems='center' justifyContent='center' flexDir={{ base: 'column', md: 'row' }} gap={{ base: "2rem", md: "3.125rem" }}
            >
              {featuresList.slice(3, featuresList.length).map((feature, i) => {
                return (
                  <HStack key={feature.img} gap="0.5rem" w="full" maxW="19.2rem">
                    <Image src={feature.img} boxSize="4.5rem" loading="lazy" />
                    <Text lineHeight="1.3125rem" color="darkGrey">
                      {feature.text}
                    </Text>
                  </HStack>
                )
              }
              )}
            </Flex>
          </Flex>
        </Box>
      </Center>
    </Box>
  );
};

export default MembershipFeatures;
