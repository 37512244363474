import { Icon } from "@chakra-ui/react";

export const CustomEmptyProfileIcon = (props) => (
  <Icon viewBox="0 0 42 42" {...props}>
    <path
      d="M21.0001 42C32.598 42 42.0001 32.598 42.0001 21C42.0001 9.40202 32.598 0 21.0001 0C9.40208 0 6.10352e-05 9.40202 6.10352e-05 21C6.10352e-05 32.598 9.40208 42 21.0001 42Z"
      fill="#EAE8E8"
    />
    <path
      d="M27.0401 15.1353C27.0401 17.2688 25.97 19.1479 24.34 20.2472C27.09 21.5332 29.0001 24.3569 29.0001 27.6333C29.0001 27.9538 28.98 28.2699 28.95 28.5806C28.92 28.8234 28.71 29 28.46 29H13.5401C13.2901 29 13.0801 28.8234 13.0501 28.5806C13.0201 28.2699 13.0001 27.9538 13.0001 27.6333C13.0001 24.3561 14.9101 21.532 17.6601 20.2463C16.0301 19.147 14.96 17.2682 14.96 15.1353C14.96 11.7469 17.6701 9 21.0001 9C24.3401 9 27.0401 11.7469 27.0401 15.1353Z"
      fill="white"
    />
  </Icon>
);
