import { Flex, Grid, GridItem, Text } from "@chakra-ui/react";
import SectionHeading from "../shared/SectionHeading";
const HowItWorksSection = () => {
  return (
    <Grid
      templateColumns="1fr"
      placeItems="center center"
      pt={{ base: "4rem", md: "7.3rem", "2xl": "13.75rem" }}
      pb="1rem"
      px={{ base: "1rem" }}
      backgroundColor="backgroundGrey"
      w="full"
    >
      <GridItem>
        <Flex
          maxW={{ base: "36.875rem", lg: "45rem" }}
          flexDirection="column"
          gap={{ base: "0", lg: "1.875rem" }}
          m={{ lg: "2rem" }}
          alignItems={{ base: "flex-start", lg: "center" }}
          textAlign={{ base: "left", lg: "center" }}
        >
          <SectionHeading lineOneText="how it" lastWord="works" centered />
          <Text
            fontFamily="Plus Jakarta Sans Regular"
            color="darkGrey"
            fontSize={{ base: "1.313rem", lg: "1.875rem" }}
            lineHeight={{ base: "1.9rem", lg: "2.4375rem" }}
          >
            The current recruitment model is outdated, costly and frustrating.
            We are making recruiting effective and affordable for businesses of
            all sizes.
          </Text>
        </Flex>
      </GridItem>
    </Grid>
  );
};

export default HowItWorksSection;
