import { numberInputAnatomy as parts } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from "@chakra-ui/styled-system";
import { Input } from "./input";

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyleRoot = defineStyle({});

const baseStyleField = defineStyle({ ...(Input.baseStyle?.field ?? {}) });

const baseStyleStepperGroup = defineStyle({});

const baseStyleStepper = defineStyle({
  _first: {
    border: "none",
    mr: "10px",
    _active: {
      bg: "green",
    },
  },
  _last: {
    mt: "0",
    borderLeft: "none",
    borderTop: "1px solid #333333",
    mr: "10px",
    _active: {
      bg: "blue",
    },
  },
});

const baseStyle = definePartsStyle({
  root: baseStyleRoot,
  field: baseStyleField,
  stepperGroup: baseStyleStepperGroup,
  stepper: baseStyleStepper,
});

function getSize(size) {
  const radius = {
    lg: "md",
    md: "md",
    sm: "sm",
    xs: "sm",
  };

  return definePartsStyle({
    field: {
      borderRadius: "18px",
      paddingInlineEnd: "5px",
      verticalAlign: "top",
    },
    stepper: {
      fontSize: "0.5rem",
      _first: {
        borderTopEndRadius: radius[size],
      },
      _last: {
        borderBottomEndRadius: radius[size],
        mt: "-1px",
        borderTopWidth: 1,
      },
    },
  });
}

const sizes = {
  xs: getSize("xs"),
  sm: getSize("sm"),
  md: getSize("md"),
  lg: getSize("lg"),
};

export const NumberInput = defineMultiStyleConfig({
  baseStyle,
  sizes,
  variants: Input.variants,
  defaultProps: Input.defaultProps,
});
