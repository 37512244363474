import { Icon } from "@chakra-ui/react";

export const CustomLinkedinTypeTwoIcon = (props) => (
  <Icon viewBox="0 0 21 21" fill="none" {...props}>
    <g clipPath="url(#clip0_3557_3094)">
      <path
        d="M4.16914 1.69141C3.52749 1.69141 2.94414 1.93586 2.50664 2.3942C2.03999 2.88307 1.80664 3.49416 1.80664 4.13583C1.80664 4.80804 2.06914 5.41916 2.50664 5.87749C2.94414 6.33583 3.55664 6.61083 4.13999 6.58028C4.13999 6.58028 4.16914 6.58028 4.19833 6.58028C4.78167 6.58028 5.33583 6.33583 5.77333 5.87749C6.21083 5.41916 6.47333 4.80804 6.47333 4.13583C6.50248 3.49416 6.23998 2.88304 5.80248 2.4247C5.36498 1.93583 4.78164 1.69141 4.16914 1.69141ZM5.36499 5.44974C5.04414 5.78586 4.60664 5.99974 4.13999 5.9692C3.70249 5.9692 3.23583 5.78586 2.91499 5.44974C2.56499 5.11362 2.38999 4.62474 2.38999 4.13586C2.38999 3.64699 2.56499 3.18865 2.91499 2.82199C3.23583 2.48586 3.67333 2.30253 4.16914 2.30253C4.60664 2.30253 5.04414 2.48586 5.36499 2.82199C5.71499 3.18865 5.88999 3.64699 5.88999 4.13586C5.88999 4.62474 5.71499 5.11362 5.36499 5.44974Z"
        fill={props.color ? props.color : "black"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.20541 2.1052C2.72368 1.56224 3.41693 1.27344 4.1693 1.27344C4.89518 1.27344 5.59056 1.5644 6.10865 2.14054C6.60969 2.66778 6.92198 3.38063 6.89014 4.14392C6.8877 4.92388 6.58133 5.63334 6.07489 6.16389C5.56482 6.69825 4.90419 6.99565 4.19849 6.99565H4.15091C3.43098 7.0294 2.7099 6.69241 2.20541 6.16389C1.69694 5.63121 1.39014 4.91818 1.39014 4.13452C1.39014 3.38775 1.66336 2.67305 2.20541 2.1052ZM4.1693 2.71789C3.78089 2.71789 3.45441 2.85919 3.21655 3.10838C2.94254 3.39543 2.80681 3.74989 2.80681 4.13456C2.80681 4.53034 2.94916 4.90341 3.20376 5.14791L3.21029 5.15419L3.21655 5.16074C3.45441 5.40993 3.81005 5.55123 4.14015 5.55123H4.15377L4.16736 5.55212C4.48539 5.57293 4.80868 5.42795 5.06375 5.16074L5.07 5.15419L5.07654 5.14791C5.33114 4.90341 5.47348 4.53034 5.47348 4.13456C5.47348 3.74989 5.33775 3.39543 5.06375 3.10838C4.82069 2.85375 4.49173 2.71789 4.1693 2.71789Z"
        fill={props.color ? props.color : "black"}
      />
      <path
        d="M5.30689 7.19141H2.94439C2.47773 7.19141 2.09854 7.58862 2.09854 8.10807V18.8025C2.09854 19.2914 2.50689 19.7192 2.97354 19.7192H5.30689C5.77354 19.7192 6.18189 19.2914 6.18189 18.8331V8.10807C6.18189 7.61916 5.77354 7.19141 5.30689 7.19141ZM5.59854 18.8331C5.59854 18.9859 5.4527 19.1081 5.30689 19.1081H2.97354C2.8277 19.1081 2.68189 18.9553 2.68189 18.8025V8.10807C2.68189 7.95528 2.79854 7.80253 2.94439 7.80253H5.30689C5.45273 7.80253 5.59854 7.95532 5.59854 8.10807V18.8331Z"
        fill={props.color ? props.color : "black"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.68164 8.10677C1.68164 7.38515 2.22038 6.77344 2.94415 6.77344H5.30665C6.02147 6.77344 6.59832 7.40621 6.59832 8.10677V18.8318C6.59832 19.5109 6.01262 20.1346 5.30665 20.1346H2.97331C2.25848 20.1346 1.68164 19.5017 1.68164 18.8012V8.10677ZM3.09832 8.21789V18.6901H5.18164V8.21789H3.09832Z"
        fill={props.color ? props.color : "black"}
      />
      <path
        d="M15.2233 6.88672H14.6108C13.4733 6.88672 12.3942 7.40618 11.6942 8.23118V7.80339C11.6942 7.49784 11.4025 7.19226 11.1108 7.19226H8.19418C7.93168 7.19226 7.61084 7.43672 7.61084 7.77281V19.17C7.61084 19.5061 7.93168 19.72 8.19418 19.72H11.4025C11.665 19.72 11.9859 19.5061 11.9859 19.17V12.57C11.9859 11.5617 12.6859 10.7672 13.59 10.7672C14.0567 10.7672 14.4942 10.9506 14.815 11.2867C15.1067 11.5617 15.2234 11.9895 15.2234 12.5395V19.1089C15.2234 19.4145 15.515 19.7201 15.8067 19.7201H18.7234C19.015 19.7201 19.3067 19.4145 19.3067 19.1089V11.2256C19.3067 8.78118 17.5275 6.88672 15.2233 6.88672ZM18.7233 19.0784L18.6942 19.1089H15.8358L15.8067 12.5395C15.8067 11.8062 15.6317 11.2562 15.2525 10.859C14.815 10.4006 14.2317 10.1562 13.6192 10.1562C12.3942 10.1867 11.4317 11.2256 11.4317 12.5701V19.1089H8.22334V7.80339H11.1108L11.14 7.83393V9.75893L11.9275 8.93393L11.9567 8.90339C12.54 8.04784 13.5608 7.49784 14.64 7.49784H15.2525C17.2067 7.49784 18.7233 9.14784 18.7233 11.2256V19.0784Z"
        fill={props.color ? props.color : "black"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0034 7.36294C12.7315 6.80082 13.6519 6.46875 14.611 6.46875H15.2235C17.7815 6.46875 19.7235 8.5742 19.7236 11.2243V19.1076C19.7236 19.3892 19.5929 19.6406 19.426 19.8155C19.2603 19.9891 19.0119 20.1354 18.7236 20.1354H15.8069C15.5185 20.1354 15.2702 19.9891 15.1045 19.8155C14.9375 19.6406 14.8069 19.3892 14.8069 19.1076V12.5382C14.8069 12.0321 14.6972 11.7467 14.5294 11.5885L14.5214 11.581L14.5138 11.5731C14.2734 11.3212 13.9457 11.1826 13.5902 11.1826C12.9617 11.1826 12.4027 11.7422 12.4027 12.5687V19.1687C12.4027 19.4802 12.2514 19.7278 12.0617 19.888C11.8783 20.043 11.6385 20.1354 11.4027 20.1354H8.19438C7.95863 20.1354 7.71883 20.043 7.53537 19.888C7.34571 19.7278 7.19437 19.4802 7.19437 19.1687V7.7715C7.19437 7.46445 7.34148 7.21341 7.52343 7.0474C7.70034 6.88598 7.94302 6.77429 8.19438 6.77429H11.111C11.3994 6.77429 11.6478 6.92061 11.8135 7.09425C11.8863 7.17054 11.9522 7.26138 12.0034 7.36294ZM14.6402 7.91321C13.6985 7.91321 12.8067 8.39533 12.3011 9.13681L12.2818 9.16506L12.2291 9.22032L10.7235 10.7976V8.21875H8.6402V18.691H11.0152V12.5687C11.0152 11.0246 12.1326 9.77515 13.609 9.73834L13.6194 9.73808C14.3455 9.73808 15.0378 10.0291 15.5541 10.57C16.0318 11.0704 16.2234 11.7393 16.2235 12.5372C16.2235 12.5376 16.2235 12.5379 16.2235 12.5382L16.2509 18.691H18.3069V11.2243C18.3069 9.34654 16.9477 7.91321 15.2527 7.91321H14.6402Z"
        fill={props.color ? props.color : "black"}
      />
    </g>
    <defs>
      <clipPath id="clip0_3557_3094">
        <rect
          width="19.1667"
          height="20"
          fill="white"
          transform="translate(0.973633 0.703125)"
        />
      </clipPath>
    </defs>
  </Icon>
);
