import { format, parseISO, compareAsc, startOfToday, getYear } from "date-fns";

export function getBase64Promise(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export function getBase64DataForSubmit(base64) {
  return base64.split(",")[1];
}

export function selectQualificationYear() {
  const currentYear = new Date().getFullYear();
  const range = (start, stop, step) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (_, i) => start + i * step
    );
  const rangeFrom = currentYear - 1980;
  let result = range(currentYear, currentYear - rangeFrom, -1);
  const arr = [];
  result.forEach((item, index) => {
    arr.push({ value: index, label: item });
  });
  return arr;
}

export const urltoFile = async (url, filename, mimeType) => {
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
};

export function isEmptyObject(obj) {
  return Object.keys(obj).length === 0;
}

export function NoneEmpty(arr) {
  for (var i = 0; i < arr.length; i++) {
    if (arr[i] === "") return true;
  }
  return false;
}

// compare 2 arrays regardless of element's index
export function compareArrays(list1, list2) {
  const array1 = [...list1].sort();
  const array2 = [...list2].sort();
  return (
    array1.length === array2.length &&
    array1.every((value, index) => value === array2[index])
  );
}

export const formatSalary = (number) => {
  if (number?.toString()?.length === 4)
    return `£` + number.toString().substring(0, 1) + "k";
  if (number?.toString()?.length === 5)
    return `£` + number?.toString().substring(0, 2) + "k";
  if (number?.toString()?.length === 6)
    return `£` + number?.toString().substring(0, 3) + "k";
  return "£" + 1;
};
// data transform for - desired jobs/location/noticePeriod/sectorsWorkedIn/firstDegreeClassification for react-select input
export function transformData(data, notArray) {
  if (notArray)
    return {
      label: data?.name,
      value: data?.id,
    };
  if (!data) return [];
  return data.map((item) => {
    return {
      label: item?.name,
      value: item?.id,
    };
  });
}

export function randomBgColor() {
  const colors = [
    "#966AC1",
    "#6AC1BC",
    "#6673EA",
    "#F2D08D",
    "#EB95AF",
    "#ACBDD7",
  ];
  const lastColorIndex = colors.indexOf(randomBgColor.lastColor);
  let nextColorIndex = Math.floor(Math.random() * colors.length);

  while (lastColorIndex === nextColorIndex) {
    nextColorIndex = Math.floor(Math.random() * colors.length);
  }
  randomBgColor.lastColor = colors[nextColorIndex];
  return randomBgColor.lastColor;
}

export function dateFormat(timestamp) {
  const today = startOfToday();
  // convert timestamp (string) to ISO date
  const givenDate = parseISO(timestamp);

  if (compareAsc(today, givenDate) < 0) {
    return format(givenDate, "HH:mm");
  }

  if (getYear(givenDate) !== getYear(today)) {
    return format(givenDate, "d MMM yyyy");
  }

  return format(givenDate, "d MMM");
}

// are two objects equal
export function deepEqual(x, y) {
  return x && y && typeof x === "object" && typeof y === "object"
    ? Object.keys(x).length === Object.keys(y).length &&
        Object.keys(x).reduce(function (isEqual, key) {
          return isEqual && deepEqual(x[key], y[key]);
        }, true)
    : x === y;
}

// error unpacker
export function getErrorResponsePayload(onError) {
  const data = onError?.response?.data || null;
  if (data) {
    const { message, code, errors } = data;

    return {
      code,
      message,
      errors: errors?.children || null,
      additionalErrors: errors?.errors || null,
    };
  }

  return {
    code: null,
    message: null,
    errors: null,
    additionalErrors: null,
  };
}

export function newLineAfterPeriod(text) {
  let result = "";
  result = text?.split(".").map(
    (item, key) =>
      item !== "" && (
        <span key={key}>
          {item}.
          <br />
        </span>
      )
  );
  return result;
}

export function getContrastYIQ(hexcolor, light = "#ffffff", dark = "#29323A") {
  hexcolor = hexcolor ? hexcolor.replace("#", "") : "";
  const foreground = getContrastYIQDiff(hexcolor);
  const darkBackground = Math.abs(foreground - getContrastYIQDiff(dark));
  const lightBackground = Math.abs(foreground - getContrastYIQDiff(light));
  return darkBackground > lightBackground ? dark : light;
}

function getContrastYIQDiff(hexcolor) {
  hexcolor = hexcolor ? hexcolor.replace("#", "") : "";
  const r = parseInt(hexcolor.substr(0, 2), 16);
  const g = parseInt(hexcolor.substr(2, 2), 16);
  const b = parseInt(hexcolor.substr(4, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq;
}

export function truncateText(text, charNumber=200) {
  if (text.length === 0) return ''
  if (text.length <= charNumber) return text

  return text.slice(0, charNumber - 1) + '...'
}
