import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
} from "@chakra-ui/react";
import { CustomMinusIcon } from "../../../assets/icons/CustomMinusIcon";
import { CustomPlusIcon } from "../../../assets/icons/CustomPlusIcon";

const FaqAccordionItem = ({ question, answer, addition }) => {
  return (
    <Accordion allowMultiple>
      <AccordionItem>
        {({ isExpanded }) => (
          <>
            <Heading>
              <AccordionButton
                py={{ base: "0.8rem", md: "1.2rem", lg: "1.6875rem" }}
                //px="0"
              >
                <Box
                  flex="1"
                  textAlign="left"
                  fontFamily={
                    isExpanded
                      ? "Plus Jakarta Sans Bold"
                      : "Plus Jakarta Sans Medium"
                  }
                  fontSize={{ base: "1rem", sm: "1.125rem", lg: "1.125rem" }}
                >
                  {question}
                </Box>
                {isExpanded ? (
                  <CustomMinusIcon
                    boxSize={{ base: "1.5rem", sm: "1.8rem", lg: "2.375rem" }}
                    ml={{ base: "0.5rem", md: "1.8rem" }}
                  />
                ) : (
                  <CustomPlusIcon
                    boxSize={{ base: "1.5rem", sm: "1.8rem", lg: "2.375rem" }}
                    ml={{ base: "0.5rem", md: "1.8rem" }}
                  />
                )}
              </AccordionButton>
            </Heading>
            <AccordionPanel
              fontSize={{ base: "0.875rem", lg: "0.875rem" }}
              lineHeight={{ base: "1.3rem", lg: "1.75rem" }}
            >
              {answer}
              <br/>
              {addition}
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
};
export default FaqAccordionItem;
