import { Icon } from "@chakra-ui/react";

export const CustomSingleUserWithCircleIcon = (props) => (
  <Icon viewBox="0 0 18 19" fill="none" {...props}>
    <circle
      cx="9"
      cy="9.31641"
      r="8"
      stroke={props.color ? props.color : "#CDEE24"}
      strokeWidth="2"
    />
    <path
      d="M11.5888 6.80518C11.5888 7.71954 11.1294 8.52489 10.4323 8.996C11.6106 9.54717 12.4285 10.7573 12.4285 12.1615C12.4285 12.2167 12.4272 12.2717 12.4247 12.3262C12.4135 12.5704 12.2011 12.7472 11.9567 12.7472H6.04318C5.79876 12.7472 5.58631 12.5704 5.57512 12.3262C5.57262 12.2717 5.57135 12.2167 5.57135 12.1615C5.57135 10.757 6.38964 9.54664 7.56832 8.99564C6.8715 8.52448 6.41233 7.71931 6.41233 6.80518C6.41233 5.353 7.57113 4.17578 9.00057 4.17578C10.43 4.17578 11.5888 5.353 11.5888 6.80518Z"
      stroke={props.color ? props.color : "#CDEE24"}
      strokeWidth="2"
    />
  </Icon>
);
