import {
  Grid,
  GridItem,
  Flex,
  Text,
  Box,
} from "@chakra-ui/react";
import TextElement from "./TextElement";
import ImageElement from "./ImageElelement";
import PseudoElementsTypeOne from "../shared/pseudo/PseudoElementsTypeOne";
import PseudoElementsTypeTwo from "../shared/pseudo/PseudoElementsTypeTwo";
import PseudoElementsTypeThree from "../shared/pseudo/PseudoElementsTypeThree";
import PseudoElementsTypeFour from "../shared/pseudo/PseudoElementsTypeFour";
import PseudoElementsTypeFive from "../shared/pseudo/PseudoElementsTypeFive";

const NumberedSection = ({
  imageBlockSize,
  textBlockSize,
  gap,
  bgColor,
  imageFirst,
  stepNumber,
  twoLine,
  lineOneText,
  lineTwoText,
  lastWord,
  noCircle,
  bodyText,
  separatorText,
  srcX1,
  srcX2,
  srcX3,
  fallbackSrc,
  hasTopElement,
  hasBottomElement,
  ourStorySection,
  pseudoElementsTypeOne,
  pseudoElementsTypeTwo,
  pseudoElementsTypeThree,
  pseudoElementsTypeFour,
  pseudoElementsTypeFive,
}) => {
  return (
    <Box position="relative" w="full">
      {pseudoElementsTypeOne && <PseudoElementsTypeOne />}
      {pseudoElementsTypeTwo && <PseudoElementsTypeTwo />}
      {pseudoElementsTypeThree && <PseudoElementsTypeThree />}
      {pseudoElementsTypeFour && <PseudoElementsTypeFour />}
      {pseudoElementsTypeFive && <PseudoElementsTypeFive />}
      <Grid
        templateColumns="1fr"
        placeItems="center center"
        pt={
          hasTopElement
            ? { base: "3rem", md: "5.8rem", "2xl": "8.125rem" }
            : { base: "3rem", lg: "7.3rem", "2xl": "13.75rem" }
        }
        pb={
          hasBottomElement
            ? { base: "3rem", md: "5.8rem", "2xl": "8.125rem" }
            : { base: "3rem", lg: "7.3rem", "2xl": "13.75rem" }
        }
        backgroundColor={bgColor}
        w="full"
      >
        <GridItem px={{ base: "1rem", "2xl": 0 }} position="relative">
          <Flex
            direction={
              imageFirst
                ? { base: "column", lg: "row-reverse" }
                : { base: "column", lg: "row" }
            }
            justifyContent="center"
            alignItems={{ base: "flex-start", lg: "center" }}
            gap={gap}
          >
            <TextElement
              stepNumber={stepNumber}
              twoLine={twoLine}
              lineOneText={lineOneText}
              lineTwoText={lineTwoText}
              lastWord={lastWord}
              bodyText={bodyText}
              noCircle={noCircle}
              separatorText={separatorText}
              ourStorySection={!!ourStorySection}
              hasBottomElement={!!hasBottomElement}
              textBlockSize={textBlockSize}
            />
            <Flex direction="column" maxWidth={imageBlockSize} width="full">
              <ImageElement
                srcX1={srcX1}
                srcX2={srcX2}
                srcX3={srcX3}
                fallbackSrc={fallbackSrc}
                imageBlockSize={imageBlockSize}
              />
              {ourStorySection && (
                <Box
                  textAlign="center"
                  fontFamily="Plus Jakarta Sans Medium"
                  fontSize="1.5rem"
                  lineHeight="2.125rem"
                  pl={{ base: "0", lg: "3rem" }}
                >
                  <Text mt="1rem">Catherine Turner</Text>
                  <Text color="#A6A6A6" fontWeight="600" letterSpacing="1px">
                    CEO
                  </Text>
                </Box>
              )}
            </Flex>
          </Flex>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default NumberedSection;
