import { Box, Heading, Image } from "@chakra-ui/react";
import blackLinesSrc from "../../../assets/vectors/blackLines.svg";
import elipseLimeSrc from "../../../assets/vectors/elipseLime.svg";
import ringBlackSrc from "../../../assets/vectors/ringBlack.svg";
import ringLimeSrc from "../../../assets/vectors/ringLime.svg";
import triangleDotSrc from "../../../assets/vectors/triangleDot.svg";
import logoSymbolLimeSrc from "../../../assets/vectors/logoSymbolLime.svg";
import ringWhiteSrc from "../../../assets/vectors/ringWhite.svg";

const LoginImagePseudoElements = ({ page }) => {
  if (page === "joinAsCandidate") return;
  return (
    <Box w="full" h="full" position="absolute" zIndex="2">
      <Image
        src={ringLimeSrc}
        loading="lazy"
        position="absolute"
        bottom="15%"
        left={{ lg: "-2%", xl: "-4%" }}
        width={{ lg: "2rem", xl: "3.5rem" }}
      />
      <Image
        src={triangleDotSrc}
        loading="lazy"
        width={{ lg: "8rem", xl: "11.125rem" }}
        position="absolute"
        top="21%"
        left={{ lg: "-2%", xl: "-6%" }}
      />
      <Image
        src={logoSymbolLimeSrc}
        loading="lazy"
        maxW="4.8rem"
        position="absolute"
        top="10%"
        left="8%"
      />
      {page === "contact" && (
        <>
          <Image
            src={elipseLimeSrc}
            loading="lazy"
            maxW="7.6rem"
            position="absolute"
            right="-1.5rem"
            top="15%"
            transform="rotate(90deg)"
          />
          <Image
            src={ringWhiteSrc}
            loading="lazy"
            position="absolute"
            right="2%"
            bottom={{ lg: "28%", xl: "26%" }}
            width={{ lg: "3rem", xl: "5rem" }}
          />
        </>
      )}
      {(page === "joinAsCompany" || page === "login") && (
        <>
          <Image
            src={elipseLimeSrc}
            loading="lazy"
            maxW="7.6rem"
            position="absolute"
            right="5%"
            top="0"
          />

          <Image
            src={ringBlackSrc}
            loading="lazy"
            position="absolute"
            right="2%"
            bottom={{ lg: "28%", xl: "26%" }}
            width={{ lg: "3rem", xl: "5rem" }}
          />
          <Image
            src={blackLinesSrc}
            loading="lazy"
            maxW="50rem"
            position="absolute"
            right="0"
            bottom="0"
            width={{ base: "30rem" }}
          />
          <Heading
            fontSize={{ lg: "2rem", xl: "2.5rem" }} //40px
            lineHeight={{ lg: "2.3rem", xl: "2.6rem" }} //42px
            color="white"
            position="absolute"
            bottom="10%"
            left="10%"
            width={{ lg: "28rem", xl: "31rem" }}
          >
            Transforming the way businesses access and recruit top finance
            talent
          </Heading>
        </>
      )}
    </Box>
  );
};

export default LoginImagePseudoElements;
