import { Flex, Center, Heading, Box, Text, Link } from "@chakra-ui/react";
import {
  HeadingPrimary,
  HeadingSecondary,
  HeadingTertiary,
} from "../../components/public/privacy/Headings";
import Intro from "../../components/public/privacy/Intro";
import {
  ListOne,
  ListFour,
  ListTwo,
  ListFive,
  ListSix,
  ListSeven,
  ListNine,
  ListTen,
} from "../../components/public/privacy/Lists";
import { RegularText } from "../../components/public/privacy/Texts";
import {
  ListWrapper,
  SectionWrapper,
} from "../../components/public/privacy/Wrappers";

const privacyPolicyLink =
  "https://www.freeprivacypolicy.com/free-privacy-policy-generator/";

const PrivacyPage = () => {
  return (
    <Flex
      minH="100vh"
      flexDir="column"
      alignItems="center"
      justifyContent="start"
      w="full"
    >
      <Heading
        as="h1"
        fontSize={{ base: "2rem", lg: "3.125rem" }}
        lineHeight={{ base: "2.5rem", lg: "3.9375rem" }}
        bgColor="backgroundGrey"
        w="full"
        textAlign="center"
        pt="calc(4.625rem + 4rem)"
        pb="4rem"
        px={{ base: "1rem", sm: "3rem" }}
      >
        Privacy Policy
      </Heading>
      <Box width="full" bgColor="white" pt={{ base: "3.75rem" }} px="1rem">
        <Center flexDirection="column" gap="3.75rem" mb={{ lg: "6.875rem" }}>
          <Box maxWidth={{ base: "46.875rem" }} width="100%">
            {/* interpretions and definitions */}
            <SectionWrapper>
              <Intro />
              <Text>
                We use Your Personal data to provide and improve the Service. By
                using the Service, You agree to the collection and use of
                information in accordance with this Privacy Policy. This Privacy
                Policy has been created with the help of the{" "}
                <Text
                  as={Link}
                  href={privacyPolicyLink}
                  isExternal
                  color="limeText"
                  textStyle="bodyMedium"
                >
                  Free Privacy Policy Generator.
                </Text>
              </Text>
              <HeadingPrimary heading="Interpretation and Definitions" />
              <HeadingSecondary heading="Interpretation" />
              <RegularText text="The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural." />
              <HeadingSecondary heading="Definitions" />
              <ListWrapper>
                <ListOne>
                  <RegularText text="For the purposes of this Privacy Policy:" />
                  <ListOne />
                </ListOne>
              </ListWrapper>
            </SectionWrapper>
            {/* collecting and using your personal data */}
            <SectionWrapper>
              <HeadingPrimary heading="Collecting and Using Your Personal Data" />
              <HeadingSecondary heading="Types of Data Collected" />
              <HeadingTertiary heading="Personal Data" />
              <ListWrapper>
                <RegularText
                  text="While using Our Service, We may ask You to provide Us with
                  certain personally identifiable information that can be used
                  to contact or identify You. Personally identifiable
                  information may include, but is not limited to:"
                />
                <ListTwo />
              </ListWrapper>
              <HeadingTertiary heading="Usage Data" />
              <RegularText text="Usage Data is collected automatically when using the Service." />
              <RegularText text="Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data." />
              <RegularText text="When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data." />
              <RegularText text="We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device." />
              <HeadingTertiary heading="Information from Third-Party Social Media Services" />
              <ListWrapper>
                {/* <RegularText text="The Company allows You to create an account and log in to use the Service through the following Third-party Social Media Services:" />
                <ListThree /> */}
                <RegularText text="If You decide to register through or otherwise grant us access to a Third-Party Social Media Service, We may collect Personal data that is already associated with Your Third-Party Social Media Service's account, such as Your name, Your email address, Your activities or Your contact list associated with that account." />
                <RegularText text="You may also have the option of sharing additional information with the Company through Your Third-Party Social Media Service's account. If You choose to provide such information and Personal Data, during registration or otherwise, You are giving the Company permission to use, share, and store it in a manner consistent with this Privacy Policy." />
              </ListWrapper>
              <HeadingTertiary heading="Information Collected while Using the Application" />
              <ListWrapper>
                <RegularText text="While using Our Application, in order to provide features of Our Application, We may collect, with Your prior permission:" />
                <ListFour />
              </ListWrapper>
              <RegularText text="We use this information to provide features of Our Service, to improve and customize Our Service. The information may be uploaded to the Company's servers and/or a Service Provider's server or it may be simply stored on Your device." />
              <RegularText text="You can enable or disable access to this information at any time, through Your Device settings." />
              <HeadingTertiary heading="Tracking Technologies and Cookies" />
              <ListWrapper>
                <RegularText text="We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service. The technologies We use may include:" />
                <ListFive />
                <RegularText text="Cookies can be 'Persistent' or 'Session' Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser. Learn more about cookies on the Free Privacy Policy website article." />
                <RegularText text="We use both Session and Persistent Cookies for the purposes set out below:" />
                <ListSix />
                <RegularText text="For more information about the cookies we use and your choices regarding cookies, please visit our Cookies Policy or the Cookies section of our Privacy Policy." />
              </ListWrapper>
            </SectionWrapper>
            {/* use of your personal data */}
            <SectionWrapper>
              <HeadingSecondary heading="Use of Your Personal Data" />
              <ListWrapper>
                <RegularText text="The Company may use Personal Data for the following purposes:" />
                <ListSix />
                <RegularText text="We may share Your personal information in the following situations:" />
                <ListSeven />
              </ListWrapper>
            </SectionWrapper>
            {/* Retention of Your Personal Data  */}
            <SectionWrapper>
              <HeadingSecondary heading="Retention of Your Personal Data" />
              <RegularText text="The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies." />
              <RegularText text="The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods." />
            </SectionWrapper>
            {/*Transfer of Your Personal Data*/}
            <SectionWrapper>
              <HeadingSecondary heading="Transfer of Your Personal Data" />
              <RegularText text="Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction." />
              <RegularText text="Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer." />
              <RegularText text="The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information." />
            </SectionWrapper>
            {/* Delete Your Personal Data */}
            <SectionWrapper>
              <HeadingSecondary heading="Delete Your Personal Data" />
              <RegularText text="You have the right to delete or request that We assist in deleting the Personal Data that We have collected about You." />
              <RegularText text="Our Service may give You the ability to delete certain information about You from within the Service." />
              <RegularText text="You may update, amend, or delete Your information at any time by signing in to Your Account, if you have one, and visiting the account settings section that allows you to manage Your personal information. You may also contact Us to request access to, correct, or delete any personal information that You have provided to Us." />
              <RegularText text="Please note, however, that We may need to retain certain information when we have a legal obligation or lawful basis to do so." />
            </SectionWrapper>
            {/* Disclosure of Your Personal Data */}
            <SectionWrapper>
              <HeadingSecondary heading="Disclosure of Your Personal Data" />
              <HeadingTertiary heading="Business Transactions" />
              <RegularText text="If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy." />
              <HeadingTertiary heading="Law enforcement" />
              <RegularText text="Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency)." />
              <HeadingTertiary heading="Other legal requirements" />
              <ListWrapper>
                <RegularText text="The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:" />
                <ListNine />
              </ListWrapper>
            </SectionWrapper>
            {/* Security of Your Personal Data */}
            <SectionWrapper>
              <HeadingSecondary heading="Security of Your Personal Data" />
              <RegularText text="The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security." />
            </SectionWrapper>
            {/*  Children's Privacy*/}
            <SectionWrapper>
              <HeadingPrimary heading="Children's Privacy" />
              <RegularText text="Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers." />
              <RegularText text="If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information." />
            </SectionWrapper>
            {/* Links to Other Websites */}
            <SectionWrapper>
              <HeadingPrimary heading="Links to Other Websites" />
              <RegularText text="Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit." />
              <RegularText text="We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services." />
            </SectionWrapper>
            {/* Changes to this Privacy Policy */}
            <SectionWrapper>
              <HeadingPrimary heading="Changes to this Privacy Policy" />
              <RegularText text="We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page." />
              <RegularText text="We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the 'Last updated' date at the top of this Privacy Policy." />
              <RegularText text="You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page." />
            </SectionWrapper>
            {/* Contact Us */}
            <SectionWrapper>
              <HeadingPrimary heading="Contact Us" />
              <ListWrapper>
                <RegularText text="If you have any questions about this Privacy Policy, You can contact us:" />
                <ListTen />
              </ListWrapper>
            </SectionWrapper>
          </Box>
        </Center>
      </Box>
    </Flex>
  );
};

export default PrivacyPage;
