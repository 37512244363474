import { Icon } from "@chakra-ui/react";

export const CustomDropdownIcon = (props) => (
  <Icon viewBox="0 0 10 8" {...props}>
    <path
      d="M5.77145 6.56466C5.37147 7.04961 4.62853 7.04961 4.22855 6.56466L2.01946 3.88629C1.4815 3.23404 1.94544 2.25 2.79091 2.25L7.20909 2.25C8.05455 2.25 8.5185 3.23405 7.98054 3.88629L5.77145 6.56466Z"
      fill="black"
    />
  </Icon>
);
