import {
  VStack,
  Image,
  Heading,
  Text,
  Flex,
  Center,
} from "@chakra-ui/react";
import paperAirplaneSrc from "../../../assets/vectors/PaperAirpline.svg";

const FormSuccessfulySent = ({ form }) => {
  // prop form can have 4 values - joinAsCompany, joinAsCandidate, forgot-password, contact
  const infos = {
    contact: {
      title: "Thank you for contacting us!",
      message: "We will answer you as soon as possible.",
    },
    joinAsCompany: {
      title: "Thank you!",
      message:
        "You will shortly receive an email to verify your account. Once verified, a member of our friendly team will be in contact to arrange a short demo video call displaying all of the bespoke functionality on SearcHive, as well as our subscription pricing options.",
      additionalMessage:
        "Your account will be activated as soon as payment has been made. You will have full access to the platform and can look forward to hiring the very best talent for your roles!",
    },
    joinAsCandidate: {
      title: "Thank you for applying.",
      message: "Keep an eye on your email for next steps.",
    },
    reset_password: {
      title: "Check your inbox",
      message:
        "Your reset link is in your inbox. Make sure to check your spam folder if you can't see it in your inbox.",
    },
    bookADemo: {
      title: "Thank you!",
      message: "We will get back to you as soon as possible.",
    }
  };

  const info = infos[form];

  const blockWidth =
    form === "joinAsCandidate" || form === "contact" ? "22rem" : "auto";
  const textFontSize =
    form === "joinAsCandidate" || form === "contact" ? "1.125rem" : "1rem";

  return (
    <Center>
      <Flex
        direction="column"
        gap="3rem"
        alignItems={{ base: "center", sm: "flex-start" }}
        maxW={blockWidth}
        w="full"
        pt={{ lg: "3rem", "2xl": "8rem" }}
      >
        <Image src={paperAirplaneSrc} loading="lazy" alignSelf="center" />
        <VStack
          spacing="1.375rem"
          alignItems={{ base: "center", sm: form==='bookADemo' ? 'center' : "flex-start" }}
          minH={{
            base: "calc(100vh - 65px - 253px - 11rem - 92px)",
            sm: "calc(100vh - 65px - 65px - 18rem - 25px)",
            lg: "auto",
          }}
        >
          <Heading
            textAlign={{ base: "center", sm: "left" }}
            fontSize={{ base: "2.5rem", sm: "2.5rem" }} //40px
            lineHeight={{ base: "3rem", sm: "3.125rem" }} //50px
            mb="0.5rem"
          >
            {info.title}
          </Heading>
          <Text
            textAlign={{ base: "center", sm: "left" }}
            color="darkGrey"
            fontSize={textFontSize}
            lineHeight="1.3rem"
          >
            {info.message}
          </Text>
          {form === "joinAsCompany" && (
            <Text
              textAlign={{ base: "center", sm: "left" }}
              color="darkGrey"
              fontSize={textFontSize}
              lineHeight="1.3rem"
              fontWeight="600"
            >
              {info.additionalMessage}
            </Text>
          )}
        </VStack>
      </Flex>
    </Center>
  );
};

export default FormSuccessfulySent;
