import { Icon } from "@chakra-ui/react";

export const CustomUniversalFileIcon = (props) => (
  <Icon viewBox="0 0 30 33" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.888 0.668671L29.0196 9.11814C29.1296 9.23254 29.1911 9.38508 29.1911 9.5438V29.9454C29.1911 31.298 28.0877 32.3985 26.7313 32.3985H8.32626C6.96993 32.3985 5.86646 31.2974 5.86646 29.944V24.7735H7.09405V29.9441C7.09405 30.6206 7.64682 31.1709 8.32626 31.1709H26.7313C27.4107 31.1709 27.9635 30.6211 27.9635 29.9454V10.3014H19.984C19.645 10.3014 19.3702 10.0266 19.3702 9.68762V1.70812H8.32626C7.64682 1.70812 7.09405 2.26039 7.09405 2.93934V11.461H5.86646V2.93934C5.86646 1.58357 6.96993 0.48053 8.32626 0.48053H20.4457C20.6126 0.48053 20.7723 0.54848 20.888 0.668671ZM27.2731 9.07382L20.5978 2.13758V9.07382H27.2731Z"
      fill="#333333"
    />
    <rect
      x="0.524414"
      y="11.3633"
      width="11.8242"
      height="13.4883"
      rx="1"
      fill="#CDEE24"
    />
    <path
      d="M3.37903 19.2877C3.22392 19.1326 3.22392 18.8819 3.37903 18.7267C3.53415 18.5716 3.78487 18.5716 3.93999 18.7267L6.03981 20.8266V14.4334C6.03981 14.2144 6.21754 14.0367 6.43652 14.0367C6.65551 14.0367 6.83324 14.2144 6.83324 14.4334V20.8266L8.93306 18.7267C9.08817 18.5716 9.3389 18.5716 9.49401 18.7267C9.64913 18.8819 9.64913 19.1326 9.49401 19.2877L6.717 22.0647C6.5599 22.2222 6.30799 22.217 6.15565 22.0647L3.37903 19.2877Z"
      fill="#333333"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.15559 22.0647L3.37898 19.2877C3.22386 19.1326 3.22386 18.8818 3.37898 18.7267C3.53409 18.5716 3.78482 18.5716 3.93993 18.7267L6.03975 20.8266V14.4334C6.03975 14.2144 6.21748 14.0367 6.43647 14.0367C6.65545 14.0367 6.83318 14.2144 6.83318 14.4334V20.8266L8.933 18.7267C9.08812 18.5716 9.33884 18.5716 9.49396 18.7267C9.64907 18.8818 9.64907 19.1326 9.49396 19.2877L6.71695 22.0647C6.55985 22.2222 6.30793 22.217 6.15559 22.0647ZM7.13318 20.1023L8.72087 18.5146C8.99314 18.2423 9.43382 18.2423 9.70609 18.5146C9.97836 18.7869 9.97836 19.2275 9.70609 19.4998L6.92934 22.2766C6.65235 22.5543 6.21026 22.5436 5.94346 22.2768L3.16684 19.4998C3.16684 19.4998 3.16685 19.4998 3.16684 19.4998C2.89459 19.2275 2.89458 18.7869 3.16684 18.5146C3.43912 18.2423 3.87979 18.2423 4.15206 18.5146L5.73975 20.1023V14.4334C5.73975 14.0488 6.05179 13.7367 6.43647 13.7367C6.82114 13.7367 7.13318 14.0488 7.13318 14.4334V20.1023Z"
      fill="#333333"
    />
  </Icon>
);
