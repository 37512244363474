import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  GridItem,
  Heading,
  Input,
  SimpleGrid,
  useBreakpointValue,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  REGEX_IS_VALID_URL,
  addProtocolPrefixUrl,
} from "../../utils/validations";
import { useMutation } from "@tanstack/react-query";
import AutocompleteCompanyNameInput, {
  CustomMenu,
} from "../../components/shared/AutocompleteCompanyNameInput";
import FormSuccessfulySent from "../../components/public/shared/FormSuccessfulySent";
import { bookADemoFn, publicAxios } from "../../services/apiServices";
import { getErrorResponsePayload } from "../../utils/helpers";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("Please enter first name"),
  lastName: Yup.string().required("Please enter last name"),
  company: Yup.string().required("Please enter company name"),
  email: Yup.string()
    .required("Please enter email address")
    .email("Please enter a valid email address"),
  phoneNumber: Yup.string().required("Please enter phone number"),
});

const formOptions = {
  resolver: yupResolver(validationSchema),
  mode: "onBlur",
};

function BookADemoPage() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedCompanyInfo, setSelectedCompanyInfo] = useState(null);
  const [selectedCompanyError, setSelectedCompanyError] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [successfulRegistration, setSuccessfulRegistration] = useState(false);
  const colSpan = useBreakpointValue({ base: 2, lg: 1 });
  let [searchParams] = useSearchParams();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty, isValid },
    watch,
    getFieldState,
    setError,
    resetField,
    getValues,
    clearErrors,
    setValue,
  } = useForm(formOptions);

  const { mutate, isLoading } = useMutation({
    mutationFn: (data) => bookADemoFn(data),
    onSuccess: (data) => {
      setSuccessfulRegistration(true);
    },
    onError: (error) => {
      const { errors } = getErrorResponsePayload(error);

      if (errors?.firstName?.errors) {
        const message = errors.firstName.errors[0];
        setError("firstName", { type: "server", message });
      }
      if (errors?.lastName?.errors) {
        const message = errors.lastName.errors[0];
        setError("lastName", { type: "server", message });
      }
      if (errors?.companyName?.errors) {
        const message = errors.companyName.errors[0];
        setError("companyName", { type: "server", message });
        setSelectedCompanyError(message);
      }
      if (errors?.companyWebSiteUrl?.errors) {
        const message = errors.companyWebSiteUrl.errors[0];
        setError("companyWebSiteUrl", { type: "server", message });
        setSelectedCompanyError(message);
      }
      if (errors?.phoneNumber?.errors) {
        const message = errors.phoneNumber.errors[0];
        setError("phoneNumber", { type: "server", message });
      }
      if (errors?.email?.errors) {
        const message = errors.email.errors[0];
        setError("email", { type: "server", message });
      }
    },
  });

  // company field is used only for error tracking, company state is handled using selectedCompanyInfo / selectedCompanyError
  const validateCompanyField = () => {
    if (!selectedCompanyInfo) {
      setSelectedCompanyError("Please enter company name");
    } else {
      setSelectedCompanyError(null);
    }
  };

  useEffect(() => {
    if (selectedCompanyInfo) {
      setValue("company", "company-placeholder-text");
      clearErrors("company");
    } else {
      setValue("company", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompanyInfo]);

  const watchCompanyName = watch("companyName");

  const isCompanyAdded = () => {
    return !!selectedCompanyInfo;
  };

  const prepareDataForSubmit = (data) => {
    let tempDataObj = { ...data };
    // company details
    let companyObj = {};
    if (selectedCompanyInfo?.uuid) {
      delete tempDataObj.companyName;
      delete tempDataObj.companyWebSiteUrl;
      companyObj["company"] = selectedCompanyInfo.uuid;
    } else {
      delete tempDataObj.company;
    }

    const profile = {
      phoneNumber: tempDataObj.phoneNumber,
    };
    delete tempDataObj.phoneNumber;

    return {
      ...tempDataObj,
      ...companyObj,
      profile,
    };
  };

  const onSubmit = async (data) => {
    const formattedData = prepareDataForSubmit(data);
    console.log({formattedData});
    mutate({
      ...formattedData,
    });
  };

  // TBD move this logic to react-query
  const getCompaniesList = async (term) => {
    try {
      const { data } = await publicAxios.get(
        `companies/search?term=${encodeURIComponent(term)}`
      );
      if (data.data.length === 0) return [];
      return data.data;
    } catch (error) {}
  };

  const handleAddNewCompany = async () => {
    const [name, url] = getValues(["companyName", "companyWebSiteUrl"]);

    if (!name) {
      setError("companyName", {
        type: "manual",
        message: "Please enter company name",
      });

      return;
    }

    // validate company URL
    if (url) {
      const prefixedUrl = addProtocolPrefixUrl(url);
      if (!REGEX_IS_VALID_URL.test(prefixedUrl)) {
        setError("companyWebSiteUrl", {
          type: "manual",
          message: "Enter a valid website URL, e.g. example.com",
        });

        return;
      }

      setValue("companyWebSiteUrl", prefixedUrl);
    }

    // validate company name - check for existing company
    if (name) {
      const companiesList = await getCompaniesList(name);
      const companyNames = companiesList?.reduce((acc, company) => {
        return [...acc, company.name.toLowerCase()];
      }, []);

      if (companyNames?.includes(name.toLowerCase())) {
        setError("companyName", {
          type: "manual",
          message: "This company already exists",
        });

        return;
      }
    }

    setSelectedCompanyInfo({
      uuid: null,
      name: name,
      webSiteUrl: url ? addProtocolPrefixUrl(url) : "",
    });

    onClose();
  };

  const handleModalClose = () => {
    onClose();
    clearErrors(["companyName", "companyWebSiteUrl"]);
    resetField("companyName");
    resetField("companyWebSiteUrl");
  };

  if (successfulRegistration)
    return <FormSuccessfulySent form="bookADemo" />;

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {/* remove autofill with these two fields */}
      <Input id="username" name="username" display="none" type="text" />
      <Input id="password" display="none" type="password" name="password" />
      <Modal isOpen={isOpen} onClose={handleModalClose} isCentered>
        <ModalOverlay backgroundColor="modalOverlay" />
        <ModalContent
          minWidth={{ base: "25rem", sm: "35rem" }}
          p={{ base: "2rem", sm: "5rem" }}
          m="2rem"
        >
          <ModalHeader
            as={Heading}
            alignSelf={{ base: "center" }}
            fontSize={{ base: "1.8rem", sm: "2.125rem" }} //50px
            lineHeight={{ base: "3rem", sm: "2.6875rem" }} //63px
            mb="2rem"
          >
            Add company
          </ModalHeader>
          <ModalCloseButton _hover={{ bg: "backgroundGrey" }} />
          <ModalBody>
            <VStack spacing={8} alignItems="flex-start">
              <FormControl isRequired isInvalid={errors.companyName}>
                <FormLabel
                  textStyle="bodySemiBold"
                  fontWeight="600"
                  htmlFor="companyName"
                  requiredIndicator=""
                  mb="0.5rem"
                >
                  Company name
                </FormLabel>
                <Input
                  id="companyName"
                  type="text"
                  placeholder="Enter company name"
                  {...register("companyName")}
                />
                <FormErrorMessage>
                  {errors.companyName?.message || errors.companyName}
                </FormErrorMessage>
              </FormControl>
              <FormControl isRequired isInvalid={errors.companyWebSiteUrl}>
                <FormLabel
                  textStyle="bodySemiBold"
                  fontWeight="600"
                  htmlFor="companyWebSiteUrl"
                  requiredIndicator=""
                  mb="0.5rem"
                >
                  Website (optional)
                </FormLabel>
                <Input
                  id="companyWebSiteUrl"
                  type="text"
                  placeholder="Enter website URL"
                  {...register("companyWebSiteUrl")}
                />
                <FormErrorMessage>
                  {errors.companyWebSiteUrl?.message ||
                    errors.companyWebSiteUrl}
                </FormErrorMessage>
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              w="full"
              onClick={handleAddNewCompany}
              disabled={!watchCompanyName}
              mt={6}
              alt="Add company"
            >
              Add company
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <VStack spacing={8} alignItems="flex-start">
        <Heading
          as="h1"
          alignSelf={{ base: "center", sm: "flex-start" }}
          fontSize={{ base: "2.5rem", sm: "3.125rem" }} //50px
          lineHeight={{ base: "3rem", sm: "3.9rem" }} //63px
          mb="0.5rem"
        >
          Book a demo
        </Heading>

        <SimpleGrid w="full" columns={2} columnGap={4} rowGap={8}>
          <GridItem colSpan={colSpan}>
            <FormControl isRequired isInvalid={errors.firstName}>
              <FormLabel
                textStyle="bodySemiBold"
                fontWeight="600"
                htmlFor="firstName"
                requiredIndicator=""
                mb="0.5rem"
              >
                First name
              </FormLabel>
              <Input
                autoComplete="new-password"
                id="firstName"
                type="text"
                placeholder="Enter first name"
                {...register("firstName")}
              />
              <FormErrorMessage fontSize="12px">
                {errors.firstName?.message || errors.firstName}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={colSpan}>
            <FormControl isRequired isInvalid={errors.lastName}>
              <FormLabel
                textStyle="bodySemiBold"
                fontWeight="600"
                htmlFor="lastName"
                requiredIndicator=""
                mb="0.5rem"
              >
                Last name
              </FormLabel>
              <Input
                autoComplete="new-password"
                id="lastName"
                type="text"
                placeholder="Enter last name"
                {...register("lastName")}
              />
              <FormErrorMessage fontSize="12px">
                {errors.lastName?.message || errors.lastName}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        </SimpleGrid>

        <FormControl
          isRequired
          isInvalid={
            errors.company || errors.companyName || selectedCompanyError
          }
        >
          <FormLabel
            textStyle="bodySemiBold"
            fontWeight="600"
            htmlFor="companyName"
            requiredIndicator=""
            mb="0.5rem"
          >
            Company name
          </FormLabel>
          <AutocompleteCompanyNameInput
            cacheOptions
            loadOptions={(value) => getCompaniesList(value)}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.webSiteUrl}
            components={{ Menu: CustomMenu }}
            placeholder={"Start typing a company name"}
            errorBorder={
              errors.company || errors.companyName || selectedCompanyError
            }
            isClearable={true}
            onChange={(company) => {
              clearErrors("companyName");
              clearErrors("companyWebSiteUrl");
              setSelectedCompanyInfo(company);
              setIsMenuOpen(false);
            }}
            handleMenuBtnClick={(e) => {
              // to ensure that events are trigger in defined order - first onTouchEnd than onClick
              // https://stackoverflow.com/questions/45612379/react-onclick-and-ontouchstart-fired-simultaneously
              e.preventDefault();
              onOpen();
              resetField("companyName");
              resetField("companyWebSiteUrl");
              setSelectedCompanyInfo(null);
            }}
            noOptionsMessage={() => ""}
            value={selectedCompanyInfo}
            menuIsOpen={isMenuOpen}
            onMenuOpen={() => setIsMenuOpen(true)}
            onBlur={(e) => {
              validateCompanyField();
              setTimeout(() => {
                setIsMenuOpen(false);
              }, 200);
            }}
            // prevent only on mobile devices onBlur & onChange to trigger at the same time
            blurInputOnSelect={false}
          />
          <FormErrorMessage fontSize="12px">
            {selectedCompanyError ||
              errors.company?.message ||
              errors.companyName?.message ||
              errors.companyName}
          </FormErrorMessage>
        </FormControl>

        <FormControl isRequired isInvalid={errors.email}>
          <FormLabel
            textStyle="bodySemiBold"
            fontWeight="600"
            htmlFor="email"
            requiredIndicator=""
            mb="0.5rem"
          >
            Email address
          </FormLabel>
          <Input
            autoComplete="new-password"
            id="email"
            name="email"
            type="email"
            placeholder="Enter email address"
            {...register("email")}
          />
          <FormErrorMessage fontSize="12px">
            {errors.email?.message || errors.email}
          </FormErrorMessage>
        </FormControl>

        <FormControl isRequired isInvalid={errors.phoneNumber}>
          <FormLabel
            textStyle="bodySemiBold"
            fontWeight="600"
            htmlFor="phoneNumber"
            requiredIndicator=""
            mb="0.5rem"
          >
            Phone number
          </FormLabel>
          <Input
            autoComplete="off"
            id="phoneNumber"
            type="number"
            placeholder="Enter phone number"
            {...register("phoneNumber")}
          />
          <FormErrorMessage fontSize="12px">
            {errors.phoneNumber?.message || errors.phoneNumber}
          </FormErrorMessage>
        </FormControl>


        <Button
          type="submit"
          w="full"
          isLoading={isSubmitting || isLoading}
          disabled={
            !isDirty ||
            !isValid ||
            isSubmitting ||
            !isCompanyAdded() ||
            isLoading
          }
          alt="Send request"
          >
          Send request
        </Button>

      
      </VStack>
    </form>
  );
}

export default BookADemoPage;
