import PictureOptimized from "../../shared/PictureOptimized";

const ImageElement = ({
  srcX1,
  srcX2,
  srcX3,
  fallbackSrc,
  alt,
  imageBlockSize,
}) => {
  return (
    <PictureOptimized
      srcX1={srcX1}
      srcX2={srcX2}
      srcX3={srcX3}
      fallbackSrc={fallbackSrc}
      alt={alt}
      //maxWidth="42.5rem" // 680px
      maxWidth={imageBlockSize}
      width="100%"
    />
  );
};

export default ImageElement;
