import { Icon } from "@chakra-ui/react";

export const CustomImagePlaceholderIcon = (props) => (
  <Icon viewBox="0 0 20 18" fill="none" {...props}>
    <g clipPath="url(#clip0_2150_1325)">
      <path
        d="M5.95109 4.06057H6.62747L6.87924 3.43279L7.44081 2.03249C7.53089 1.80787 7.74856 1.66064 7.99057 1.66064H12.1996C12.4437 1.66064 12.6629 1.81049 12.7515 2.03803L13.291 3.42344L13.5391 4.06057H14.2228H16.6842C17.451 4.06057 18.0727 4.68222 18.0727 5.44906V14.2722C18.0727 15.039 17.451 15.6606 16.6842 15.6606H3.47208C2.70523 15.6606 2.08359 15.039 2.08359 14.2722V5.44906C2.08359 4.68222 2.70523 4.06057 3.47208 4.06057H5.95109Z"
        stroke="#D7D7D7"
        strokeWidth="2"
      />
      <circle
        cx="10.0779"
        cy="9.60212"
        r="2.89082"
        stroke="#D7D7D7"
        strokeWidth="2"
      />
      <circle
        cx="15.5032"
        cy="6.83095"
        r="1"
        fill="#D7D7D7"
        stroke="#D7D7D7"
        strokeWidth="0.425808"
      />
    </g>
    <defs>
      <clipPath id="clip0_2150_1325">
        <rect
          width="19"
          height="17"
          fill="white"
          transform="translate(0.578125 0.160645)"
        />
      </clipPath>
    </defs>
  </Icon>
);
