import { Icon } from "@chakra-ui/react";

export const CustomCheckIcon = (props) => (
  <Icon viewBox="0 0 24 17" {...props}>
    <path
      d="M21.66.255c-.11.052-.288.172-.396.268-.108.096-2.765 3.094-5.905 6.662L9.65 13.673l-3.427-2.692C4.338 9.5 2.686 8.23 2.551 8.159c-.626-.334-1.262-.14-1.772.541-.464.621-.475 1.22-.032 1.738.149.173 7.261 5.785 7.659 6.042.686.445 1.651.493 2.414.121.132-.064.352-.212.489-.329.295-.251 12.001-13.553 12.197-13.859.39-.609.286-1.197-.31-1.748C22.82.316 22.54.183 22.16.17c-.245-.008-.337.008-.5.085"
      fill={props.color ? props.color : "black"}
    />
  </Icon>
);
