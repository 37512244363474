import { Icon } from "@chakra-ui/react";

export const CustomQualificationsSkillsIcon = (props) => (
  <Icon viewBox="0 0 28 24" fill="none" {...props}>
 <path fillRule="evenodd" clipRule="evenodd" d="M0.415007 19.2022C0.615553 19.4028 0.912069 19.473 1.18128 19.3838L3.92132 18.4757C4.16828 18.3938 4.35517 18.1899 4.41516 17.9367C4.47516 17.6836 4.39967 17.4174 4.21571 17.2334L2.38375 15.4015C2.19978 15.2175 1.93362 15.142 1.68047 15.202C1.42732 15.262 1.22334 15.4489 1.1415 15.6959L0.23341 18.4359C0.144191 18.7052 0.214461 19.0017 0.415007 19.2022ZM2.1271 17.4901L2.18285 17.3219L2.2953 17.4344L2.1271 17.4901Z" fill={props.color ? props.color : "#CDEE24"}/>
<path fillRule="evenodd" clipRule="evenodd" d="M16.494 6.96094C16.6929 6.96094 16.8837 6.88192 17.0244 6.74127L18.4137 5.35189L18.4138 5.35187C19.0944 4.67122 19.0944 3.56763 18.4137 2.88699L16.7287 1.20198C16.0481 0.521316 14.9445 0.521316 14.2638 1.20198L12.8745 2.59136C12.7338 2.73201 12.6548 2.92278 12.6548 3.12169C12.6548 3.3206 12.7338 3.51137 12.8745 3.65202L15.9637 6.74127C16.1044 6.88192 16.2951 6.96094 16.494 6.96094ZM14.4654 3.12169L15.3245 2.26264C15.4194 2.16776 15.5732 2.16776 15.6681 2.26264L17.3531 3.94765C17.4479 4.04247 17.4479 4.19633 17.3531 4.29123C17.3531 4.29124 17.3531 4.29125 17.3531 4.29126L16.494 5.15028L14.4654 3.12169Z" fill={props.color ? props.color : "#CDEE24"}/>
<path fillRule="evenodd" clipRule="evenodd" d="M5.56269 17.8906C5.7616 17.8906 5.95237 17.8116 6.09302 17.671L17.025 6.73891C17.1657 6.59825 17.2447 6.40749 17.2447 6.20857C17.2447 6.00966 17.1657 5.81889 17.025 5.67824L15.4804 4.13365C15.1875 3.84076 14.7126 3.84077 14.4197 4.13366C14.1269 4.42656 14.1269 4.90143 14.4198 5.19432L15.434 6.20858L5.56269 16.08L4.5484 15.0657C4.2555 14.7728 3.78063 14.7728 3.48774 15.0657C3.19484 15.3586 3.19484 15.8334 3.48774 16.1263L5.03236 17.671C5.17301 17.8116 5.36378 17.8906 5.56269 17.8906Z" fill={props.color ? props.color : "#CDEE24"}/>
<path fillRule="evenodd" clipRule="evenodd" d="M4.55006 16.128C4.84295 15.8351 4.84294 15.3602 4.55005 15.0673L3.53576 14.0531L13.4071 4.18168L14.4214 5.19598C14.7143 5.48887 15.1892 5.48887 15.4821 5.19598C15.775 4.90308 15.775 4.42821 15.4821 4.13531L13.9374 2.59069C13.7968 2.45004 13.606 2.37102 13.4071 2.37102C13.2082 2.37102 13.0174 2.45004 12.8768 2.59069L1.94477 13.5227C1.80411 13.6634 1.7251 13.8542 1.7251 14.0531C1.7251 14.252 1.80412 14.4428 1.94477 14.5834L3.4894 16.128C3.7823 16.4209 4.25717 16.4209 4.55006 16.128Z" fill={props.color ? props.color : "#CDEE24"}/>
<path fillRule="evenodd" clipRule="evenodd" d="M14.6626 17.9796C14.0854 17.931 13.522 17.6858 13.0809 17.2448C12.3528 16.5166 12.1583 15.4551 12.5018 14.5482C12.6063 14.2723 12.5394 13.9608 12.3308 13.7522L11.1725 12.5939L12.0206 11.7458L13.179 12.9041C13.3876 13.1127 13.6991 13.1796 13.9749 13.0751C14.8819 12.7316 15.9434 12.9261 16.6715 13.6542L16.6715 13.6542C17.1126 14.0953 17.3578 14.6587 17.4063 15.2359L16.4908 14.3205C16.198 14.0276 15.7231 14.0276 15.4302 14.3205L13.7472 16.0035C13.6065 16.1441 13.5275 16.3349 13.5275 16.5338C13.5275 16.7327 13.6065 16.9235 13.7472 17.0641L14.6626 17.9796ZM12.0203 18.3054C13.234 19.5191 15.0264 19.7979 16.5058 19.146C16.7323 19.0462 16.895 18.8411 16.9406 18.5978C16.9862 18.3545 16.9088 18.1044 16.7337 17.9294L15.3382 16.5338L15.9605 15.9115L17.3561 17.307C17.5311 17.482 17.7812 17.5594 18.0245 17.5139C18.2678 17.4683 18.4729 17.3056 18.5727 17.0791C19.2246 15.5997 18.9459 13.8072 17.7321 12.5936M17.7321 12.5935C16.6979 11.5594 15.2434 11.2041 13.921 11.5248L12.5509 10.1548C12.2581 9.86188 11.7832 9.86188 11.4903 10.1548L9.58149 12.0636C9.28859 12.3565 9.28859 12.8313 9.58149 13.1242L10.9515 14.4942C10.6308 15.8167 10.9861 17.2712 12.0203 18.3054" fill={props.color ? props.color : "#CDEE24"}/>
<path fillRule="evenodd" clipRule="evenodd" d="M4.25243 1.50872C4.8296 1.55724 5.39304 1.80245 5.8341 2.24351C6.56222 2.97163 6.75673 4.03315 6.41322 4.9401C6.30873 5.21597 6.37567 5.52748 6.58427 5.73608L7.74256 6.89437L6.89441 7.74253L5.73608 6.58422C5.52748 6.37563 5.21598 6.30869 4.94011 6.41318C4.03316 6.75668 2.97168 6.56218 2.24356 5.83406L2.24354 5.83404C1.80247 5.39301 1.55726 4.82956 1.50875 4.25237L2.42419 5.16781C2.71709 5.4607 3.19196 5.4607 3.48485 5.16781L5.16785 3.48481C5.30851 3.34416 5.38752 3.15339 5.38752 2.95448C5.38752 2.75557 5.30851 2.5648 5.16785 2.42415L4.25243 1.50872ZM6.89476 1.18285C5.68107 -0.030843 3.8886 -0.309586 2.40922 0.342257C2.18271 0.442061 2.02003 0.647198 1.97445 0.890489C1.92888 1.13378 2.00627 1.38389 2.1813 1.55892L3.57686 2.95448L2.95452 3.57682L1.55896 2.18126C1.38393 2.00623 1.13382 1.92883 0.890531 1.97441C0.64724 2.01999 0.442103 2.18267 0.342299 2.40918C-0.309532 3.88853 -0.0308566 5.68104 1.1829 6.89472M1.18292 6.89474C2.21709 7.9289 3.67162 8.28415 4.99408 7.96353L6.36409 9.33352C6.65698 9.6264 7.13185 9.6264 7.42474 9.33351L9.33355 7.4247C9.62645 7.13181 9.62645 6.65693 9.33355 6.36404L7.96357 4.99406C8.28419 3.6716 7.92894 2.21703 6.89476 1.18285" fill={props.color ? props.color : "#CDEE24"}/>
<path fillRule="evenodd" clipRule="evenodd" d="M3.15174 18.296C3.35229 18.4965 3.64881 18.5668 3.91802 18.4776L5.79853 17.8543C6.04549 17.7725 6.23238 17.5685 6.29237 17.3153C6.35236 17.0622 6.27687 16.796 6.09291 16.6121L3.00366 13.5228C2.8197 13.3389 2.55354 13.2634 2.30039 13.3234C2.04723 13.3834 1.84326 13.5703 1.76141 13.8172L1.1382 15.6977C1.04898 15.9669 1.11924 16.2634 1.31978 16.464L3.15174 18.296ZM3.88528 16.9082L2.70758 15.7304L2.80276 15.4432L4.1725 16.813L3.88528 16.9082Z" fill={props.color ? props.color : "#CDEE24"}/>
<path d="M5.35736 16.8867L2.03448 17.8657L2.84808 14.4985L5.35736 16.8867Z" fill={props.color ? props.color : "#CDEE24"}/>
  </Icon>
);
