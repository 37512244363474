import { Icon } from "@chakra-ui/react";

export const CustomCameraIcon = (props) => (
  <Icon viewBox="0 0 54 54" fill="none" {...props}>
    {/* commented lines because of iphone not rendering those svg tags */}
    {/* <g filter="url(#filter0_d_2038_11598)"> */}
    <circle cx="27" cy="27" r="22" fill="#CDEE24" className="bgFill" />
    {/* </g> */}
    <path
      d="M21.8163 20.9675H22.4927L22.7445 20.3397L23.4498 18.5809C23.6019 18.2017 23.9694 17.9531 24.378 17.9531H29.6646C30.0768 17.9531 30.4468 18.2061 30.5964 18.5903L31.274 20.3304L31.5221 20.9675H32.2059H35.2974C36.4019 20.9675 37.2974 21.8629 37.2974 22.9675V34.0495C37.2974 35.1541 36.4019 36.0495 35.2974 36.0495H18.7026C17.5981 36.0495 16.7026 35.1541 16.7026 34.0495V22.9675C16.7026 21.8629 17.5981 20.9675 18.7026 20.9675H21.8163Z"
      stroke="black"
      strokeWidth="2"
      className="fgStroke"
    />
    <circle
      cx="26.9998"
      cy="28.1799"
      r="3.88696"
      stroke="black"
      strokeWidth="2"
      className="fgStroke"
    />
    <circle
      cx="33.814"
      cy="24.7031"
      r="1.52344"
      fill="black"
      className="fgFill"
    />
    <defs>
      {/* <filter id="filter0_d_2038_11598" x="0" y="0" width="54" height="54"> */}
      <feBlend
        mode="normal"
        in2="BackgroundImageFix"
        result="effect1_dropShadow_2038_11598"
      />
      <feBlend
        mode="normal"
        in="SourceGraphic"
        in2="effect1_dropShadow_2038_11598"
        result="shape"
      />
      {/* </filter> */}
    </defs>
  </Icon>
);
