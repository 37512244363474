import {
  FormControl,
  Button,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Text,
  VStack,
  useToast,
  Flex,
} from "@chakra-ui/react";
import { CustomEyeClosedIcon, CustomEyeOpenIcon } from "../../assets/icons";
import { useContext, useState } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AuthContext } from "../../context/AuthContext";
import { loginFn, getMeFn } from "../../services/apiServices";
import { getErrorResponsePayload } from "../../utils/helpers";
import CustomToast from "../../components/private/shared/UI/CustomToast";

const validationSchema = Yup.object().shape({
  username: Yup.string()
    .required("Please enter your email")
    .email("Please enter a valid email address"),
  password: Yup.string().required("Please enter your password"),
});

const formOptions = {
  resolver: yupResolver(validationSchema),
  mode: "onBlur",
};

function LoginPage() {
  const [show, setShow] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty, isValid },
  } = useForm(formOptions);

  const { setAuthTokens, setAuthUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const toast = useToast();
  const location = useLocation();

  let nextPathname = location?.state?.next?.pathname ?? "/";
  let nextSearch = location?.state?.next?.search ?? "";
  if (nextPathname === "/logout") {
    nextPathname = "/";
  }

  const showPasswordClickHandler = () => setShow(!show);

  const [enabledGetMeQuery, setEnabledGetMeQuery] = useState(false);
  useQuery({
    queryKey: ["GetMe"],
    queryFn: () => getMeFn(),
    enabled: enabledGetMeQuery,
    onSuccess: (response) => {
      setAuthUser(response?.data);
      if (nextPathname !== "/") {
        setTimeout(() => {
          navigate(
            { pathname: nextPathname, search: nextSearch },
            { replace: true }
          );
        }, 2);
      }
      setEnabledGetMeQuery(false);
    },
    onError: (error) => {
      setEnabledGetMeQuery(false);
    },
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: (data) => loginFn(data),
    onSuccess: (response) => {
      setAuthTokens({
        token: response?.token,
        refreshToken: response?.refresh_token,
      });
      setEnabledGetMeQuery(true);
    },
    onError: (error) => {
      const { code } = getErrorResponsePayload(error);
      setEnabledGetMeQuery(false);
      if (code === 401) {
        toast({
          position: "top",
          render: (props) => (
            <CustomToast
              status="warning"
              title=""
              description="Sorry, we don't recognise that email and password. Please check your login details and try again."
              onClose={props.onClose}
              id={props.id}
            />
          ),
        });
      }
    },
  });

  const onSubmit = async (data) => {
    mutate({
      ...data,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <VStack spacing={8} alignItems="flex-start">
        <Heading
          as="h1"
          alignSelf={{ base: "center", sm: "flex-start" }}
          fontSize={{ base: "2rem", sm: "3.125rem" }} //50px
          lineHeight={{ base: "2.5rem", sm: "3.9rem" }} //63px
          mb="1rem"
        >
          Sign in
        </Heading>

        <FormControl isRequired isInvalid={errors.username}>
          <FormLabel
            textStyle="bodySemiBold"
            fontWeight="600"
            htmlFor="username"
            requiredIndicator=""
          >
            Email address
          </FormLabel>
          <Input
            id="username"
            name="username"
            type="email"
            placeholder="Enter email address"
            {...register("username")}
          />
          <FormErrorMessage fontSize="12px">
            {errors.username?.message || errors.username}
          </FormErrorMessage>
        </FormControl>

        <FormControl isRequired isInvalid={errors.password}>
          <FormLabel
            textStyle="bodySemiBold"
            fontWeight="600"
            htmlFor="password"
            requiredIndicator=""
          >
            Password
          </FormLabel>
          <InputGroup>
            <Input
              id="password"
              name="password"
              type={show ? "text" : "password"}
              placeholder="Enter password"
              {...register("password")}
            />
            <InputRightElement>
              {show ? (
                <CustomEyeClosedIcon
                  onClick={showPasswordClickHandler}
                  boxSize="1.125rem"
                  cursor="pointer"
                  color="placeholderColor"
                />
              ) : (
                <CustomEyeOpenIcon
                  onClick={showPasswordClickHandler}
                  boxSize="1.125rem"
                  cursor="pointer"
                  color="placeholderColor"
                />
              )}
            </InputRightElement>
          </InputGroup>
          <FormErrorMessage fontSize="12px">
            {errors.password?.message || errors.password}
          </FormErrorMessage>
        </FormControl>

        <Flex w="full" justifyContent="flex-end" pb="0.5rem">
          <Link
            as={RouterLink}
            to="/forgot-password"
            color="limeText"
            fontSize="1rem"
          >
            Forgot password?
          </Link>
        </Flex>

        <Button
          type="submit"
          w="full"
          isLoading={isSubmitting || isLoading}
          // isLoading={isSubmitting}
          isDisabled={!isDirty || !isValid || isSubmitting || isLoading}
          // isDisabled={!isDirty || !isValid || isSubmitting}
          mt={3}
          alt="Login"
        >
          Login
        </Button>

        <Text alignSelf="center" fontSize="0.875rem">
          Don't have an account yet?{" "}
          <Link
            as={RouterLink}
            to="/get-started"
            color="limeText"
            fontSize="inherit"
          >
            Sign up
          </Link>
        </Text>
      </VStack>
    </form>
  );
}

export default LoginPage;
