import { useContext } from "react";
import PublicContactUsPage from "../public/ContactUsPage";
import LayoutLogin from "../../components/public/login/LayoutLogin";
import { AuthContext } from "../../context/AuthContext";
import { Navigate } from "react-router-dom";

const ContactUsPage = () => {
  const { isAuthenticated, isRoleClient, isRoleCandidate, authState } =
    useContext(AuthContext);
  return !isAuthenticated() ? (
    <LayoutLogin scheme="mixed" page="contact">
      <PublicContactUsPage />
    </LayoutLogin>
  ) : isRoleCandidate() ? (
    <LayoutLogin scheme="mixed" page="contact" userType="candidate">
      <PublicContactUsPage />
    </LayoutLogin>
  ) : isRoleClient() ? (
    authState?.user?.membershipStatus ? (
      <LayoutLogin
        scheme="mixed"
        page="contact"
        userType="client"
        membershipStatus
      >
        <PublicContactUsPage />
      </LayoutLogin>
    ) : (
      <LayoutLogin scheme="mixed" page="contact" userType="client">
        <PublicContactUsPage />
      </LayoutLogin>
    )
  ) : (
    <Navigate to="/" replace />
  );
};

export default ContactUsPage;
