import { Icon } from "@chakra-ui/react";

export const CustomJobPrefIcon = (props) => (
  <Icon viewBox="0 0 59 59" fill="none" {...props}>
    <circle cx="29.5781" cy="29.6431" r="28.8848" fill={props.bgcircle} />
    <rect
      x="16.6152"
      y="17.7544"
      width="22.5418"
      height="23.9487"
      rx="2.25"
      fill={props.whitebg}
      stroke={props.blackstroke}
      strokeWidth="1.5"
    />
    <line
      x1="27.291"
      y1="24.6118"
      x2="35.2791"
      y2="24.6118"
      stroke={props.blackstroke}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <line
      x1="27.291"
      y1="30.1431"
      x2="35.2791"
      y2="30.1431"
      stroke={props.blackstroke}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <line
      x1="27.291"
      y1="35.6274"
      x2="35.2791"
      y2="35.6274"
      stroke={props.blackstroke}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M20.0762 24.1266L21.5868 25.4712L24.1166 23.0806"
      stroke={props.limestroke}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M20.0762 29.4704L21.5868 30.815L24.1166 28.4243"
      stroke={props.limestroke}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M20.0762 34.8141L21.5868 36.1587L24.1166 33.7681"
      stroke={props.limestroke}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <circle cx="35.4824" cy="38.4731" r="5.29688" fill={props.whitebg} />
    <path
      d="M39.499 42.501L43.2871 46.2778M41.1035 38.6353C41.1035 41.6502 38.6594 44.0942 35.6445 44.0942C32.6296 44.0942 30.1855 41.6502 30.1855 38.6353C30.1855 35.6203 32.6296 33.1763 35.6445 33.1763C38.6594 33.1763 41.1035 35.6203 41.1035 38.6353Z"
      stroke={props.blackstroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
