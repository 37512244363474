import React, { createContext, useState } from "react";

const AuthContext = createContext();
const { Provider } = AuthContext;

const KEYS = {
  token: process.env.REACT_APP_TOKEN,
  refreshToken: process.env.REACT_APP_REFRESH_TOKEN,
  user: process.env.REACT_APP_USER,
};

const AuthProvider = ({ children }) => {
  const token = localStorage.getItem(KEYS.token);
  const refreshToken = localStorage.getItem(KEYS.refreshToken);
  const user = localStorage.getItem(KEYS.user);
  const [authState, setAuthState] = useState({
    token,
    refreshToken,
    user: user !== "undefined" ? JSON.parse(user) : null,
  });

  const setAuthInfo = ({ token, refreshToken, user }) => {
    localStorage.setItem(KEYS.token, token);
    localStorage.setItem(KEYS.refreshToken, refreshToken);
    localStorage.setItem(KEYS.user, JSON.stringify(user));

    setAuthState({ token, refreshToken, user });
  };

  const setUser = (user) => {
    localStorage.setItem(KEYS.user, JSON.stringify(user));
    setAuthState({
      ...authState,
      user,
    });
  };

  const setTokens = ({ token, refreshToken }) => {
    localStorage.setItem(KEYS.token, token);
    localStorage.setItem(KEYS.refreshToken, refreshToken);

    setAuthState({ ...authState, token, refreshToken });
  };

  const logout = async () => {
    try {
      localStorage.removeItem(KEYS.token);
      localStorage.removeItem(KEYS.refreshToken);
      localStorage.removeItem(KEYS.user);
      localStorage.removeItem("filters");
      localStorage.removeItem("query");
      localStorage.removeItem("isReload");
      setAuthState({
        token: null,
        user: null,
        refreshToken: null,
      });
    } catch (onError) {
      console.log(onError);
    }
  };

  const isAuthenticated = () => {
    if (!authState.token || authState.user === null) {
      return false;
    }
    return true;
  };

  const isSetUpConfirmed = () => {
    const { user } = authState;
    return !!user?.setUpConfirmed;
  };

  const isRoleAdmin = () => {
    const roles = authState?.user?.roles || [];
    return roles.includes("ROLE_ADMIN") && isAuthenticated();
  };

  const isRoleClient = () => {
    // const roles = authState?.user?.roles || [];
    const typeClient = authState?.user?.type === "type_client";
    return isAuthenticated() && typeClient;
  };

  const isRoleCandidate = () => {
    const typeCandidate = authState?.user?.type === "type_candidate";
    return typeCandidate && isAuthenticated();
  };

  const getAccessToken = () => {
    return localStorage.getItem(KEYS.token);
  };

  const getRefreshToken = () => {
    return localStorage.getItem(KEYS.refreshToken);
  };

  const getUser = () => {
    return localStorage.getItem(KEYS.user);
  };

  return (
    <Provider
      value={{
        KEYS,
        authState,
        setAuthState: (authInfo) => setAuthInfo(authInfo),
        setAuthTokens: (tokens) => setTokens(tokens),
        setAuthUser: (user) => setUser(user),
        logout,
        isAuthenticated,
        isSetUpConfirmed,
        isRoleAdmin,
        isRoleClient,
        isRoleCandidate,
        getAccessToken,
        getRefreshToken,
        getUser,
      }}
    >
      {children}
    </Provider>
  );
};

export { AuthContext, AuthProvider };
