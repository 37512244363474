import { RegularText } from "./Texts";

const lastUpdatedDate = "March 15, 2023";

const Intro = () => {
  return (
    <>
      <RegularText text={`Last updated: ${lastUpdatedDate}`} />
      <RegularText text="Please read these terms and conditions carefully before using Our Service." />
    </>
  );
};

export default Intro;
