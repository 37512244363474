import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Box, Text, Flex, IconButton, Heading, Circle } from '@chakra-ui/react';
import Slider from 'react-slick';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import user1 from "../../assets/images/public/testimonials/user1.jpg"
import user2 from "../../assets/images/public/testimonials/user2.jpg"
import user3 from "../../assets/images/public/testimonials/user3.jpg"
import user4 from "../../assets/images/public/testimonials/user4.jpg"
import user5 from "../../assets/images/public/testimonials/user5.jpg"
import user6 from "../../assets/images/public/testimonials/user6.jfif"
import logo1 from "../../assets/images/public/testimonials/logo/logo1.jfif"
import logo2 from "../../assets/images/public/testimonials/logo/logo2.jfif"
import logo3 from "../../assets/images/public/testimonials/logo/logo3.jfif"
import logo4 from "../../assets/images/public/testimonials/logo/logo4.jfif"
import logo5 from "../../assets/images/public/testimonials/logo/logo5.jfif"
import logo6 from "../../assets/images/public/testimonials/logo/logo6.jfif"

const testimonials = [
    {
        logo: logo4,
        image: user4,
        content: "I recently started as CFO of Zodia Custody /(a digital assets custodian, majority-owned by Standard Chartered) and had to quickly build a Finance team. As a start-up, this needed to be done quickly and cheaply, whilst delivering the quality of candidates that can span both a FISE 100 regulated environment and the challenges and constant change of a digital assets start-up. I have worked with Catherine Turner many times over the last 15 years and her new Searchive platform that she and Ben Davies have built has been incredibly fast to produce candidates and highly cost-effective for hiring multiple roles. Thanks to both of them.",
        name: 'Jonathan Hugh',
        designation: 'Chief Financial Officer, Zodia Custody'
    },
    {
        logo: logo2,
        image: user2,
        content: "SearcHive supported us with a critical finance role that we had been struggling to fill, due to some really specific skills and experience that we needed to find. Within 48hrs of briefing, the team was able to source 4 candidates from their talent platform. All of these met the brief, and we went on to interview and fill the role within 2 weeks of engaging. I was super impressed by both the calibre of candidates, and by how quickly and efficiently SearcHive worked throughout the process to deliver everything that was promised. I would highly recommend SearcHive to anyone looking to hire into their Finance team!",
        name: 'Elaina Griffiths',
        designation: 'Talent Acquisition Lead at Bloom & Wild'
    },
    {
        logo: logo1,
        image: user1,
        content: 'I am happy to recommend Catherine and SearcHive. They understood the brief and business context; they listened to our feedback during the shortlisting process and interviews and adapted accordingly; they advised us carefully and objectively about candidates and ultimately found us a great senior finance person.',
        name: 'Dan Channer',
        designation: 'Group MD, Haslams'
    },
    {
        logo: logo3,
        image: user3,
        content: "I have known Catherine and her team for over 7 years now after she placed me in a career changing role at IRIS Software Group. Since then I have used her company many times to help build out my finance teams, most recently with the new Searchive platform which has been an extremely efficient and cost effective way of hiring some great new talent here at Forterro. Four candidates to be precise! Would highly recommend!",
        name: 'Jack Macaree',
        designation: 'Vice President at Forterro'
    },
    {
        logo: logo5,
        image: user5,
        content: "We worked with SearcHive for the recent recruitment of a new Head of Finance. This is a key role for UK Athletics with some very specific requirements. We were delighted with the quality of the candidates presented and enjoyed working with Catherine and her team",
        name: 'Tom Solesbury',
        designation: 'COO & General Counsel, UK Athletics'
    },
    {
        logo: logo6,
        image: user6,
        content: "I cannot over-emphasise the quality of Ben and SearcHive's knowledge of the market, and effectiveness at helping me to secure a quality candidate quickly. Ben listens, and I mean really listens, actively. The service received is outstanding. There is no pressure, but there is timely action and prompt follow-ups as needed. I highly recommend Ben and the SearcHive team and look forward to working with them again in the future.",
        name: 'Louise Abrantes',
        designation: 'Interim Financial Controller I Spire Healthcare I FCMA'
    },
];

const Testimonials = () => {
    const sliderRef = useRef(null);
    const descriptionRefs = useRef([]);
    const [isExpanded, setIsExpanded] = useState(Array(testimonials.length).fill(false));
    const [showButton, setShowButton] = useState(Array(testimonials.length).fill(false));

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        beforeChange: (current, next) => {
            // Check the content height for the next slide
            setTimeout(() => {
                if (descriptionRefs.current[next]) {
                    const ref = descriptionRefs.current[next];
                    const lineHeight = parseInt(window.getComputedStyle(ref).lineHeight, 10);
                    const maxHeight = lineHeight * 4; // 4 lines
                    const newShowButton = [...showButton];
                    newShowButton[next] = ref.scrollHeight > maxHeight;
                    setShowButton(newShowButton);
                }
            }, 0);
        }
    };

    const toggleExpand = (index) => {
        const newIsExpanded = [...isExpanded];
        newIsExpanded[index] = !newIsExpanded[index];
        setIsExpanded(newIsExpanded);
    };

    useEffect(() => {
        if (descriptionRefs.current[0]) {
            const ref = descriptionRefs.current[0];
            const lineHeight = parseInt(window.getComputedStyle(ref).lineHeight, 10);
            const maxHeight = lineHeight * 4; // 4 lines
            const newShowButton = [...showButton];
            newShowButton[0] = ref.scrollHeight > maxHeight;
            setShowButton(newShowButton);
        }
    }, []);


    return (
        <Fragment>
            <Box marginTop={'8.75rem'}>
                <Heading
                    fontSize={{ base: "2rem", lg: "3.125rem" }} //50px
                    lineHeight={{ base: "2.5rem", lg: "3.9375rem" }} //63px
                    textTransform="uppercase"
                    position="relative"
                >
                    <Text as="span" position="relative">
                        Testimonials
                        <Box position="absolute" w="full" h="full" top="0" left="0">
                            <Circle
                                boxSize="1.125rem"
                                bgColor="electricLime"
                                position="absolute"
                                bottom="15%"
                                right="-25px"
                            />
                        </Box>
                    </Text>
                </Heading>
            </Box>
            <Box maxW="85%" mx="auto" p={4} position="relative" margin={'100px auto'} borderWidth="1px" borderRadius="3rem" className='testimonial_slick'>
                <Slider ref={sliderRef} {...settings} id="unique_2">
                    {testimonials.map((testimonial, index) => (
                        <Box
                            key={index}
                            overflow="hidden"
                            p={'20'}
                            textAlign="left"
                        >
                            <Flex>
                                <img
                                    loading={'lazy'}
                                    src={testimonial.image}
                                    alt={testimonial.name || ""}
                                    style={{ marginRight: '4rem', borderRadius: '2rem', height: '250px', width: "250px" }}
                                />
                                <Box>
                                    <Text fontSize="2xl" mb={2} ref={el => descriptionRefs.current[index] = el}
                                        className={`description ${isExpanded[index] ? 'expanded' : 'collapsed'}`}>
                                        {testimonial.content}
                                    </Text>
                                    {showButton[index] && (
                                        <button onClick={() => toggleExpand(index)} className="btn btn-link p-0 show_more_less_btn">
                                            {isExpanded[index] ? 'Show less' : 'Show more'}
                                        </button>
                                    )}
                                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} width={'100%'}>
                                        <Box>
                                            <Text fontWeight="bold" fontSize="3xl" textTransform={'uppercase'}>{testimonial.name}</Text>
                                            <Text color="gray.500" fontSize="xl">{testimonial.designation}</Text>
                                        </Box>
                                        <img
                                            loading={'lazy'}
                                            src={testimonial.logo}
                                            alt={testimonial.name || ""}
                                            style={{ marginRight: '4rem', borderRadius: '1rem', height: '100px', width: "100px" }}
                                        />
                                    </Box>
                                </Box>
                            </Flex>
                        </Box>
                    ))}
                </Slider>
                <Flex justifyContent="space-between" mt={4}>
                    <Box></Box> {/* Empty Box to balance the layout */}
                    <Box className='slick_btn'>
                        <IconButton
                            icon={<ChevronLeftIcon />}
                            onClick={() => sliderRef.current.slickPrev()}
                            aria-label="Previous testimonial"
                            size="sm"
                            mx={1}
                            className='prev_btn'
                        />
                        <IconButton
                            icon={<ChevronRightIcon />}
                            onClick={() => sliderRef.current.slickNext()}
                            aria-label="Next testimonial"
                            size="sm"
                            mx={1}
                            className='next_btn'
                        />
                    </Box>
                </Flex>
            </Box>
        </Fragment>
    );
};

export default Testimonials;
