import { Icon } from "@chakra-ui/react";

export const CustomChatBubbleIcon = (props) => (
  <Icon viewBox="0 0 24 25" fill="none" {...props}>
    <path
      d="M21.61 19.7836C23.1359 18.4236 24 16.6109 24 14.7298C24 12.7306 23.0641 10.8609 21.3646 9.46483C19.7107 8.10654 17.5214 7.3584 15.1998 7.3584C12.8782 7.3584 10.689 8.10654 9.03522 9.46493C7.33579 10.8609 6.3999 12.7306 6.3999 14.7298C6.3999 16.7289 7.33579 18.5986 9.03522 19.9947C10.689 21.3531 12.8782 22.1012 15.1998 22.1012C15.5092 22.1012 15.8204 22.0875 16.1291 22.0604L16.6563 22.5174C18.4729 24.0916 20.796 24.9581 23.1997 24.9582H23.9997V23.2554L23.7654 23.0211C22.8411 22.0941 22.1087 20.994 21.61 19.7836ZM17.7042 21.3083L16.6462 20.3912L16.2934 20.4345C15.9306 20.4788 15.5654 20.5011 15.1998 20.5011C11.2299 20.5011 7.99989 17.9121 7.99989 14.7298C7.99989 11.5475 11.2299 8.95838 15.1998 8.95838C19.1698 8.95838 22.3997 11.5474 22.3997 14.7298C22.3997 16.3153 21.6087 17.7954 20.1725 18.8976L19.7117 19.2512L19.9243 19.8529C20.36 21.0819 21.0004 22.2283 21.8184 23.2437C20.295 22.9894 18.8714 22.3197 17.7042 21.3083Z"
      fill={props.color ? props.color : "black"}
    />
    <path
      d="M2.20923 16.0316C2.95808 15.0837 3.54605 14.0192 3.94961 12.8805L4.16061 12.2818L3.70026 11.9285C2.34588 10.8892 1.59998 9.49432 1.59998 8.00078C1.59998 4.99961 4.6504 2.55799 8.39991 2.55799C11.1583 2.55799 13.5382 3.87948 14.6037 5.77296C14.8015 5.76336 15.0002 5.75796 15.1998 5.75796C15.6007 5.75796 15.998 5.77741 16.3915 5.81631C15.9798 4.74957 15.2647 3.77578 14.2815 2.96829C12.7035 1.67195 10.6149 0.958008 8.39991 0.958008C6.18493 0.958008 4.09636 1.67195 2.51817 2.96829C0.89429 4.30222 0 6.08945 0 8.00078C0 9.79076 0.817591 11.5155 2.26248 12.8129C1.79095 13.9476 1.10197 14.9791 0.234348 15.8493L0 16.0836V17.7578H0.799992C2.27933 17.7576 3.73832 17.4131 5.0615 16.7516C4.9164 16.2017 4.83139 15.6376 4.80795 15.0694C4.00485 15.54 3.1252 15.8658 2.20923 16.0316Z"
      fill={props.color ? props.color : "black"}
    />
  </Icon>
);
