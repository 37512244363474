import { Icon } from "@chakra-ui/react";

export const CustomMoreInfoIcon = (props) => (
  <Icon viewBox="0 0 26 26" fill="none" {...props}>
    <circle cx="13" cy="13" r="12.5" fill="white" stroke="#D7D7D7" />
    <rect
      x="11.7204"
      y="11.3613"
      width="2.56272"
      height="8.80284"
      rx="1.28136"
      fill="black"
    />
    <circle cx="13.0007" cy="8.2795" r="1.50607" fill="black" />
  </Icon>
);
