import { Icon } from "@chakra-ui/react";

export const CustomExperienceEmploymentIcon = (props) => (
  <Icon viewBox="0 0 29 24" fill="none" {...props}>
    <path
      d="M3.16406 2.23437C3.16406 1.68209 3.61178 1.23438 4.16406 1.23438H14.4971C15.0494 1.23438 15.4971 1.68209 15.4971 2.23438V22.2344H4.16406C3.61178 22.2344 3.16406 21.7867 3.16406 21.2344V2.23437Z"
      stroke="#CDEE24"
      strokeWidth="2"
    />
    <path
      d="M15.498 8.625H23.9901C24.5424 8.625 24.9901 9.07272 24.9901 9.625V21.2327C24.9901 21.785 24.5424 22.2327 23.9901 22.2327H15.498V8.625Z"
      stroke="#CDEE24"
      strokeWidth="2"
    />
    <path
      d="M6.83594 14.9336C6.83594 14.3813 7.28365 13.9336 7.83594 13.9336H10.824C11.3763 13.9336 11.824 14.3813 11.824 14.9336V21.7279H6.83594V14.9336Z"
      stroke="#CDEE24"
      strokeWidth="2"
    />
    <line
      x1="19.3633"
      y1="13.6289"
      x2="21.1217"
      y2="13.6289"
      stroke="#CDEE24"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="7.12891"
      y1="7.12109"
      x2="11.5318"
      y2="7.12109"
      stroke="#CDEE24"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="19.3633"
      y1="16.5508"
      x2="21.1217"
      y2="16.5508"
      stroke="#CDEE24"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="7.12891"
      y1="10.043"
      x2="11.5318"
      y2="10.043"
      stroke="#CDEE24"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </Icon>
);
