import { defineStyle, defineStyleConfig } from "@chakra-ui/styled-system";

const baseStyle = defineStyle({
  //   fontSize: "md",
  //   marginEnd: "3",
  mb: "4",
  //   fontWeight: "medium",
  //   transitionProperty: "common",
  //   transitionDuration: "normal",
  //   opacity: 1,
  //   _disabled: {
  //     opacity: 0.4,
  //   },
});

export const FormLabel = defineStyleConfig({
  baseStyle,
});
