import { useContext } from "react";
import PublicPrivacyPage from "../public/PrivacyPage";
import LayoutMembership from "../../components/public/shared/layout/LayoutMembership";
import LayoutClientCandidate from "../../components/private/shared/LayoutClientCandidate";
import { AuthContext } from "../../context/AuthContext";
import { Navigate } from "react-router-dom";

const PrivacyPage = () => {
  const { isAuthenticated, isRoleClient, isRoleCandidate, authState } =
    useContext(AuthContext);

  return !isAuthenticated() ? (
    <LayoutMembership scheme="black">
      <PublicPrivacyPage />
    </LayoutMembership>
  ) : isRoleCandidate() ? (
    <LayoutClientCandidate
      pseudoElementsType="two"
      userType="candidate"
      membershipStatus
    >
      <PublicPrivacyPage />
    </LayoutClientCandidate>
  ) : isRoleClient() ? (
    authState?.user?.membershipStatus ? (
      <LayoutClientCandidate
        pseudoElementsType="two"
        userType="client"
        membershipStatus
      >
        <PublicPrivacyPage />
      </LayoutClientCandidate>
    ) : (
      <LayoutClientCandidate pseudoElementsType="two" userType="client">
        <PublicPrivacyPage />
      </LayoutClientCandidate>
    )
  ) : (
    <Navigate to="/" replace />
  );
};

export default PrivacyPage;
