import { Flex } from "@chakra-ui/react";
import Landing from "../../components/public/clients/Landing";
import Footer from "../../components/public/clients/Footer";
import ThreeSymbolsSection from "../../components/public/shared/ThreeSymbolsSection";
import NumberedSection from "../../components/public/home/NumberedSection";
import findSecX3Src from "../../assets/images/public/shared/find_candidate_x3.webp";
import findSecFallbacksrc from "../../assets/images/public/shared/find_candidate_x3.png";
import searchSecX3Src from "../../assets/images/public/shared/search_candidate_x3.webp";
import searchSecFallbacksrc from "../../assets/images/public/shared/search_candidate_x3.png";
import communicateSecX3Src from "../../assets/images/public/shared/communicate_x3.webp";
import communicateSecFallbacksrc from "../../assets/images/public/shared/communicate_x3.png";
import hireSecX1Src from "../../assets/images/public/candidates/hire_cand_x1.webp";
import hireSecX3Src from "../../assets/images/public/candidates/hire_cand_x3.webp";
import hireSecFallbacksrc from "../../assets/images/public/candidates/hire_cand_x3.png";

const ClientsPage = () => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      w="full"
      minH="100vh"
      overflow="hidden"
    >
      <Landing />
      <NumberedSection
        bgColor="backgroundGrey"
        imageBlockSize="37.5rem"
        textBlockSize="28.3125rem"
        gap={{ base: "3rem", md: "5rem", xl: "8.875rem" }}
        stepNumber=""
        imageFirst
        lastWord="why"
        noCircle={true}
        bodyText="Directly communicate with the hidden talent you have been searching for. SearcHive is the intelligent and user-friendly tool to hire great finance talent. And fast!"
        separatorText="intelligent and user-friendly"
        srcX1={findSecX3Src}
        srcX2={findSecX3Src}
        srcX3={findSecX3Src}
        fallbackSrc={findSecFallbacksrc}
        hasBottomElement
        pseudoElementsTypeFive
      />

      <ThreeSymbolsSection
        heading1="Instant"
        description1="Real-time communication."
        heading2="Cost-saving"
        description2="No recruitment fees."
        heading3="High quality"
        description3="Candidates pre-screened by industry experts."
        maxTextW="20rem"
      />
      <NumberedSection
        bgColor="white"
        imageBlockSize="37.5rem"
        textBlockSize="34.0625rem"
        gap={{ base: "3rem", md: "5rem", xl: "7.875rem" }}
        stepNumber="01"
        lastWord="Search"
        bodyText="Use superior filtering and robust functionalities to intelligently search for the right candidate. Every time."
        srcX1={searchSecX3Src}
        srcX2={searchSecX3Src}
        srcX3={searchSecX3Src}
        fallbackSrc={searchSecFallbacksrc}
        pseudoElementsTypeTwo
      />
      <NumberedSection
        bgColor="backgroundGrey"
        imageBlockSize="42.6875rem"
        textBlockSize="31.875rem"
        gap={{ base: "3rem", md: "5rem", xl: "6.375rem" }}
        stepNumber="02"
        imageFirst
        lastWord="talk directly"
        bodyText="Just one click. Communicate in real-time with actively looking candidates. Pitch your roles and your company with confidence."
        srcX1={communicateSecX3Src}
        srcX2={communicateSecX3Src}
        srcX3={communicateSecX3Src}
        fallbackSrc={communicateSecFallbacksrc}
      />
      <NumberedSection
        bgColor="white"
        imageBlockSize="42.6875rem"
        textBlockSize="31.25rem"
        gap={{ base: "3rem", md: "5rem", xl: "6.25rem" }}
        stepNumber="03"
        lastWord="hire"
        bodyText="Putting you in control of a faster hiring process. Increase your chances of securing the right talent."
        srcX1={hireSecX1Src}
        srcX2={hireSecX3Src}
        srcX3={hireSecX3Src}
        fallbackSrc={hireSecFallbacksrc}
      />

      <Footer />
    </Flex>
  );
};

export default ClientsPage;
