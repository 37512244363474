import { Box, Image, useMediaQuery } from "@chakra-ui/react";
import ringLimeSrc from "../../../../assets/vectors/ringLime.svg";
import triangleDotSrc from "../../../../assets/vectors/triangleDot.svg";

const PageHeaderLeftPseudoElementsTypeOne = ({ invertPseudoElements }) => {
  const [isLargerThanMD] = useMediaQuery("(min-width: 48em)");

  return (
    <Box w="full" position="absolute" top="3rem" left="0">
      <Image
        src={ringLimeSrc}
        loading="lazy"
        w="3rem"
        position="absolute"
        top="0"
        left={invertPseudoElements ? null : "8rem"}
        right={invertPseudoElements ? "8rem" : null}
        objectFit="cover"
      />
      {isLargerThanMD && (
        <Image
          src={triangleDotSrc}
          loading="lazy"
          w="11.125rem"
          position="absolute"
          top="1rem"
          left={invertPseudoElements ? null : "-4.5rem"}
          right={invertPseudoElements ? "-4.5rem" : null}
          objectFit="cover"
        />
      )}
    </Box>
  );
};

export default PageHeaderLeftPseudoElementsTypeOne;
