import { Icon } from "@chakra-ui/react";

export const CustomLetterIcon = (props) => (
  <Icon viewBox="0 0 27 21" fill="none" {...props}>
    <path
      d="M24.2174 1.11719H3.38413C2.23511 1.11719 1.30078 2.05151 1.30078 3.20054V17.7839C1.30078 18.9329 2.23511 19.8672 3.38413 19.8672H24.2175C25.3665 19.8672 26.3008 18.9329 26.3008 17.7838V3.20054C26.3008 2.05151 25.3665 1.11719 24.2174 1.11719ZM3.38413 2.15884H24.2175C24.2942 2.15884 24.3619 2.18682 24.4339 2.20264C22.6302 3.85337 16.6496 9.32466 14.5566 11.2104C14.3929 11.3579 14.1289 11.5338 13.8008 11.5338C13.4728 11.5338 13.2088 11.3579 13.0445 11.2099C10.9518 9.32446 4.97075 3.85288 3.16738 2.20273C3.23955 2.18691 3.30732 2.15884 3.38413 2.15884ZM2.34243 17.7838V3.20054C2.34243 3.09849 2.37319 3.00591 2.40073 2.91265C3.7812 4.17612 7.95425 7.9937 10.6601 10.4543C7.96304 12.771 3.78892 16.7285 2.39746 18.0553C2.3729 17.9667 2.34243 17.8801 2.34243 17.7838ZM24.2174 18.8255H3.38413C3.30093 18.8255 3.22695 18.7965 3.14927 18.778C4.58711 17.4073 8.78779 13.4271 11.4375 11.1604C11.7829 11.4737 12.0984 11.7594 12.3471 11.9835C12.7764 12.3711 13.2789 12.5755 13.8008 12.5755C14.3227 12.5755 14.8251 12.371 15.2539 11.984C15.5027 11.7598 15.8185 11.4738 16.1641 11.1604C18.8139 13.4269 23.014 17.4067 24.4523 18.778C24.3746 18.7965 24.3007 18.8255 24.2174 18.8255ZM25.2591 17.7838C25.2591 17.8801 25.2287 17.9667 25.2041 18.0553C23.8122 16.7278 19.6385 12.7708 16.9415 10.4543C19.6475 7.99375 23.8199 4.17651 25.2008 2.91255C25.2284 3.00581 25.2591 3.09844 25.2591 3.20049V17.7838Z"
      fill="#CDEE24"
      stroke="#CDEE24"
    />
  </Icon>
);
