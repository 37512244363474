import { Flex, Center, Heading, Box } from "@chakra-ui/react";
import FaqAccordionList from "../../components/public/faq/FaqAccordionList";

const FaqPage = () => {
  return (
    <Flex
      minH="100vh"
      flexDir="column"
      alignItems="center"
      justifyContent="start"
      w="full"
    >
      <Heading
        as="h1"
        fontSize={{ base: "2rem", lg: "3.125rem" }}
        lineHeight={{ base: "2.5rem", lg: "3.9375rem" }}
        bgColor="backgroundGrey"
        w="full"
        textAlign="center"
        pt="calc(4.625rem + 4rem)"
        pb="4rem"
        px={{ base: "1rem", sm: "3rem" }}
      >
        Frequently Asked Questions
      </Heading>
      <Box
        width="full"
        bgColor="white"
        py={{ base: "2rem", lg: "3.75rem" }}
        px="1rem"
      >
        <Center
          flexDirection="column"
          gap={{ base: "2rem", lg: "3.75rem" }}
          mb={{ base: "0rem", sm: "6.875rem" }}
        >
          <FaqAccordionList listType="company" />
          <FaqAccordionList listType="candidate" />
        </Center>
      </Box>
    </Flex>
  );
};

export default FaqPage;
