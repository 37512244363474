import { inputAnatomy as parts } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from "@chakra-ui/styled-system";

// This function creates a set of function that helps us create multipart component styles.
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyleControl = defineStyle({
  w: "20px",
  h: "20px",
  transitionProperty: "box-shadow",
  transitionDuration: "normal",
  border: "2px solid",
  borderRadius: "2px",
  color: "white",
  borderColor: "fieldStroke",

  _checked: {
    bg: "electricLime",
    borderColor: "electricLime",
    color: "white",
    // _hover: {
    //   bg: "green",
    //   borderColor: "lightgreen",
    // },

    // _disabled: {
    //   borderColor: "yellow",
    //   bg: "grey",
    //   color: "purple",
    // },
  },
  _indeterminate: {
    bg: "blue",
    // borderColor: "lightyellow",
    // color: "orange",
  },

  _disabled: {
    bg: "black",
    borderColor: "grey",
  },

  _focusVisible: {
    // borderColor: "lightyellow",
    // border: "2px solid #bcbcbc",
    border: "none",
    boxShadow: "0 0 0 1px #d7d7d7",
    _checked: {
      boxShadow: "0 0 0 3px transparent",
    },
  },

  _invalid: {
    borderColor: "error",
  },
});

const baseStyleContainer = defineStyle({
  _disabled: { cursor: "not-allowed" },
});

const baseStyleLabel = defineStyle({
  userSelect: "none",
  _disabled: { opacity: 0.4 },
});

const baseStyleIcon = defineStyle({
  transitionProperty: "transform",
  transitionDuration: "normal",
  boxSize: "10px",
  animation: "none",
});

const baseStyle = definePartsStyle({
  icon: baseStyleIcon,
  container: baseStyleContainer,
  control: baseStyleControl,
  label: baseStyleLabel,
});

const sizes = {
  md: definePartsStyle({
    control: "20px",
    label: { fontSize: "md" },
    icon: { fontSize: "5px" },
  }),
};

export const Checkbox = defineMultiStyleConfig({
  baseStyle,
  sizes,
  defaultProps: {
    size: "md",
    colorScheme: "electricLime",
  },
});
