const PictureOptimized = (props) => {
  const { srcX1, srcX2, srcX3, fallbackSrc, alt } = props;
  const notLoadingLazy = props?.notLoadingLazy
  console.log(notLoadingLazy)
  return (
    <picture>
      <source
        srcSet={`${srcX1} 1x, ${srcX2} 2x, ${srcX3} 3x`}
        type="image/webp"
        media="screen"
      />
      <img
        loading={notLoadingLazy ? 'eager' : 'lazy'}
        src={fallbackSrc}
        alt={alt || ""}
        style={{ ...props }}
      />
    </picture>
  );
};

export default PictureOptimized;
