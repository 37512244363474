import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { useContext, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  hasDigit,
  hasLowerAndUpperCase,
  hasSymbol,
  lengthInBetween,
  REGEX_CONTAINS_BOTH_LOWER_AND_UPPERCASE,
  REGEX_HAS_AT_LEAST_ONE_NUMBER,
  REGEX_HAS_SYMBOL,
} from "../../utils/validations";
import {
  CustomCheckCircleIcon,
  CustomExclamationMarkIcon,
  CustomEyeClosedIcon,
  CustomEyeOpenIcon,
} from "../../assets/icons";
import { resetPasswordFn } from "../../services/apiServices";
import { AuthContext } from "../../context/AuthContext";
import { getErrorResponsePayload } from "../../utils/helpers";
import CustomToast from "../../components/private/shared/UI/CustomToast";

const validationSchema = Yup.object().shape({
  plainPassword: Yup.string()
    .required("Please enter your password")
    .min(6, "Please enter at least 6 characters")
    .max(30, "Please enter 30 characters or less")
    .matches(REGEX_CONTAINS_BOTH_LOWER_AND_UPPERCASE)
    .matches(REGEX_HAS_AT_LEAST_ONE_NUMBER)
    .matches(REGEX_HAS_SYMBOL),
});

const formOptions = {
  resolver: yupResolver(validationSchema),
  mode: "onSubmit",
};

const ResetPasswordPage = () => {
  const [show, setShow] = useState(false);
  const toast = useToast();
  const { resetToken: token } = useParams();
  const navigate = useNavigate();
  const { setAuthState } = useContext(AuthContext);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty, isValid },
    watch,
    getFieldState,
    getValues,
  } = useForm(formOptions);

  const { mutate, isLoading } = useMutation({
    mutationFn: (data) => resetPasswordFn(data),
    onSuccess: ({ data }) => {
      const { user, token, refresh_token: refreshToken } = data;
      setAuthState({ user, token, refreshToken });
      navigate("/dashboard", { replace: true });
    },
    onError: (error) => {
      const { message, additionalErrors } = getErrorResponsePayload(error);
      // check for the link expiration
      if (additionalErrors[0] === "The link expired.") {
        navigate("/forgot-password");
        toast({
          position: "top",
          render: (props) => (
            <CustomToast
              status="error"
              title={`The link expired.`}
              description={"Please resend the link to your email."}
              onClose={props.onClose}
              id={props.id}
            />
          ),
        });

        return;
      }
      // error toast
      toast({
        position: "top",
        render: (props) => (
          <CustomToast
            status="error"
            title={message ? message : `Something went wrong`}
            description={"Please try again later"}
            onClose={props.onClose}
            id={props.id}
          />
        ),
      });
      // navigate if necessary
    },
  });

  const watchPassword = watch("plainPassword");
  const fieldStatePassword = getFieldState("plainPassword");
  const validPassword =
    watchPassword?.match(RegExp(REGEX_CONTAINS_BOTH_LOWER_AND_UPPERCASE)) &&
    watchPassword?.match(RegExp(REGEX_HAS_AT_LEAST_ONE_NUMBER)) &&
    watchPassword?.match(RegExp(REGEX_HAS_SYMBOL));
  const valuePassword = getValues("plainPassword");
  const showPasswordClickHandler = () => setShow(!show);

  const onSubmit = (data) => {
    mutate({
      ...data,
      token,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing={10} alignItems="flex-start">
        <Heading
          alignSelf={{ base: "center", sm: "flex-start" }}
          textAlign={{ base: "center", sm: "left" }}
          fontSize={{ base: "2rem", sm: "3.125rem" }} //50px
          lineHeight={{ base: "2.5rem", sm: "3.9rem" }} //63px
          //mb="2rem"
        >
          Choose a <br />
          new password
        </Heading>
        <FormControl isRequired isInvalid={errors.password}>
          <FormLabel
            textStyle="bodySemiBold"
            fontWeight="600"
            htmlFor="password"
            requiredIndicator=""
          >
            New password
          </FormLabel>
          <InputGroup>
            <Input
              autoComplete="off"
              id="plainPassword"
              type={show ? "text" : "password"}
              placeholder="Enter password"
              {...register("plainPassword")}
            />
            <InputRightElement>
              {show ? (
                <CustomEyeClosedIcon
                  onClick={showPasswordClickHandler}
                  boxSize="1.125rem"
                  cursor="pointer"
                  color="placeholderColor"
                />
              ) : (
                <CustomEyeOpenIcon
                  onClick={showPasswordClickHandler}
                  boxSize="1.125rem"
                  cursor="pointer"
                  color="placeholderColor"
                />
              )}
            </InputRightElement>
          </InputGroup>
          {fieldStatePassword.error ||
          (valuePassword !== "" && validPassword === null) ? (
            <FormHelperText fontSize="12px">
              <VStack spacing={0} alignItems="left">
                <Text color={fieldStatePassword.error ? "error" : "black"}>
                  Please enter your password:
                </Text>
                <Box
                  color={
                    lengthInBetween(watchPassword, 6, 30)
                      ? "limeText"
                      : fieldStatePassword.error
                      ? "error"
                      : "black"
                  }
                >
                  {lengthInBetween(watchPassword, 6, 30) ? (
                    <CustomCheckCircleIcon boxSize={2} mr={1} />
                  ) : (
                    <CustomExclamationMarkIcon boxSize={2} mr={1} />
                  )}
                  contains at least 6 and maximum 30 characters
                </Box>
                <Box
                  color={
                    hasLowerAndUpperCase(watchPassword)
                      ? "limeText"
                      : fieldStatePassword.error
                      ? "error"
                      : "black"
                  }
                >
                  {hasLowerAndUpperCase(watchPassword, 6, 30) ? (
                    <CustomCheckCircleIcon boxSize={2} mr={1} />
                  ) : (
                    <CustomExclamationMarkIcon boxSize={2} mr={1} />
                  )}
                  contains both lower (a-z) and upper case letters (A-Z)
                </Box>
                <Box
                  color={
                    hasDigit(watchPassword) && hasSymbol(watchPassword)
                      ? "limeText"
                      : fieldStatePassword.error
                      ? "error"
                      : "black"
                  }
                >
                  {hasDigit(watchPassword) && hasSymbol(watchPassword) ? (
                    <CustomCheckCircleIcon boxSize={2} mr={1} />
                  ) : (
                    <CustomExclamationMarkIcon boxSize={2} mr={1} />
                  )}
                  contains at least one number (0-9) and a symbol
                </Box>
              </VStack>
            </FormHelperText>
          ) : (
            ""
          )}
        </FormControl>
        <Button
          type="submit"
          w="full"
          isLoading={isSubmitting || isLoading}
          disabled={!isDirty || !isValid || isSubmitting || isLoading}
          alt="Reset password"
        >
          Reset password
        </Button>
      </VStack>
    </form>
  );
};

export default ResetPasswordPage;
