import { Icon } from "@chakra-ui/react";

export const CustomSendMessageIcon = (props) => (
  <Icon viewBox="0 0 44 44" fill="none" {...props}>
    <circle cx="22" cy="22" r="22" fill={props.color || "#CDEE24"} />
    <path
      d="M33.5031 21.1144C33.3036 20.7024 32.971 20.3697 32.559 20.1702L17.2103 12.7431C16.1991 12.2532 14.9824 12.6758 14.4926 13.6869C14.243 14.2021 14.222 14.7986 14.4348 15.33L17.1038 22.0011L14.4348 28.6729C14.0191 29.7195 14.5306 30.9049 15.5771 31.3206C15.8138 31.4146 16.066 31.4635 16.3206 31.4646C16.6287 31.4646 16.9328 31.3951 17.2103 31.2612L32.559 23.832C33.5702 23.3422 33.9929 22.1255 33.5031 21.1144ZM16.6196 30.0386C16.2825 30.2018 15.877 30.0608 15.7138 29.7237C15.6307 29.552 15.6237 29.3532 15.6947 29.176L18.2932 22.6792H31.8287L16.6196 30.0386ZM18.2932 21.323L15.6947 14.8261C15.5866 14.5672 15.6537 14.2681 15.8622 14.0803C16.064 13.8836 16.3687 13.8375 16.6196 13.9657L31.8287 21.323H18.2932Z"
      fill="black"
    />
  </Icon>
);
