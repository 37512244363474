import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import FormSuccessfulySent from "../../components/public/shared/FormSuccessfulySent";
import { forgotPasswordFn } from "../../services/apiServices";
import { getErrorResponsePayload } from "../../utils/helpers";
import CustomToast from "../../components/private/shared/UI/CustomToast";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required("Please enter email address")
    .email("Please enter a valid email address"),
});

const formOptions = {
  resolver: yupResolver(validationSchema),
  mode: "onChange",
};

const ForgotPasswordPage = () => {
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const toast = useToast();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty, isValid },
    reset,
  } = useForm(formOptions);

  const { mutate, isLoading } = useMutation({
    mutationFn: (data) => forgotPasswordFn(data),
    onSuccess: (data) => {
      setSubmitSuccess(true);
      reset();
    },
    onError: (error) => {
      const { message } = getErrorResponsePayload(error);
      toast({
        position: "top",
        render: (props) => (
          <CustomToast
            status="error"
            title={message ? message : `Something went wrong`}
            description={"Please try again later"}
            onClose={props.onClose}
            id={props.id}
          />
        ),
      });
    },
  });

  const onSubmit = (data) => {
    mutate({
      ...data,
    });
  };

  if (submitSuccess) return <FormSuccessfulySent form="reset_password" />;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing={10} alignItems="flex-start">
        <Heading
          alignSelf={{ base: "center", sm: "flex-start" }}
          textAlign={{ base: "center", sm: "left" }}
          fontSize={{ base: "2rem", sm: "3.125rem" }} //50px
          lineHeight={{ base: "2.5rem", sm: "3.9rem" }} //63px
        >
          Forgot password?
        </Heading>
        <Text textAlign={{ base: "center", sm: "left" }} pr="2rem">
          Just enter your email address and we'll send you instructions to reset
          your password.
        </Text>
        <FormControl isRequired isInvalid={errors.email}>
          <FormLabel
            textStyle="bodySemiBold"
            fontWeight="600"
            htmlFor="email"
            requiredIndicator=""
          >
            Email address
          </FormLabel>
          <Input
            id="email"
            type="email"
            placeholder="Enter email address"
            {...register("email")}
          />
          <FormErrorMessage>
            {errors.email?.message || errors.email}
          </FormErrorMessage>
        </FormControl>
        <Button
          type="submit"
          w="full"
          isLoading={isSubmitting || isLoading}
          disabled={!isDirty || !isValid || isSubmitting || isLoading}
          alt="Send reset instructions"
        >
          Send reset instructions
        </Button>
      </VStack>
    </form>
  );
};

export default ForgotPasswordPage;
