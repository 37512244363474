import MessagesSubscribe from "./MessagesSubscribe";
import { AuthContext } from "../../../../../context/AuthContext";
import { useContext } from "react";
import { Outlet } from "react-router-dom";

const PusherCheckerWrapper = () => {
  const { authState } = useContext(AuthContext);

  return authState?.user ? <MessagesSubscribe /> : <Outlet/>;
};

export default PusherCheckerWrapper;
