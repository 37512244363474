import {
  Box,
  Button,
  Circle,
  Flex,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

const LandingTextContent = ({
  type,
  description,
  descriptionW,
  ctaText,
  ctaW,
  ctaTwo,
  ctaTo,
}) => {
  return (
    <Flex
      flexDir="column"
      gap={{ base: "1rem", lg: "2.188rem" }}
      textAlign="left"
      justifyContent="center"
      alignItems="flex-start"
      w="full"
    >
      {type === "home" ? (
        <Text
          as="h1"
          textStyle="headingRegular"
          fontSize={{ base: "2.25rem", lg: "5.625rem" }}
          lineHeight={{ base: "2.835rem", lg: "7.063rem" }}
          textTransform="uppercase"
          color="black"
        >
          Simpler
          <Flex
            columnGap="0.5rem"
            justifyContent="flex-start"
            flexDir={{ base: "column", md: "row" }}
          >
            <Text
              as="span"
              textStyle="headingRegular"
              fontSize={{ base: "2.25rem", lg: "5.625rem" }}
              lineHeight={{ base: "2.835rem", lg: "7.063rem" }}
              textTransform="uppercase"
              color="black"
            >
              way to
            </Text>
            <Text
              as="span"
              textStyle="headingExtraBold"
              fontSize={{ base: "2.25rem", lg: "5.625rem" }}
              lineHeight={{ base: "2.835rem", lg: "7.063rem" }}
              textTransform="uppercase"
              color="black"
              position="relative"
              w="fit-content"
            >
              hire
              <Box position="absolute" w="full" h="full" top="0" left="0">
                <Circle
                  boxSize={{ base: "0.75rem", md: "1.125rem" }}
                  bgColor="electricLime"
                  position="absolute"
                  bottom="16%"
                  right={{ base: "-15px", md: "-25px" }}
                />
              </Box>
            </Text>
          </Flex>
        </Text>
      ) : type === "candidates" ? (
        <Text
          as="h1"
          textStyle="headingRegular"
          fontSize={{ base: "2.25rem", lg: "5.625rem" }}
          lineHeight={{ base: "2.835rem", lg: "7.063rem" }}
          textTransform="uppercase"
          color="black"
        >
          <Text
            as="span"
            textStyle="headingExtraBold"
            fontSize={{ base: "2.25rem", lg: "5.625rem" }}
            lineHeight={{ base: "2.835rem", lg: "7.063rem" }}
            textTransform="uppercase"
            position="relative"
            color="black"
          >
            Get hired
          </Text>
          <Flex gap="1.125rem" justifyContent="flex-start">
            <Text
              as="span"
              textStyle="headingRegular"
              fontSize={{ base: "2.25rem", lg: "5.625rem" }}
              lineHeight={{ base: "2.835rem", lg: "7.063rem" }}
              textTransform="uppercase"
              color="black"
              position="relative"
            >
              easily
              <Box position="absolute" w="full" h="full" top="0" left="0">
                <Circle
                  boxSize={{ base: "0.75rem", md: "1.125rem" }}
                  bgColor="electricLime"
                  position="absolute"
                  bottom="16%"
                  right={{ base: "-15px", md: "-25px" }}
                />
              </Box>
            </Text>
          </Flex>
        </Text>
      ) : type === "clients" ? (
        <Text
          as="h1"
          textStyle="headingRegular"
          fontSize={{ base: "2.25rem", lg: "5.625rem" }}
          lineHeight={{ base: "2.835rem", lg: "7.063rem" }}
          textTransform="uppercase"
          color="black"
          position="relative"
        >
          <Text
            as="span"
            textStyle="headingExtraBold"
            fontSize={{ base: "2.25rem", lg: "5.625rem" }}
            lineHeight={{ base: "2.835rem", lg: "7.063rem" }}
            textTransform="uppercase"
            position="relative"
            color="black"
          >
            Recruit
          </Text>
          <Flex gap="1.125rem" justifyContent="flex-start">
            <Text
              as="span"
              textStyle="headingRegular"
              fontSize={{ base: "2.25rem", lg: "5.625rem" }}
              lineHeight={{ base: "2.835rem", lg: "7.063rem" }}
              textTransform="uppercase"
              color="black"
              position="relative"
            >
              top talent
              <Box position="absolute" w="full" h="full" top="0" left="0">
                <Circle
                  boxSize={{ base: "0.75rem", md: "1.125rem" }}
                  bgColor="electricLime"
                  position="absolute"
                  bottom="16%"
                  right={{ base: "-15px", md: "-25px" }}
                />
              </Box>
            </Text>
          </Flex>
        </Text>
      ) : null}

      {description && (
        <Text
          fontSize={{ base: "1.188rem", lg: "1.5rem" }}
          lineHeight={{ base: "1.663rem", lg: "2.1rem" }}
          maxW={descriptionW}
          px="0.5rem"
        >
          {description}
        </Text>
      )}
      <Stack
        direction={{ base: "column", sm: "row" }}
        gap={{ base: "0.3rem", md: "0.938rem" }}
        alignItems="center"
        justifyContent="center"
      >
        <Button as={RouterLink} to={ctaTo} w={ctaW} h="3.063rem">
          {ctaText}
        </Button>
        {ctaTwo && (
          <Button
            as={RouterLink}
            w="10.125rem"
            h="3.063rem"
            variant="tablePagination"
            backgroundColor="white"
            _hover={{ bg: "black", color: "white", border: "none" }}
            to="/join-as-candidate"
          >
            Get job offers
          </Button>
        )}
      </Stack>
    </Flex>
  );
};

export default LandingTextContent;
