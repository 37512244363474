import { Box, Image } from "@chakra-ui/react";
import blackLinesSrc from "../../../../assets/vectors/blackLines.svg";
import ringLime from "../../../../assets/vectors/ringLime.svg";
import ringBlack from "../../../../assets/vectors/ringBlack.svg";

const PseudoElementsTypeTwo = () => {
  return (
    <Box
      w={{ base: "15rem", lg: "21rem" }}
      position="absolute"
      bottom={{ base: "6.5rem", lg: "9rem" }}
      right="0"
    >
      <Image
        src={blackLinesSrc}
        loading="lazy"
        position="absolute"
        width={{ base: "15rem", lg: "21rem" }}
        top={{ base: "0", sm: "0" }}
        left={{ base: "0", sm: "0" }}
        objectFit="cover"
      />
      <Image
        src={ringLime}
        loading="lazy"
        width="2.375rem"
        position="absolute"
        top={{ base: "5rem", lg: "2rem" }}
        right={{ base: "2rem", lg: "4rem" }}
        objectFit="cover"
      />
      <Image
        src={ringBlack}
        loading="lazy"
        width="1.6875rem"
        position="absolute"
        top="4rem"
        left="6rem"
        objectFit="cover"
      />
    </Box>
  );
};

export default PseudoElementsTypeTwo;
