import { modalAnatomy as parts } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from "@chakra-ui/styled-system";

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyleCloseButton = defineStyle({
  position: "absolute",
  top: "2",
  insetEnd: "3",
  _hover: {
    background: "transparent",
    color: "mediumGrey",
  },
  _focus: {
    ring: 0,
  },
});

const baseStyle = definePartsStyle({
  closeButton: baseStyleCloseButton,
});

export const Modal = defineMultiStyleConfig({
  baseStyle,
});
