import { Box, Image, useMediaQuery } from "@chakra-ui/react";
import blackLinesFullSrc from "../../../../assets/vectors/blackLinesFull.svg";
import elipseLimeFullSrc from "../../../../assets/vectors/elipseLimeFull.svg";

const PageHeaderRightPseudoElementsTypeOne = ({ invertPseudoElements }) => {
  const [isLargerThanSM] = useMediaQuery("(min-width: 30em)");

  return (
    <Box w="full" position="absolute" top="1rem" right="0">
      <Image
        src={blackLinesFullSrc}
        loading="lazy"
        w="24rem"
        position="absolute"
        top="-1rem"
        objectFit="cover"
        right={invertPseudoElements ? null : "-7rem"}
        left={invertPseudoElements ? "-7rem" : null}
        transform={invertPseudoElements ? "rotate(-70deg)" : null}
      />
      {isLargerThanSM && (
        <Image
          src={elipseLimeFullSrc}
          loading="lazy"
          w="3rem"
          position="absolute"
          right={invertPseudoElements ? null : "2rem"}
          left={invertPseudoElements ? "2rem" : null}
          top="9rem"
          objectFit="cover"
        />
      )}
    </Box>
  );
};

export default PageHeaderRightPseudoElementsTypeOne;
