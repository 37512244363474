import { Box } from "@chakra-ui/react";
import registerFallbackSrc from "../../../assets/images/public/registerWomanX1Fallback.png";
import loginX1Src from "../../../assets/images/public/loginWomanX1.webp";
import loginX2Src from "../../../assets/images/public/loginWomanX2.webp";
import loginFallbackSrc from "../../../assets/images/public/loginWomanX1Fallback.png";
import contactX1Src from "../../../assets/images/public/contact_x2.webp";
import contactFallbackSrc from "../../../assets/images/public/contact_x2.png";
import joinAsCandidateX1Src from "../../../assets/images/public/join-as-candidate_x1.webp";
import joinAsCandidateX2Src from "../../../assets/images/public/join-as-candidate_x2.webp";
import joinAsCandidateFallbackSrc from "../../../assets/images/public/join-as-candidate_x2.png";

import PictureOptimized from "../../shared/PictureOptimized";
import LoginImagePseudoElements from "./LoginImagePseudoElements";

const LoginImage = ({ page }) => {
  // page can have 4 options - joinAsCompany, joinAsCandidate, contact, login
  const imageTypeProps = {
    login: {
      srcX1: loginX1Src,
      srcX2: loginX2Src,
      srcX3: loginX2Src,
      fallbackSrc: loginFallbackSrc,
      alt: "login",
    },
    joinAsCompany: {
      srcX1: loginX1Src,
      srcX2: loginX1Src, // nije greska
      srcX3: loginX2Src,
      fallbackSrc: registerFallbackSrc,
      alt: "register",
    },
    joinAsCandidate: {
      srcX1: joinAsCandidateX1Src,
      srcX2: joinAsCandidateX2Src,
      fallbackSrc: joinAsCandidateFallbackSrc,
      alt: "register",
    },
    contact: {
      srcX1: contactX1Src,
      srcX2: contactX1Src,
      fallbackSrc: contactFallbackSrc,
      alt: "contact",
    },
  };

  const actualImage = imageTypeProps[page];

  if (page === "joinAsCandidate") return (
    <Box
      position="relative"
      minWidth="52.625rem" // 842px
      w="full"
    >
      <PictureOptimized
        srcX1={actualImage.srcX1}
        srcX2={actualImage.srcX2}
        fallbackSrc={actualImage.fallbackSrc}
        alt={actualImage.alt}
        position="absolute"
        bottom="-22rem"
        left="-6rem"
        width="auto"
        height="39.1875rem" // 627px
      />
    </Box>
  );

  return (
    <Box position="sticky" top="0">
      <LoginImagePseudoElements page={page} />

      <PictureOptimized
        //srcX1={register ? loginX1Src : loginX1Src}
        //srcX2={register ? loginX1Src : loginX2Src}
        //fallbackSrc={register ? registerFallbackSrc : loginFallbackSrc}
        //alt={register ? "register" : "login"}
        srcX1={actualImage.srcX1}
        srcX2={actualImage.srcX2}
        fallbackSrc={actualImage.fallbackSrc}
        alt={actualImage.alt}
        //width="100%"
        height="100vh"
      />
    </Box>
  );
};

export default LoginImage;
