import { Box, Image } from "@chakra-ui/react";
import triangleDot from "../../../../assets/vectors/triangleDot.svg";
import ringLime from "../../../../assets/vectors/ringLime.svg";

const PseudoElementsTypeFour = () => {
  return (
    <Box
      w={{ base: "15rem", lg: "16rem" }}
      position="absolute"
      bottom={{ base: "5.5rem", lg: "4rem", xl: "7.5rem" }}
      //right={{ base: "6.5rem", lg: "-10rem", xl: "" }}
      left={{
        base: "80%",
        lg: "calc( 77rem + (100% - 77rem) / 2)",
        "2xl": "calc( 95rem + (100% - 95rem) / 2)",
      }}
      zIndex={1}
    >
      <Image
        src={triangleDot}
        loading="lazy"
        position="absolute"
        width={{ base: "6rem", lg: "11.1825rem", "2xl": "13rem" }}
        top={{ base: "4rem", lg: "0" }}
        left={{ base: "0", lg: "0" }}
        objectFit="cover"
        transform="rotate(-11deg)"
      />
      <Image
        src={ringLime}
        loading="lazy"
        width={{ base: "1.5rem", lg: "3,8125rem", "2xl": "5.5rem" }}
        position="absolute"
        top={{ base: "3rem", lg: "-1rem", "2xl": "-5rem" }}
        left={{ base: "4rem", lg: "5rem", "2xl": "6rem" }}
        objectFit="cover"
      />
    </Box>
  );
};

export default PseudoElementsTypeFour;
