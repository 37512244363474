import { React } from "react";
import colors from "../../theme/foundations/colors";
import AsyncSelect from "react-select/async";
import { components } from "react-select";
import { Button, Box } from "@chakra-ui/react";

const AutocompleteCompanyNameInput = (props) => {
  const reactSelectComponentStyle = {
    control: (provided, state) => ({
      ...provided,
      padding: "0 0.5rem",
      height: "2.5rem", // 40px
      borderRadius: "1.875rem", // 30px
      borderColor: state.isFocused
        ? colors.electricLime
        : props.errorBorder
        ? colors.error
        : colors.semiDarkGrey,
      backgroundColor: "white",
      boxShadow: "none",
      ":hover": {
        borderColor: colors.electricLime,
        transition: "all .2s",
        cursor: "text",
      },
    }),

    placeholder: (provided, state) => ({
      ...provided,
      color: "#d3d3d3",
      fontSize: "1rem",
    }),

    indicatorSeparator: (provided, state) => ({
      display: "none",
    }),

    dropdownIndicator: (provided, state) => ({
      display: "none",
    }),

    menu: (provided, state) => ({
      ...provided,
      borderRadius: "6px",
      border: colors.fieldStroke,
      boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
      zIndex: 3,
      //   -ms-overflow-style: none;
      ".menu-btn": {
        backgroundColor: "transparent",
        padding: "1rem",
        color: colors.limeText,
        display: "block",
        ":hover": {
          color: colors.darkGrey,
          backgroundColor: "transparent",
        },
      },
    }),

    option: (provided, state) => ({
      ...provided,
      color: "inherit",
      padding: "0.7rem 1rem",
      wordBreak: "break-word",
      backgroundColor: state.isSelected ? colors.limeText : "inherit",
      transition: "all 0.2s",
      ":hover": {
        backgroundColor: state.isSelected
          ? colors.limeText
          : colors.backgroundGrey,
      },
    }),
    menuList: (provided, state) => ({
      ...provided,
      padding: "0px",
    }),

    clearIndicator: (provided, state) => ({
      ...provided,
      ":hover": {
        cursor: "pointer",
      },
    }),
  };
  return <AsyncSelect {...props} styles={reactSelectComponentStyle} />;
};

export const CustomMenu = (props) => {
  return (
    <>
      <components.Menu {...props}>
        <Box>{props.children}</Box>
        <Button
          className="menu-btn"
          onClick={props.selectProps.handleMenuBtnClick}
          onTouchEnd={props.selectProps.handleMenuBtnClick}
          width="auto"
        >
          + Add a company
        </Button>
      </components.Menu>
    </>
  );
};

export default AutocompleteCompanyNameInput;
