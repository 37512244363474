import { Icon } from "@chakra-ui/react";

export const CustomPdfIcon = (props) => (
  <Icon viewBox="0 0 31 33" fill="none" {...props}>
    <path
      d="M30.1538 9.10092L22.0223 0.651444C21.9065 0.531253 21.7468 0.463303 21.58 0.463303H9.46048C8.10415 0.463303 7.00068 1.56634 7.00068 2.92211V11.5118H2.08911C1.07435 11.5118 0.248779 12.3372 0.248779 13.3517V22.562C0.248779 23.5765 1.07435 24.4018 2.08911 24.4018H7.00068V29.9268C7.00068 31.2802 8.10415 32.3813 9.46048 32.3813H27.8656C29.2219 32.3813 30.3254 31.2808 30.3254 29.9281V9.52657C30.3254 9.36785 30.2638 9.21531 30.1538 9.10092ZM21.7321 2.12036L28.4073 9.05659H21.7321V2.12036ZM2.08911 23.1742C1.75123 23.1742 1.47644 22.8996 1.47644 22.562V13.3517C1.47644 13.0141 1.75129 12.7394 2.08911 12.7394H18.6641C19.002 12.7394 19.2768 13.0141 19.2768 13.3517V22.562C19.2768 22.8996 19.002 23.1742 18.6641 23.1742H2.08911ZM29.0977 29.9281C29.0977 30.6039 28.5449 31.1537 27.8655 31.1537H9.46048C8.78104 31.1537 8.22827 30.6033 8.22827 29.9268V24.4018H18.6641C19.6789 24.4018 20.5045 23.5765 20.5045 22.562V13.3517C20.5045 12.3372 19.6789 11.5118 18.6641 11.5118H8.22827V2.92211C8.22827 2.24317 8.78104 1.6909 9.46048 1.6909H20.5044V9.67039C20.5044 10.0094 20.7793 10.2842 21.1182 10.2842H29.0977V29.9281Z"
      fill="#333333"
    />
    <rect
      x="0.248779"
      y="11.4438"
      width="20.3516"
      height="13.3125"
      rx="1"
      fill="#CDEE24"
    />
    <path
      d="M4.00659 20.6013V15.5104H6.01511C6.40124 15.5104 6.7302 15.5842 7.00198 15.7317C7.27376 15.8775 7.48091 16.0805 7.62342 16.3407C7.7676 16.5992 7.83969 16.8975 7.83969 17.2356C7.83969 17.5736 7.76677 17.8719 7.62094 18.1305C7.47511 18.389 7.26381 18.5903 6.98706 18.7345C6.71197 18.8787 6.37887 18.9508 5.98777 18.9508H4.70759V18.0882H5.81377C6.02092 18.0882 6.19161 18.0526 6.32584 17.9813C6.46173 17.9084 6.56282 17.8081 6.62911 17.6805C6.69705 17.5513 6.73102 17.403 6.73102 17.2356C6.73102 17.0665 6.69705 16.9191 6.62911 16.7931C6.56282 16.6655 6.46173 16.5669 6.32584 16.4973C6.18995 16.426 6.0176 16.3904 5.80879 16.3904H5.08294V20.6013H4.00659Z"
      fill="#333333"
    />
    <path
      d="M10.3435 20.6013H8.53882V15.5104H10.3584C10.8705 15.5104 11.3113 15.6124 11.6809 15.8162C12.0504 16.0184 12.3346 16.3092 12.5335 16.6887C12.734 17.0682 12.8343 17.5223 12.8343 18.0509C12.8343 18.5812 12.734 19.037 12.5335 19.4181C12.3346 19.7993 12.0488 20.0918 11.6759 20.2956C11.3047 20.4994 10.8606 20.6013 10.3435 20.6013ZM9.61517 19.6791H10.2988C10.6169 19.6791 10.8846 19.6228 11.1017 19.5101C11.3204 19.3957 11.4845 19.2192 11.5939 18.9806C11.7049 18.7403 11.7604 18.4304 11.7604 18.0509C11.7604 17.6747 11.7049 17.3673 11.5939 17.1287C11.4845 16.8901 11.3213 16.7144 11.1042 16.6017C10.8871 16.489 10.6194 16.4327 10.3012 16.4327H9.61517V19.6791Z"
      fill="#333333"
    />
    <path
      d="M13.6316 20.6013V15.5104H17.0023V16.3979H14.7079V17.6109H16.7786V18.4984H14.7079V20.6013H13.6316Z"
      fill="#333333"
    />
  </Icon>
);
