import { Icon } from "@chakra-ui/react";

export const CustomExperienceWorkIcon = (props) => (
  <Icon viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.2262 1.38437C13.2262 0.749247 13.741 0.234375 14.3762 0.234375C19.4572 0.234375 23.5762 4.35336 23.5762 9.43437C23.5762 10.0695 23.0613 10.5844 22.4262 10.5844H14.3762C13.741 10.5844 13.2262 10.0695 13.2262 9.43437V1.38437ZM15.5262 2.62978V8.28438H21.1808C20.696 5.39499 18.4156 3.11452 15.5262 2.62978ZM9.77617 4.9159C5.87503 5.47391 2.87617 8.82893 2.87617 12.8844C2.87617 17.3303 6.48028 20.9344 10.9262 20.9344C14.9816 20.9344 18.3366 17.9355 18.8946 14.0344H10.9262C10.291 14.0344 9.77617 13.5195 9.77617 12.8844V4.9159ZM0.576172 12.8844C0.576172 7.16823 5.21002 2.53437 10.9262 2.53437C11.5613 2.53437 12.0762 3.04925 12.0762 3.68437V11.7344H20.1262C20.7613 11.7344 21.2762 12.2492 21.2762 12.8844C21.2762 18.6005 16.6423 23.2344 10.9262 23.2344C5.21002 23.2344 0.576172 18.6005 0.576172 12.8844Z"
      fill={props.color ? props.color : "#CDEE24"}
    />
  </Icon>
);
