import { Box, Flex, useMediaQuery, Spacer } from "@chakra-ui/react";
import PageHeaderLeftPseudoElementsTypeTwo from "./UI/PageHeaderLeftPseudoElementsTypeTwo";
import PageHeaderRightPseudoElementsTypeTwo from "./UI/PageHeaderRightPseudoElementsTypeTwo";
import ClientCandidateHeaderNavigation from "./profile/ClientCandidateHeaderNavigation";
import ClientNotActivatedHeaderNavigation from "../../../components/private/client/activation/HeaderNavigation";
import FooterNavigation from "./navs/FooterNavigation";
import ScrollToTop from "./UI/ScrollToTop";
import PageHeaderLeftPseudoElementsTypeOne from "./UI/PageHeaderLeftPseudoElementsTypeOne";
import PageHeaderRightPseudoElementsTypeOne from "./UI/PageHeaderRightPseudoElementsTypeOne";

const LayoutClientCandidate = ({
  children,
  pseudoElementsType,
  userType,
  bgColor,
  removeOnMobile,
  singleMessageThread,
  footerFullWidth,
  hasSidebar,
  membershipStatus,
}) => {
  const [isSmallerThan768px] = useMediaQuery("(min-width: 768px)");
  const [isSmallerThan1200px] = useMediaQuery("(min-width: 1200px)");
  const [isLargerThanLG] = useMediaQuery("(min-width: 992px)");

  return (
    <>
      <ScrollToTop />
      <Box
        position="relative"
        overflow="hidden"
        bgColor="white"
        minHeight="100vh"
      >
        {pseudoElementsType === "one" ? (
          removeOnMobile ? (
            <>
              {isSmallerThan1200px && <PageHeaderLeftPseudoElementsTypeOne />}
              {isSmallerThan768px && <PageHeaderRightPseudoElementsTypeOne />}
            </>
          ) : (
            <>
              <PageHeaderLeftPseudoElementsTypeOne />
              <PageHeaderRightPseudoElementsTypeOne />
            </>
          )
        ) : pseudoElementsType === "two" ? (
          <>
            <PageHeaderLeftPseudoElementsTypeTwo />
            <PageHeaderRightPseudoElementsTypeTwo />
          </>
        ) : null}

        {singleMessageThread && !isLargerThanLG ? null : membershipStatus ? (
          <ClientCandidateHeaderNavigation type={userType} />
        ) : (
          <ClientNotActivatedHeaderNavigation />
        )}

        <Flex
          flexDir="column"
          justifyContent="space-between"
          alignItems="center"
          w="full"
          minHeight="100vh"
        >
          {children}
          <Spacer />
          {!singleMessageThread && (
            <FooterNavigation
              footerFullWidth={footerFullWidth}
              bgColor={bgColor}
              hasSidebar={hasSidebar}
            />
          )}
        </Flex>
      </Box>
    </>
  );
};

export default LayoutClientCandidate;
