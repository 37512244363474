import { modalAnatomy as parts } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from "@chakra-ui/styled-system";

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys);

// const baseStyleOverlay = defineStyle({
//   bg: "blackAlpha.600",
//   zIndex: "modal",
// });

// const baseStyleDialogContainer = defineStyle((props) => {
//   const { isCentered, scrollBehavior } = props;

//   return {
//     display: "flex",
//     zIndex: "modal",
//     justifyContent: "center",
//     alignItems: isCentered ? "center" : "flex-start",
//     overflow: scrollBehavior === "inside" ? "hidden" : "auto",
//     overscrollBehaviorY: "none",
//   };
// });

// const baseStyleDialog = defineStyle((props) => {
//   const { scrollBehavior } = props;

//   return {
//     borderRadius: "md",
//     bg: mode("white", "gray.700")(props),
//     color: "inherit",
//     my: "16",
//     zIndex: "modal",
//     maxH: scrollBehavior === "inside" ? "calc(100% - 7.5rem)" : undefined,
//     boxShadow: mode("lg", "dark-lg")(props),
//   };
// });

// const baseStyleHeader = defineStyle({
//   px: "6",
//   py: "4",
//   fontSize: "xl",
//   fontWeight: "semibold",
// });

const baseStyleCloseButton = defineStyle({
  // position: "absolute",
  // top: "2",
  // insetEnd: "3",
  _hover: {
    background: "transparent",
    color: "mediumGrey",
  },
  _focus: {
    ring: 0,
  },
});

// const baseStyleBody = defineStyle((props) => {
//   const { scrollBehavior } = props;
//   return {
//     px: "6",
//     py: "2",
//     flex: "1",
//     overflow: scrollBehavior === "inside" ? "auto" : undefined,
//   };
// });

// const baseStyleFooter = defineStyle({
//   px: "6",
//   py: "4",
// });

const baseStyle = definePartsStyle({
  // overlay: baseStyleOverlay,
  // dialogContainer: baseStyleDialogContainer,
  // dialog: baseStyleDialog,
  // header: baseStyleHeader,
  closeButton: baseStyleCloseButton,
  // body: baseStyleBody,
  // footer: baseStyleFooter,
});

function getSize(value) {
  if (value === "full") {
    return definePartsStyle({
      dialog: {
        maxW: "100vw",
        minH: "100vh",
        my: "0",
        borderRadius: "0",
      },
    });
  }
  return definePartsStyle({
    dialog: { maxW: value },
  });
}

const sizes = {
  //   xs: getSize("xs"),
  //   sm: getSize("sm"),
  //   md: getSize("md"),
  //   lg: getSize("lg"),
  //   xl: getSize("xl"),
  //   "2xl": getSize("2xl"),
  //   "3xl": getSize("3xl"),
  //   "4xl": getSize("4xl"),
  //   "5xl": getSize("5xl"),
  //   "6xl": getSize("6xl"),
  //   full: getSize("full"),
  sideNavSize: getSize("11.25rem"),
};

export const Drawer = defineMultiStyleConfig({
  baseStyle,
  sizes,
  // defaultProps: { size: "md" },
});
