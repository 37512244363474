import { Box, Flex, useMediaQuery } from "@chakra-ui/react";
import footerX2Src from "../../../assets/images/public/clients/footer_x2.webp";
import footerMobileX1Src from "../../../assets/images/public/clients/footer_mobile_x1.webp";
import footerMobileX2Src from "../../../assets/images/public/clients/footer_mobile_x2.webp";
import footerMobileX1FallbackSrc from "../../../assets/images/public/clients/footer_mobile_x1_min.png";
import PictureOptimized from "../../shared/PictureOptimized";
import FooterTextContent from "../shared/FooterTextContent";

const Footer = () => {
  const [isSmallerThanMD] = useMediaQuery("(max-width: 48em)");
  const [isLargerThanSM] = useMediaQuery("(min-width: 30em)");

  return (
    <Box
      w="full"
      h="full"
      alignSelf="flex-start"
      position="relative"
      bg="white"
    >
      {isLargerThanSM ? (
        <PictureOptimized
          srcX1={footerX2Src}
          srcX2={footerX2Src}
          srcX3={footerX2Src}
          fallbackSrc={footerX2Src}
          height={isSmallerThanMD && "80vh"}
          objectFit={isSmallerThanMD && "cover"}
          width="100%"
          notLoadingLazy
        />
      ) : (
        <PictureOptimized
          srcX1={footerMobileX1Src}
          srcX2={footerMobileX2Src}
          srcX3={footerMobileX2Src}
          fallbackSrc={footerMobileX1FallbackSrc}
          height="80vh"
          objectFit="cover"
          width="100%"
        />
      )}
      <Box position="absolute" w="full" h="full" top="0" left="0">
        <Flex
          w="full"
          h="full"
          alignItems="center"
          pt={{ base: "30%", sm: 0 }}
          justifyContent="center"
        >
          <FooterTextContent
            type="clients"
            ctaText="Join Now"
            ctaW="14.063rem"
            ctaTo="/membership"
            alignment="left"
          />
        </Flex>
      </Box>
    </Box>
  );
};

export default Footer;
