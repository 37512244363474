import { lazy, Suspense, useContext } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  useLocation,
  Navigate,
} from "react-router-dom";
import { AuthContext } from "./context/AuthContext";
import { CompaniesListContextProvider } from "./context/AdminCompaniesListContext";
import { ClientCandidateChatContextProvider } from "./context/ClientCandidateChatContext";
import GetLoggedUser from "./hooks/useGetLoggedUser";
import PusherCheckerWrapper from "./components/private/shared/messages/pusher/PusherCheckerWrapper";
import TitleMetaTag from "./components/shared/TitleMetaTag";
import { titleMetaData } from "./utils/titleMetaData";

// PUBLIC PAGES
import JoinAsCandidatePage from "./pages/public/JoinAsCandidatePage";
import HomePage from "./pages/public/HomePage";
import PublicClientsPage from "./pages/public/ClientsPage";
import PublicCandidatesPage from "./pages/public/CandidatesPage";
import GetStartedPage from "./pages/public/GetStartedPage";
import FaqPage from "./pages/shared/FaqPage";
import TermsPage from "./pages/shared/TermsPage";
import PrivacyPage from "./pages/shared/PrivacyPage";
import ContactUsPage from "./pages/shared/ContactUsPage";
import Loading from "./components/shared/Loading";
import LayoutHome from "./components/public/shared/layout/LayoutHome";
import LayoutMembership from "./components/public/shared/layout/LayoutMembership";
import VerifyingYourEmail from "./pages/public/VerifyingYourEmailPage";
import MembershipOriginCheck from "./components/public/membership/MembershipOriginCheck";
import LayoutLogin from "./components/public/login/LayoutLogin";
import JoinAsClientPage from "./pages/public/JoinAsClientPage";
import LoginPage from "./pages/public/LoginPage";
import ForgotPasswordPage from "./pages/public/ForgotPasswordPage";
import ResetPasswordPage from "./pages/public/ResetPasswordPage";
import LogoutPage from "./pages/public/LogoutPage";
import MembershipPage from "./pages/public/MembershipPage";
import BookADemoPage from "./pages/public/BookADemoPage";

// LAZY-LOADED COMPONENTS

// ADMIN PAGES
const DashboardPage = lazy(() =>
  import("./pages/private/shared/DashboardPage")
);
const ClientsPage = lazy(() => import("./pages/private/admin/ClientsPage"));
const CompaniesPage = lazy(() => import("./pages/private/admin/CompaniesPage"));
const CandidatesPage = lazy(() =>
  import("./pages/private/admin/CandidatesPage")
);
const LayoutAdmin = lazy(() =>
  import("./components/private/admin/shared/LayoutAdmin")
);
const CreateCandidatePage = lazy(() =>
  import("./pages/private/admin/CreateCandidatePage")
);
const CreateEditCompanyPage = lazy(() =>
  import("./pages/private/admin/CreateEditCompanyPage")
);
const SetupPasswordPage = lazy(() =>
  import("./pages/public/SetupPasswordPage")
);
const ProfilePage = lazy(() => import("./pages/private/shared/ProfilePage"));
const MessagesPage = lazy(() => import("./pages/private/shared/MessagesPage"));
const MessagesThreadPage = lazy(() =>
  import("./pages/private/shared/MessagesThreadPage")
);
const ProfileViewPage = lazy(() =>
  import("./pages/private/shared/ProfileViewPage")
);

// CLIENT PAGES
const FindCandidatesPage = lazy(() =>
  import("./pages/private/client/FindCandidatesPage")
);
const InvoiceNoticePage = lazy(() =>
  import("./pages/private/client/InvoiceNoticePage")
);

// CANDIDATE PAGES
const LayoutClientCandidate = lazy(() =>
  import("./components/private/shared/LayoutClientCandidate")
);

// STYLEGUIDE PAGE
const LayoutStyleguide = lazy(() =>
  import("./components/styleguide/LayoutStyleguide")
);

const StyleguidePage = lazy(() => import("./pages/StyleguidePage"));

const showStyleguide = process.env.REACT_APP_STYLEGUIDE_PAGES === "show";

const SuspenseFallback = () => <Loading />;

const AuthRoute = () => {
  const { isAuthenticated } = useContext(AuthContext);

  return isAuthenticated() ? (
    <Navigate to="/dashboard" replace />
  ) : (
    <TitleMetaTag
      title={titleMetaData.home.title}
      description={titleMetaData.home.description}
    >
      <Outlet />
    </TitleMetaTag>
  );
};

const SharedRoute = () => {
  const { isAuthenticated, isRoleAdmin, isRoleClient, isRoleCandidate } =
    useContext(AuthContext);
  const location = useLocation();

  return isAuthenticated() &&
    (isRoleAdmin() || isRoleClient() || isRoleCandidate()) ? (
    <GetLoggedUser>
      <TitleMetaTag
        title={titleMetaData.home.title}
        description={titleMetaData.home.description}
      >
        <Outlet />
      </TitleMetaTag>
    </GetLoggedUser>
  ) : (
    <Navigate to="/login" state={{ next: location }} replace />
  );
};

const AdminRoute = () => {
  const { isRoleAdmin, isAuthenticated } = useContext(AuthContext);
  const location = useLocation();
  return isAuthenticated() && isRoleAdmin() ? (
    <GetLoggedUser>
      <TitleMetaTag
        title={titleMetaData.home.title}
        description={titleMetaData.home.description}
      >
        <Outlet />
      </TitleMetaTag>
    </GetLoggedUser>
  ) : (
    <Navigate to="/login" state={{ next: location }} replace />
  );
};

const ClientRoute = () => {
  const { isRoleClient, isAuthenticated } = useContext(AuthContext);
  const location = useLocation();
  return isAuthenticated() && isRoleClient() ? (
    <GetLoggedUser>
      <TitleMetaTag
        title={titleMetaData.home.title}
        description={titleMetaData.home.description}
      >
        <Outlet />
      </TitleMetaTag>
    </GetLoggedUser>
  ) : (
    <Navigate to="/login" state={{ next: location }} replace />
  );
};

const CandidateRoute = () => {
  const { isRoleCandidate, isAuthenticated } = useContext(AuthContext);
  const location = useLocation();
  return isAuthenticated() && isRoleCandidate() ? (
    <GetLoggedUser>
      <TitleMetaTag
        title={titleMetaData.home.title}
        description={titleMetaData.home.description}
      >
        <Outlet />
      </TitleMetaTag>
    </GetLoggedUser>
  ) : (
    <Navigate to="/login" state={{ next: location }} replace />
  );
};

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<SuspenseFallback />}>
        <Routes>
          <Route element={<AuthRoute />}>
            <Route
              path="*"
              element={
                <LayoutHome>
                  <HomePage />
                </LayoutHome>
              }
            />
            <Route
              path="/clients"
              element={
                <LayoutHome>
                  <TitleMetaTag
                    title={titleMetaData.clients.title}
                    description={titleMetaData.clients.description}
                  >
                    <PublicClientsPage />
                  </TitleMetaTag>
                </LayoutHome>
              }
            />
            <Route
              path="/candidates"
              element={
                <LayoutHome>
                  <TitleMetaTag
                    title={titleMetaData.candidates.title}
                    description={titleMetaData.candidates.description}
                  >
                    <PublicCandidatesPage />
                  </TitleMetaTag>
                </LayoutHome>
              }
            />
            <Route
              path="/get-started"
              element={
                <LayoutMembership scheme="black" invertPseudoElements={true}>
                  <TitleMetaTag
                    title={titleMetaData.getStarted.title}
                    description={titleMetaData.getStarted.description}
                  >
                    <GetStartedPage />
                  </TitleMetaTag>
                </LayoutMembership>
              }
            />

            <Route
              path="/membership"
              element={
                <LayoutMembership pTop="10rem" bgColor="white" scheme="black">
                  <TitleMetaTag
                    title={titleMetaData.pricing.title}
                    description={titleMetaData.pricing.description}
                  >
                    <MembershipPage />
                  </TitleMetaTag>
                </LayoutMembership>
              }
            />
            <Route
              path="/confirm/:confirmationToken"
              element={<VerifyingYourEmail />}
            />

            <Route
              path="/book-a-demo"
              element={
                  <LayoutLogin scheme="mixed" page="joinAsCompany">
                    <TitleMetaTag
                      title={titleMetaData.bookADemo.title}
                      description={titleMetaData.bookADemo.description}
                    >
                      <BookADemoPage />
                    </TitleMetaTag>
                  </LayoutLogin>              }
            />
            <Route
              path="/join-as-client"
              element={
                <MembershipOriginCheck>
                  <LayoutLogin scheme="mixed" page="joinAsCompany">
                    <TitleMetaTag
                      title={titleMetaData.joinAsClient.title}
                      description={titleMetaData.joinAsClient.description}
                    >
                      <JoinAsClientPage />
                    </TitleMetaTag>
                  </LayoutLogin>
                </MembershipOriginCheck>
              }
            />
            <Route
              path="/join-as-candidate"
              element={
                <LayoutLogin scheme="black" page="joinAsCandidate">
                  <TitleMetaTag
                    title={titleMetaData.joinAsCandidate.title}
                    description={titleMetaData.joinAsCandidate.description}
                  >
                    <JoinAsCandidatePage />
                  </TitleMetaTag>
                </LayoutLogin>
              }
            />
            <Route
              path="/login"
              element={
                <LayoutLogin scheme="mixed" page="login">
                  <TitleMetaTag
                    title={titleMetaData.logIn.title}
                    description={titleMetaData.logIn.description}
                  >
                    <LoginPage />
                  </TitleMetaTag>
                </LayoutLogin>
              }
            />
            <Route
              path="/forgot-password"
              element={
                <LayoutLogin scheme="mixed" page="login">
                  <ForgotPasswordPage />
                </LayoutLogin>
              }
            />
            <Route
              path="/password-reset/:resetToken"
              element={
                <LayoutLogin scheme="mixed" page="login">
                  <ResetPasswordPage />
                </LayoutLogin>
              }
            />
            <Route
              path="/password-setup/:setupToken"
              element={
                <LayoutLogin scheme="mixed" page="login">
                  <SetupPasswordPage />
                </LayoutLogin>
              }
            />
          </Route>

          <Route
            element={
              <ClientCandidateChatContextProvider>
                <PusherCheckerWrapper />
              </ClientCandidateChatContextProvider>
            }
          >
            <Route
              path="/faq"
              element={
                <TitleMetaTag
                  title={titleMetaData.faq.title}
                  description={titleMetaData.faq.description}
                >
                  <FaqPage />
                </TitleMetaTag>
              }
            />
            <Route
              path="/privacy"
              element={
                <TitleMetaTag
                  title={titleMetaData.privacy.title}
                  description={titleMetaData.privacy.description}
                >
                  <PrivacyPage />
                </TitleMetaTag>
              }
            />
            <Route
              path="/terms"
              element={
                <TitleMetaTag
                  title={titleMetaData.terms.title}
                  description={titleMetaData.terms.description}
                >
                  <TermsPage />
                </TitleMetaTag>
              }
            />
            <Route
              path="/contact"
              element={
                <TitleMetaTag
                  title={titleMetaData.contactUs.title}
                  description={titleMetaData.contactUs.description}
                >
                  <ContactUsPage />
                </TitleMetaTag>
              }
            />
            <Route element={<SharedRoute />}>
              <Route path="/logout" element={<LogoutPage />} />
              <Route path="/dashboard" element={<DashboardPage />} />
              <Route path="/profile" element={<ProfilePage />} />

              <Route path="/messages" element={<MessagesPage />} />
              <Route
                path="/messages/thread/:id"
                element={<MessagesThreadPage />}
              />

              <Route path="/profile/:uuid" element={<ProfileViewPage />} />
              <Route element={<ClientRoute />}>
                <Route path="/membership-activation" element={<InvoiceNoticePage />} />
                <Route
                  path="/find-candidates"
                  element={
                    <LayoutClientCandidate
                      pseudoElementsType="one"
                      removeOnMobile
                      userType="client"
                      bgColor="backgroundGrey"
                      membershipStatus
                    >
                      <FindCandidatesPage />
                    </LayoutClientCandidate>
                  }
                />
              </Route>
              <Route element={<CandidateRoute />}></Route>
            </Route>
          </Route>

          <Route element={<AdminRoute />}>
            <Route
              path="/admin/clients"
              element={
                <LayoutAdmin>
                  <ClientsPage />
                </LayoutAdmin>
              }
            />
            <Route
              path="/admin/companies"
              element={
                <LayoutAdmin>
                  <CompaniesListContextProvider>
                    <CompaniesPage />
                  </CompaniesListContextProvider>
                </LayoutAdmin>
              }
            />
            <Route
              path="/companies/add"
              element={
                <LayoutAdmin createEditCompanyBg>
                  <CreateEditCompanyPage />
                </LayoutAdmin>
              }
            />
            <Route
              path="/companies/edit/:id"
              element={
                <LayoutAdmin createEditCompanyBg>
                  <CompaniesListContextProvider>
                    <CreateEditCompanyPage edit />
                  </CompaniesListContextProvider>
                </LayoutAdmin>
              }
            />
            <Route
              path="/admin/candidates"
              element={
                <LayoutAdmin>
                  <CandidatesPage />
                </LayoutAdmin>
              }
            />
            <Route
              path="/candidates/add"
              element={
                <LayoutAdmin createCandidateBg>
                  <CreateCandidatePage />
                </LayoutAdmin>
              }
            />
          </Route>
          {/* </Route> */}
          {/* Global */}
          {showStyleguide && (
            <Route
              path="/styleguide"
              element={
                <LayoutStyleguide>
                  <StyleguidePage />
                </LayoutStyleguide>
              }
            />
          )}
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default AppRoutes;
