import { Icon } from "@chakra-ui/react";

export const CustomMinusIcon = (props) => (
  <Icon width="38" height="38" viewBox="0 0 38 38" fill="none" {...props}>
    <circle
      cx="19"
      cy="19"
      r="18.5"
      transform="rotate(90 19 19)"
      fill="white"
      stroke="#EAE8E8"
    />
    <path
      d="M26.0889 18.9961L11.9121 18.9961"
      stroke="#333333"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </Icon>
);
