import { Icon } from "@chakra-ui/react";

export const CustomQualificationsDegreeIcon = (props) => (
  <Icon viewBox="0 0 28 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5352 0.362174C13.876 0.191775 14.2771 0.191775 14.6179 0.362174L26.7232 6.41481C27.1333 6.61986 27.3923 7.03902 27.3923 7.49753C27.3923 7.95605 27.1333 8.37521 26.7232 8.58026L23.7607 10.0615V16.5765C23.7607 17.035 23.5017 17.4542 23.0916 17.6592L14.6179 21.896C14.2771 22.0664 13.876 22.0664 13.5352 21.896L9.88816 20.0725V22.0238C9.88816 22.6924 9.34619 23.2344 8.67763 23.2344C8.00908 23.2344 7.46711 22.6924 7.46711 22.0238V18.862L5.06148 17.6592C4.65138 17.4542 4.39232 17.035 4.39232 16.5765V10.0615L1.4299 8.58026C1.0198 8.37521 0.760742 7.95605 0.760742 7.49753C0.760742 7.03902 1.0198 6.61986 1.4299 6.41481L13.5352 0.362174ZM6.81337 11.272V15.8283L7.46711 16.1552V11.5989L6.81337 11.272ZM9.88816 12.8094V17.3657L14.0765 19.4599L21.3397 15.8283V11.272L14.6179 14.6329C14.2771 14.8033 13.876 14.8033 13.5352 14.6329L9.88816 12.8094ZM4.67809 7.49753L8.67763 9.4973L13.5352 7.06854C14.1331 6.76955 14.8603 7.01193 15.1593 7.6099C15.4582 8.20787 15.2159 8.93501 14.6179 9.23399L11.3845 10.8507L14.0765 12.1968L23.475 7.49753L14.0765 2.79831L4.67809 7.49753Z"
      fill={props.color ? props.color : "#CDEE24"}
    />
  </Icon>
);
