import { Box } from "@chakra-ui/react";
import HeaderNavigation from "../../../private/shared/navs/HeaderNavigation";
import PageHeaderLeftPseudoElementsTypeOne from "../../../private/shared/UI/PageHeaderLeftPseudoElementsTypeOne";
import PageHeaderRightPseudoElementsTypeOne from "../../../private/shared/UI/PageHeaderRightPseudoElementsTypeOne";
import ScrollToTop from "../../../private/shared/UI/ScrollToTop";
import FooterNavigation from "../navs/FooterNavigation";

// used for public-> membership, faq, terms, privacy
const LayoutMembership = ({
  children,
  pTop,
  bgColor,
  scheme,
  invertPseudoElements,
}) => {
  return (
    <Box position="relative" overflow="hidden" bgColor="backgroundGrey">
      <ScrollToTop />
      <PageHeaderLeftPseudoElementsTypeOne
        invertPseudoElements={invertPseudoElements}
      />
      <PageHeaderRightPseudoElementsTypeOne
        invertPseudoElements={invertPseudoElements}
      />
      <HeaderNavigation />
      <Box pt={pTop || 0}>{children}</Box>
      <FooterNavigation scheme={scheme} bgColor={bgColor} />
    </Box>
  );
};

export default LayoutMembership;
