export const titleMetaData = {
  home: {
    title: "Simpler way to hire | SearcHive",
    description:
      "Easily discover talent. Quickly recruit unlimited finance candidates. No recruitment fees.",
  },

  clients: {
    title: "Recruit top talent | SearcHive",
    description:
      "Find the perfect candidate in the pool of pre-screened finance talents and directly communicate with them.",
  },

  candidates: {
    title: "Get hired easily | SearcHive",
    description:
      "Sit back and wait to be approached by market leading organisations. No third party recruiters ever involved.",
  },

  faq: {
    title: "FAQs | SearcHive",
    description: "Get answers to frequently asked questions about SearcHive.",
  },
  contactUs: {
    title: "Contact us | SearcHive",
    description:
      "Get in contact with SearcHive to discuss what we can do for your business.",
  },

  pricing: {
    title: "Pricing | SearcHive",
    description:
      "Choose a billing model. Pay via bank transfer. No credit card needed.",
  },
  logIn: {
    title: "Log in | SearcHive",
    description:
      "Easily discover talent. Quickly recruit unlimited finance candidates. No recruitment fees.",
  },
  getStarted: {
    title: "Sign up | SearcHive",
    description: "Create account to start using SearcHive.",
  },
  joinAsClient: {
    title: "Sign up as a company | SearcHive",
    description:
      "Create account to easily discover and recruit finance candidates.",
  },
  bookADemo: {
    title: "Book a demo | SearcHive",
    description: "Get in touch with SearcHive to book a demo."
  },
  joinAsCandidate: {
    title: "Join as a candidate | SearcHive",
    description: "Create account to find your next role.",
  },
  privacy: {
    title: "Privacy policy | SearcHive",
    description: "Read SearcHive's Privacy policy.",
  },
  terms: {
    title: "Terms and conditions | SearcHive",
    description: "Read SearcHive's Terms and conditions.",
  },
};
