import { Icon } from "@chakra-ui/react";

export const CustomSearchIcon = (props) => (
  <Icon viewBox="0 0 28 28" fill="none" {...props}>
    <path
      d="M19.4824 19.5049L27 27M22.6667 11.8333C22.6667 17.8164 17.8164 22.6667 11.8333 22.6667C5.85025 22.6667 1 17.8164 1 11.8333C1 5.85025 5.85025 1 11.8333 1C17.8164 1 22.6667 5.85025 22.6667 11.8333Z"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
