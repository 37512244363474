// theme/index.js
import { extendTheme } from "@chakra-ui/react";

// Global style overrides
import styles from "./styles";

import { colors, typography } from "./foundations";

// Component style overrides
import {
  Button,
  Container,
  Input,
  Checkbox,
  FormLabel,
  Modal,
  Menu,
  Drawer,
  NumberInput,
  Select,
  Table,
  Textarea,
} from "./components";

const fonts = typography.fonts;
const textStyles = typography.textStyles;

const overrides = {
  styles,
  // Other foundational style overrides go here
  colors,
  fonts,
  textStyles,
  components: {
    Button,
    Container,
    Input,
    Checkbox,
    FormLabel,
    Modal,
    Menu,
    Drawer,
    NumberInput,
    Select,
    Table,
    Textarea,
    // Other components go here
  },
};

export default extendTheme(overrides);
