import { Box, Text, Center } from "@chakra-ui/react";
import SectionHeading from "../shared/SectionHeading";

const TextElement = ({
  textBlockSize,
  stepNumber,
  noCircle,
  bodyText,
  separatorText,
  ourStorySection,
  hasBottomElement,
  twoLine,
  lineOneText,
  lineTwoText,
  lastWord,
}) => {
  const strArr = bodyText.split(separatorText);
  const textPart1 = strArr[0];
  const textPart2 = strArr[1];
  const textPart3 = separatorText ? separatorText : "";

  return (
    <Center
      position="relative"
      mt={stepNumber ? { base: "2rem", sm: "4rem", lg: "5rem" } : 0}
      maxW={textBlockSize}
      w="full"
    >
      <Box position="relative">
        <Text
          position="absolute"
          left="-1rem"
          top={{ base: "-6rem", sm: "-12rem" }}
          color="fieldStroke"
          fontSize={{ base: "10rem", sm: "18.75rem" }}
          //fontWeight="900"
          fontFamily="Inter bold"
        >
          {stepNumber}
        </Text>
        <Box position="relative">
          <SectionHeading
            twoLine={twoLine}
            lineOneText={lineOneText}
            lineTwoText={lineTwoText}
            lastWord={lastWord}
            noCircle={noCircle}
            hasBottomElement={!!hasBottomElement}
          />
          <Text
            color="darkGrey"
            fontSize={
              hasBottomElement
                ? { base: "1.5rem", lg: "1.875rem" }
                : { base: "1.188rem", lg: "1.5rem" }
            }
            lineHeight={
              hasBottomElement
                ? { base: "2rem", lg: "2.4375rem" }
                : { base: "1.5rem", lg: "1.95rem" }
            }
            mt={lineOneText === "Recruit" && "1.875rem"}
          >
            {textPart1}
            <Text as="span" fontWeight="700" borderBottom="4px solid #CDEE24">
              {textPart3}
            </Text>
            {textPart2}
          </Text>
          {ourStorySection && (
            <Text
              mt="2rem"
              fontFamily="Plus Jakarta Sans Bold"
              fontSize={{ base: "1.313rem", lg: "1.875rem" }}
              lineHeight={{ base: "1.813rem", lg: "2.625rem" }}
            >
              This Platform is a game-changer and will disrupt the industry!
            </Text>
          )}
        </Box>
      </Box>
    </Center>
  );
};

export default TextElement;
