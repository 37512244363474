import { inputAnatomy as parts } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from "@chakra-ui/styled-system";

// This function creates a set of function that helps us create multipart component styles.
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  //   field: {
  //     width: "100%",
  //     minWidth: 0,
  //     outline: 0,
  //     position: "relative",
  //     appearance: "none",
  //     transitionProperty: "common",
  //     transitionDuration: "normal",
  //     _disabled: {
  //       opacity: 0.4,
  //       cursor: "not-allowed",
  //     },
  //   },

  field: {
    ":-webkit-autofill": {
      transition: "background-color 600000s 0s, color 600000s 0s",
    },
    ":-webkit-autofill:focus": {
      transition: "background-color 600000s 0s, color 600000s 0s",
    },
  },
});

const size = {
  md: defineStyle({
    fontSize: "1rem", // 16px
    px: "4",
    h: "2.5rem", // 40px
    borderRadius: "1.875rem", // 30px
  }),
  sm: defineStyle({
    fontSize: "0.875rem", // 16px
    px: "3",
    h: "2rem", // 40px
    borderRadius: "1.875rem", // 30px
  }),
  imitateSelect: defineStyle({
    padding: "0 0.5rem",
    h: "2.5rem", // 40px
    borderRadius: "1.875rem", // 30px
    fontSize: "1rem", // 16px
  }),
};

const sizes = {
  md: definePartsStyle({
    field: size.md,
    addon: size.md,
  }),
  sm: definePartsStyle({
    field: size.sm,
    addon: size.sm,
  }),
  imitateSelect: definePartsStyle({
    field: size.imitateSelect,
    addon: size.imitateSelect,
  }),
};

const outline = definePartsStyle({
  field: {
    borderColor: "semiDarkGrey",
    backgroundColor: "white",
    _placeholder: {
      color: "placeholderColor",
    },

    _invalid: {
      borderColor: "error",
      boxShadow: `0 0 0 1px error`,
    },

    _hover: {
      borderColor: "electricLime",
      boxShadow: "0 0 0 1px electricLime",
    },

    _focusVisible: {
      borderColor: "electricLime",
      boxShadow: "0 0 0 1px electricLime",
    },
  },
});

const select = definePartsStyle({
  field: {
    border: "1px solid #EAE8E8",
    backgroundColor: "white",
    _placeholder: {
      color: "placeholderColor",
    },

    _invalid: {
      borderColor: "error",
      boxShadow: `0 0 0 1px error`,
    },

    _hover: {
      borderColor: "electricLime",
      boxShadow: "0 0 0 1px electricLime",
    },

    _focusVisible: {
      borderColor: "electricLime",
      boxShadow: "0 0 0 1px electricLime",
    },
  },
});

const bottomOnly = definePartsStyle({
  field: {
    borderBottom: "1px solid #D7D7D7",
    pl: 0,
    borderRadius: 0,
    backgroundColor: "white",
    _placeholder: {
      color: "placeholderColor",
    },

    _invalid: {
      borderColor: "error",
      boxShadow: `0 0 0 1px error`,
    },

    _hover: {
      borderColor: "electricLime",
      boxShadow: "0 0 0 1px electricLime",
    },

    _focusVisible: {
      borderColor: "electricLime",
      boxShadow: "0 0 0 1px electricLime",
    },
  },
});

const variants = {
  outline,
  select,
  bottomOnly,
};

export const Input = defineMultiStyleConfig({
  baseStyle,
  variants,
  sizes,
  defaultProps: {
    size: "md",
    variant: "outline",
  },
});
