import { createContext, useState } from "react";

const ClientCandidateChatContext = createContext({
  chatListState: {
    items: [{}, {}, {}, {}, {}],
    total: 0,
    pageCount: 0,
  },
  chatType: { title: "All chats", status: "all" },
  newMessage: null,
  itemsPerPage: 10,
  pageNumber: 0,
  numOfUnreadMessages: 0,
  pusherObj: null,
  channelValue: null,
  updateChatList: () => { },
  updateChatType: () => { },
  updateNewMessage: () => { },
  updateItemsPerPage: () => { },
  updatePageNumber: () => { },
  updateNumOfUnreadMessages: () => { },
  updateChannelValue: () => { },
});

export const ClientCandidateChatContextProvider = ({ children }) => {
  const [numOfUnreadMessages, setNumOfUnreadMessages] = useState(0);
  const [pusherObj, setPusherObj] = useState(null);
  const [channelValue, setChannelValue] = useState(null);

  const [chatListState, setChatState] = useState({
    items: [{}, {}, {}, {}, {}],
    total: 0,
    pageCount: 0,
  });

  const [chatType, setChatType] = useState({
    title: "All chats",
    status: "all",
  });
  const [newMessage, setNewMessage] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);

  const updateChatListHandler = (data) => {
    setChatState(data);
  };

  const updateChatTypeHandler = (chatType) => {
    setChatType(chatType);
  };

  const updateNewMessageHandler = (message) => {
    setNewMessage(message);
  };

  const updateItemsPerPageHandler = (value) => {
    setItemsPerPage(value);
  };

  const updatePageNumberHandler = (value) => {
    setPageNumber(value);
  };

  const updateNumOfUnreadMessages = (param) => {
    setNumOfUnreadMessages(param);
  };

  const updatePusherObj = (param) => {
    setPusherObj(param);
  };

  const updateChannelValue = (param) => {
    setChannelValue(param);
  };

  const context = {
    chatListState,
    chatType,
    newMessage,
    itemsPerPage,
    pageNumber,
    updateChatList: updateChatListHandler,
    updateChatType: updateChatTypeHandler,
    updateNewMessage: updateNewMessageHandler,
    updateItemsPerPage: updateItemsPerPageHandler,
    updatePageNumber: updatePageNumberHandler,
    numOfUnreadMessages,
    updateNumOfUnreadMessages,
    pusherObj,
    updatePusherObj,
    channelValue,
    updateChannelValue,
  };

  return (
    <ClientCandidateChatContext.Provider value={context}>
      {children}
    </ClientCandidateChatContext.Provider>
  );
};

export default ClientCandidateChatContext;
