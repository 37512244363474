import { Icon } from "@chakra-ui/react";

export const CustomChevronIcon = (props) => (
  <Icon viewBox="0 0 7 12" fill="none" {...props}>
    <path
      d="M6.18048 1L1.18048 6L6.18048 11"
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
