import { createContext, useState } from "react";

const CompaniesListContext = createContext({
  companiesState: {
    items: [],
    total: 0,
    pageCount: 0,
  },
  updateCompaniesState: () => {},
});

export const CompaniesListContextProvider = ({ children }) => {
  const [companiesState, setCompaniesState] = useState({
    items: [],
    total: 0,
    pageCount: 0,
  });

  const updateCompaniesStateHandler = (param) => {
    setCompaniesState(param);
  };

  const context = {
    companiesState,
    updateCompaniesState: updateCompaniesStateHandler,
  };

  return (
    <CompaniesListContext.Provider value={context}>
      {children}
    </CompaniesListContext.Provider>
  );
};

export default CompaniesListContext;
