import { Icon } from "@chakra-ui/react";

export const CustomReactSelectDropdownIndicator = (props) => (
  <Icon viewBox="0 0 9 7" fill="none" {...props}>
    <path
      d="M4.5 0.820801L0.602885 6.0708L8.39711 6.0708L4.5 0.820801Z"
      fill="black"
    />
  </Icon>
);
