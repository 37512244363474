export const FOR_COMPANIES = [
  {
    question: "What is SearcHive?",
    answer:
      "SearcHive is an easy-to-use platform full of the very best pre-screened and actively looking Finance, Accounting, Banking, M&A, Tax, Treasury and Strategy candidates.",
  },
  // {
  //   question: "How much does it cost?",
  //   answer:
  //     "For Medium-High volume hiring an Annual subscription starts at £25,000. This gives you 24/7 access for a year and unlimited hires with no additional finders’ fees. Pay upfront or pay monthly. ",
  //   addition:
  //     "For Low volume hiring (1-2 hires annually) there is a monthly option at a cost of £3,000 for unlimited hires with no additional finders’ fees. This option can be used up to twice annually. We also offer a 12-month subscription paid monthly. ",
  // },
  {
    question: "How does SearcHive take payments?",
    answer:
      "Once you have chosen your plan you would be invoiced directly and pay by BACS.  Payment terms are 7 days.  The sooner you pay the sooner you have access to talent.",
  },
  {
    question: "What are the benefits?",
    answer:
      "Your annual subscription will give you 24/7 search accessibility, the opportunity to message up to 100 pre-screened and actively looking candidates per day, and have real-time direct communication with candidates. If you are currently hiring or pipelining for the future you have unlimited hiring capability with no additional finders’ fees ever.",
  },
  {
    question: "Who should subscribe to SearcHive?",
    answer:
      "If you are a Talent Lead recruiting for your Finance teams you need SearcHive. If you are an RPO looking after finance hires, or you are in Finance and manage your own hiring, SearcHive will help you achieve your goals faster.",
  },
  {
    question: "What salary range are the candidates in SearcHive?",
    answer: "Typically from £40,000 to £250,000.",
  },
  {
    question: "How do we get started on SearcHive?",
    answer:
      "To get started all you need to do is choose your plan and click create account. You'll then be able to set up your company's profile and start sourcing people.",
  },
  {
    question:
      "Would a Client pay finders fees on top of an annual subscription?",
    answer:
      "No, never. We want to ensure you can recruit the very best talent regardless of budget.",
  },
  {
    question: "Can I talk to a human if need be?",
    answer:
      "Absolutely! There is an option to communicate by email hello@searchive.co.uk, phone or video call to ensure your SearcHive experience is as seamless and effective as possible.",
  },
];

export const FOR_CANDIDATES = [
  {
    question: "What is SearcHive?",
    answer:
      "SearcHive is an easy-to-use platform full of the very best pre-screened and actively looking Finance, Accounting, Banking, M&A, Tax, Treasury and Strategy candidates.",
  },
  {
    question: "How much does it cost?",
    answer: "SearcHive is completely FREE for candidates to join.",
  },
  {
    question: "What are the benefits?",
    answer:
      "It is completely free to join. You have your own personal profile and dashboard of conversations. You are in control of inputting your data to include salary required, desired locations, job role/s sought and “About Me”.  You will be contacted directly by organisations, you have the option to accept or reject their approach. You then communicate in real-time with hiring managers. You will gain access to roles long before they are advertised or with a recruiter, all with complete confidentiality. No third party involvement.",
  },
  {
    question: "Who should subscribe to SearcHive?",
    answer:
      "If you are currently looking for a new role, or ambitious and always passively interested to know what the market has to offer a candidate like, you SearcHive gives you access to these roles.",
  },
  {
    question: "What salary range are the candidates in SearcHive?",
    answer: "Typically from £40,000 to £250,000.",
  },
  {
    question: "How does a Candidate register on the platform?",
    answer:
      "Simply send us your CV via our website, LinkedIn, email, wherever you heard about us! Your details will be screened and account created. You will then be sent a verification email and can sit back and wait to be approached about relevant roles in world class organisations.",
  },
  {
    question: "How long does it take to verify my account?",
    answer:
      "On weekdays (Monday–Friday), it typically takes 10 minutes to verify a profile and then a further 10 minutes to build it.  After this you will receive an email to verify your account. These timescales may vary if you are joining over the weekend.",
  },
  {
    question: "Can I talk to a human if need be?",
    answer:
      "Absolutely! There is an option to communicate by email hello@searchive.co.uk, phone or video call to ensure your SearcHive experience is as seamless and effective as possible.",
  },
];
