import { Icon } from "@chakra-ui/react";

export const CustomLinkedinIcon = (props) => (
  <Icon viewBox="0 0 23 24" fill="none" {...props}>
    <path
      d="M7.49528 19.2598H4.49674V9.57878H7.49528V19.2598ZM7.79446 6.4944C7.79446 5.52506 7.00802 4.73828 6.03918 4.73828C5.06667 4.73828 4.28223 5.52506 4.28223 6.4944C4.28223 7.46407 5.06667 8.25085 6.03918 8.25085C7.00802 8.25085 7.79446 7.46407 7.79446 6.4944ZM18.718 13.9337C18.718 11.3349 18.169 9.40743 15.1332 9.40743C13.6744 9.40743 12.6952 10.137 12.2954 10.8957H12.2926V9.57878H9.37974V19.2598H12.2926V14.4531C12.2926 13.1943 12.613 11.9748 14.1734 11.9748C15.7125 11.9748 15.7623 13.4143 15.7623 14.5331V19.2598H18.718V13.9337Z"
      fill={props.color ? props.color : "#D7D7D7"}
    />
    <rect
      x="1"
      y="1.5"
      width="21"
      height="21"
      rx="3"
      stroke={props.color ? props.color : "#D7D7D7"}
      strokeWidth="2"
    />
  </Icon>
);
