import {
  Box,
  Flex,
  Grid,
  GridItem,
  List,
  ListItem,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import PictureOptimized from "../../shared/PictureOptimized";
import removeTheRiskX1Src from "../../../assets/images/public/home/remove_the_risk_x1.webp";
import removeTheRiskX2Src from "../../../assets/images/public/home/remove_the_risk_x2.webp";
import removeTheRiskX1FallbackSrc from "../../../assets/images/public/home/remove_the_risk_x1_min.png";
import { CustomLimeRingIcon } from "../../../assets/icons";
import SectionHeading from "../shared/SectionHeading";

const RemoveTheRisksSection = () => {
  const [isSmallerThanLG] = useMediaQuery("(max-width: 62em)");

  return isSmallerThanLG ? (
    <Grid
      templateColumns="1fr"
      placeItems="center center"
      pt={{ base: "3rem", lg: "7.3rem", "2xl": "13.75rem" }}
    >
      <GridItem>
        <Flex
          direction={{ base: "column", lg: "row" }}
          justifyContent="center"
          alignItems="center"
          gap={{ base: "3rem", md: "5rem", xl: "7.875rem" }}
          w="full"
        >
          <Flex
            maxW="36.875rem"
            flexDirection="column"
            gap="1.875rem"
            m={{ lg: "2rem" }}
            pl={{ base: "1.25rem", lg: 0 }}
            alignItems="flex-start"
            textAlign="left"
          >
            <SectionHeading
              twoLine={true}
              lineOneText="Remove the risk"
              lineTwoText="of a bad"
              lastWord="hire"
            />
            <Text
              fontSize={{ base: "1.188rem", lg: "1.25rem" }}
              lineHeight="1.625rem"
              color="darkGrey"
            >
              We have a killer ingredient of people and technology delivering
              you immediate access to finance talents.
            </Text>
            <List
              fontSize={{ base: "1.188rem", lg: "1.25rem" }}
              lineHeight="1.625rem"
              color="darkGrey"
              spacing={{ base: "0.9rem", lg: "1.5rem" }}
              textAlign="left"
            >
              <ListItem>
                <Flex
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  gap={{ base: "0.7rem", lg: "1.125rem" }}
                >
                  <CustomLimeRingIcon boxSize="1.875rem" />
                  <Text>
                    All candidates are
                    <Text as="span" textStyle="bodyBold">
                      {" "}
                      pre-screened{" "}
                    </Text>
                    by industry experts
                  </Text>
                </Flex>
              </ListItem>
              <ListItem>
                <Flex
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  gap="1.125rem"
                >
                  <CustomLimeRingIcon boxSize="1.875rem" />
                  <Text>
                    Unlimited search and
                    <Text as="span" textStyle="bodyBold">
                      {" "}
                      NO recruitment fees{" "}
                    </Text>
                  </Text>
                </Flex>
              </ListItem>
              <ListItem>
                <Flex
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  gap="1.125rem"
                >
                  <CustomLimeRingIcon boxSize="1.875rem" />
                  <Text>
                    All candidates
                    <Text as="span" textStyle="bodyBold">
                      {" "}
                      quickly integrate{" "}
                    </Text>
                    into your internal teams
                  </Text>
                </Flex>
              </ListItem>
            </List>
          </Flex>
          <PictureOptimized
            srcX1={removeTheRiskX1Src}
            srcX2={removeTheRiskX2Src}
            srcX3={removeTheRiskX2Src}
            fallbackSrc={removeTheRiskX1FallbackSrc}
            width="100%"
          />
        </Flex>
      </GridItem>
    </Grid>
  ) : (
    <Grid templateColumns="1fr auto" position="relative" w="full">
      <GridItem placeSelf="center" minW={{base: '500px', lg: '600px'}}>
        <Flex
          maxW="36.875rem"
          flexDirection="column"
          gap="1.875rem"
          m={{ lg: "2rem" }}
          pl={{ base: "1.25rem", lg: 0 }}
          alignItems="flex-start"
          textAlign="left"
        >
          <SectionHeading
            twoLine={true}
            lineOneText="Remove the risk"
            lineTwoText="of a bad"
            lastWord="hire"
          />
          <Text
            fontSize={{ base: "1.188rem", lg: "1.25rem" }}
            lineHeight="1.625rem"
            color="darkGrey"
          >
            We have a killer ingredient of people and technology delivering you
            immediate access to finance talents.
          </Text>
          <List
            fontSize={{ base: "1.188rem", lg: "1.25rem" }}
            lineHeight="1.625rem"
            color="darkGrey"
            spacing={{ base: "0.9rem", lg: "1.5rem" }}
            textAlign="left"
          >
            <ListItem>
              <Flex
                justifyContent="flex-start"
                alignItems="flex-start"
                gap={{ base: "0.7rem", lg: "1.125rem" }}
              >
                <CustomLimeRingIcon boxSize="1.875rem" />
                <Text>
                  All candidates are
                  <Text as="span" textStyle="bodyBold">
                    {" "}
                    pre-screened{" "}
                  </Text>
                  by industry experts
                </Text>
              </Flex>
            </ListItem>
            <ListItem>
              <Flex
                justifyContent="flex-start"
                alignItems="flex-start"
                gap="1.125rem"
              >
                <CustomLimeRingIcon boxSize="1.875rem" />
                <Text>
                  Unlimited search and
                  <Text as="span" textStyle="bodyBold">
                    {" "}
                    NO recruitment fees{" "}
                  </Text>
                </Text>
              </Flex>
            </ListItem>
            <ListItem>
              <Flex
                justifyContent="flex-start"
                alignItems="flex-start"
                gap="1.125rem"
              >
                <CustomLimeRingIcon boxSize="1.875rem" />
                <Text>
                  All candidates
                  <Text as="span" textStyle="bodyBold">
                    {" "}
                    quickly integrate{" "}
                  </Text>
                  into your internal teams
                </Text>
              </Flex>
            </ListItem>
          </List>
        </Flex>
      </GridItem>
      <GridItem>
        <Box position="sticky" top="0">
          <PictureOptimized
            srcX1={removeTheRiskX1Src}
            srcX2={removeTheRiskX2Src}
            fallbackSrc={removeTheRiskX1FallbackSrc}
            width="100%"
            // height="100vh"
          />
        </Box>
      </GridItem>
    </Grid>
  );
};

export default RemoveTheRisksSection;
