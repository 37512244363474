import { useContext, useEffect } from "react";
import ClientCandidateChatContext from "../../../../../context/ClientCandidateChatContext";
import NewMessageEvent from "./NewMessageEvent";

const UnreadMessagesEvent = ({ channel, setChannel }) => {
  const { updateNumOfUnreadMessages } = useContext(ClientCandidateChatContext);

  useEffect(() => {
    if (channel) {
      channel.bind("number_of_unread_messages_event", function (data) {
        updateNumOfUnreadMessages(data.totalUnreadMessages);
      });
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel]);

  return <NewMessageEvent channel={channel} />;
};

export default UnreadMessagesEvent;
