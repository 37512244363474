import { Icon } from "@chakra-ui/react";

export const CustomRemoveIcon = (props) => (
  <Icon viewBox="0 0 21 21" fill="none" {...props}>
    <path
      d="M11.6052 11.0445L14.4868 8.16287C14.6236 8.02635 14.7005 7.8411 14.7007 7.64786C14.7009 7.45462 14.6243 7.26923 14.4877 7.13247C14.3512 6.99571 14.166 6.91878 13.9727 6.91861C13.7795 6.91844 13.5941 6.99504 13.4573 7.13156L10.5757 10.0132L7.69412 7.13156C7.55736 6.9948 7.37187 6.91797 7.17847 6.91797C6.98506 6.91797 6.79957 6.9948 6.66281 7.13156C6.52605 7.26832 6.44922 7.45381 6.44922 7.64722C6.44922 7.84062 6.52605 8.02611 6.66281 8.16287L9.54442 11.0445L6.66281 13.9261C6.52605 14.0628 6.44922 14.2483 6.44922 14.4417C6.44922 14.6351 6.52605 14.8206 6.66281 14.9574C6.79957 15.0942 6.98506 15.171 7.17847 15.171C7.37187 15.171 7.55736 15.0942 7.69412 14.9574L10.5757 12.0758L13.4573 14.9574C13.5941 15.0942 13.7796 15.171 13.973 15.171C14.1664 15.171 14.3519 15.0942 14.4886 14.9574C14.6254 14.8206 14.7022 14.6351 14.7022 14.4417C14.7022 14.2483 14.6254 14.0628 14.4886 13.9261L11.6052 11.0445Z"
      fill="#333333"
    />
    <circle
      cx="10.5752"
      cy="10.8086"
      r="9.25"
      stroke="#333333"
      strokeWidth="1.5"
    />
  </Icon>
);
