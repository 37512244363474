import { Icon } from "@chakra-ui/react";

export const CustomArrowIcon = (props) => (
  <Icon viewBox="0 0 17 17" fill="none" {...props}>
    <path
      d="M1.57812 8.85352L15.5781 8.85352"
      stroke="#333"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="fgStroke"
    />
    <path
      d="M8.57812 15.8496L15.5781 8.85205L8.57813 1.8545"
      stroke="#333"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="fgStroke"
    />
  </Icon>
);
