import {
  Flex,
  Center,
  Link,
  Image,
  Spacer,
  Box,
  useDisclosure,
  VStack,
  Button,
} from "@chakra-ui/react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import logoUrl from "../../../../assets/vectors/logo.svg";
import Hamburger from "hamburger-react";
import { useEffect, useRef } from "react";

const SidebarNavigation = (props) => {
  const location = useLocation();
  const { isOpen, onToggle, onClose } = useDisclosure();
  const parentRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
      parentRef.current.style.overflowY = "auto";
      parentRef.current.style.height = "100vh";
    } else {
      document.body.style.overflow = "auto";
      parentRef.current.style.overflowY = "hidden";
      parentRef.current.style.height = "4rem";
    }
  }, [isOpen]);

  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      w="full"
      height="4rem" // 100vh
      boxShadow="0px 0px 5px rgba(0, 0, 0, 0.1)"
      zIndex="6"
      overflowX="hidden"
      overflowY="hidden"
      ref={parentRef}
    >
      <Flex alignItems="center" px="1rem" h="4rem" bgColor="#FFFFFF">
        <Center flexShrink="0">
          <Link as={RouterLink} to="/" onClick={onClose}>
            <Image src={logoUrl} w="7rem" />
          </Link>
        </Center>
        <Spacer />
        <Hamburger toggled={isOpen} toggle={onToggle} size={20} rounded />
      </Flex>
      <Box
        position="absolute"
        top="4rem"
        left={!isOpen ? "100%" : "0"}
        width="full"
        minHeight="calc(100vh - 4rem)"
        bgColor="#000"
        zIndex={5}
        transition="all 0.35s"
        py="6rem"
      >
        <VStack gap="0.5rem" mb="2rem">
          {props.navLinksList.map((item) => {
            return (
              <Link
                key={item.title}
                as={item.title === "Free trial" ? Link : RouterLink}
                to={item.title === "Free trial" ? null : item.link}
                href={
                  item.title === "Free trial"
                    ? "https://freetrial.searchive.co.uk/#join-the-hive"
                    : null
                }
                isExternal={item.title === "Free trial" ? true : null}
                color="white"
                textStyle="headingExtraBold"
                borderBottom={
                  location.pathname === item.link
                    ? "1px solid #CDEE24"
                    : "1px solid transparent"
                }
                onClick={onToggle}
                // className={location.pathname !== item.link && "animateLink"}
                // _hover={{ textDecoration: "none" }}
              >
                {item.title}
              </Link>
            );
          })}
        </VStack>
        <VStack gap="2rem">
          <Link
            as={RouterLink}
            to="/login"
            fontFamily="Plus Jakarta Sans Bold, sans-serif"
            fontSize="1rem"
            color="limeText"
            // className="animateLink"
            // _hover={{ textDecoration: "none" }}
          >
            Log in
          </Link>
          <Button
            variant="solidWhite"
            as={RouterLink}
            to="/get-started"
            size="sm"
            onClick={onToggle}
          >
            Get started
          </Button>
        </VStack>
      </Box>
    </Box>
  );
};

export default SidebarNavigation;
