import { ListItem, Text, UnorderedList, Link, VStack } from "@chakra-ui/react";

const searchiveLink = "https://searchive.co.uk/";
const searchiveContact = "https://searchive.co.uk/contact";
const searchiveEmail = "hello@searchive.co.uk";

export const ListOne = () => {
  return (
    <UnorderedList spacing="0.5rem">
      <ListItem>
        <Text as="span" textStyle="bodyBold">
          Account
        </Text>{" "}
        means a unique account created for You to access our Service or parts of
        our Service.{" "}
      </ListItem>
      <ListItem>
        <Text as="span" textStyle="bodyBold">
          Affiliate
        </Text>{" "}
        means an entity that controls, is controlled by or is under common
        control with a party, where "control" means ownership of 50% or more of
        the shares, equity interest or other securities entitled to vote for
        election of directors or other managing authority.{" "}
      </ListItem>
      <ListItem>
        <Text as="span" textStyle="bodyBold">
          Application
        </Text>{" "}
        refers to SearcHive, the software program provided by the Company.{" "}
      </ListItem>
      <ListItem>
        <Text as="span" textStyle="bodyBold">
          Company
        </Text>{" "}
        (referred to as either "the Company", "We", "Us" or "Our" in this
        Agreement) refers to SearcHive Ltd, Rotten Row Farmhouse, Rotten Row,
        Hambleden, Oxon RG9 6NB.{" "}
      </ListItem>
      <ListItem>
        <Text as="span" textStyle="bodyBold">
          Cookies
        </Text>{" "}
        are small files that are placed on Your computer, mobile device or any
        other device by a website, containing the details of Your browsing
        history on that website among its many uses.{" "}
      </ListItem>
      <ListItem>
        <Text as="span" textStyle="bodyBold">
          Country
        </Text>{" "}
        refers to: United Kingdom{" "}
      </ListItem>
      <ListItem>
        <Text as="span" textStyle="bodyBold">
          Device
        </Text>{" "}
        means any device that can access the Service such as a computer, a
        cellphone or a digital tablet.{" "}
      </ListItem>
      <ListItem>
        <Text as="span" textStyle="bodyBold">
          Personal Data
        </Text>{" "}
        is any information that relates to an identified or identifiable
        individual.{" "}
      </ListItem>
      <ListItem>
        <Text as="span" textStyle="bodyBold">
          Service
        </Text>{" "}
        refers to the Application or the Website or both.{" "}
      </ListItem>
      <ListItem>
        <Text as="span" textStyle="bodyBold">
          Service Provider
        </Text>{" "}
        means any natural or legal person who processes the data on behalf of
        the Company. It refers to third-party companies or individuals employed
        by the Company to facilitate the Service, to provide the Service on
        behalf of the Company, to perform services related to the Service or to
        assist the Company in analyzing how the Service is used.{" "}
      </ListItem>
      <ListItem>
        <Text as="span" textStyle="bodyBold">
          Third-party Social Media Service
        </Text>{" "}
        refers to any website or any social network website through which a User
        can log in or create an account to use the Service.{" "}
      </ListItem>
      <ListItem>
        <Text as="span" textStyle="bodyBold">
          Usage Data
        </Text>{" "}
        refers to data collected automatically, either generated by the use of
        the Service or from the Service infrastructure itself (for example, the
        duration of a page visit).{" "}
      </ListItem>
      <ListItem>
        <Text as="span" textStyle="bodyBold">
          Website
        </Text>{" "}
        refers to SearcHive, accessible from{" "}
        <Text
          as={Link}
          href={searchiveLink}
          isExternal
          color="limeText"
          textStyle="bodyMedium"
        >
          http://www.searchive.co.uk{" "}
        </Text>
      </ListItem>
      <ListItem>
        <Text as="span" textStyle="bodyBold">
          You
        </Text>{" "}
        means the individual accessing or using the Service, or the company, or
        other legal entity on behalf of which such individual is accessing or
        using the Service, as applicable.
      </ListItem>
    </UnorderedList>
  );
};

export const ListTwo = () => {
  return (
    <UnorderedList spacing="0.5rem">
      <ListItem>Email address</ListItem>
      <ListItem>First name and last name </ListItem>
      <ListItem>Phone number</ListItem>
      <ListItem>Address, State, Province, ZIP/Postal code, City</ListItem>
      <ListItem>Usage data</ListItem>
    </UnorderedList>
  );
};

export const ListThree = () => {
  return (
    <UnorderedList spacing="0.5rem">
      <ListItem>Google</ListItem>
      <ListItem>Facebook</ListItem>
      <ListItem>Twitter</ListItem>
      <ListItem>LinkedIn</ListItem>
    </UnorderedList>
  );
};

export const ListFour = () => {
  return (
    <UnorderedList spacing="0.5rem">
      <ListItem>
        Pictures and other information from your Device's camera and photo
        library
      </ListItem>
    </UnorderedList>
  );
};

export const ListFive = () => {
  return (
    <UnorderedList spacing="0.5rem">
      <ListItem>
        <Text as="span" textStyle="bodyBold">
          Cookies or Browser Cookies.{" "}
        </Text>{" "}
        A cookie is a small file placed on Your Device. You can instruct Your
        browser to refuse all Cookies or to indicate when a Cookie is being
        sent. However, if You do not accept Cookies, You may not be able to use
        some parts of our Service. Unless you have adjusted Your browser setting
        so that it will refuse Cookies, our Service may use Cookies.{" "}
      </ListItem>{" "}
      <ListItem>
        <Text as="span" textStyle="bodyBold">
          Web Beacons.{" "}
        </Text>{" "}
        Certain sections of our Service and our emails may contain small
        electronic files known as web beacons (also referred to as clear gifs,
        pixel tags, and single-pixel gifs) that permit the Company, for example,
        to count users who have visited those pages or opened an email and for
        other related website statistics (for example, recording the popularity
        of a certain section and verifying system and server integrity).{" "}
      </ListItem>
    </UnorderedList>
  );
};

export const ListSix = () => {
  return (
    <UnorderedList spacing="1rem">
      <ListItem>
        <Text textStyle="bodyBold">Necessary / Essential Cookies </Text>
        <VStack gap="1rem" mt="1rem" alignItems="flex-start">
          <Text>Type: Session Cookies</Text>
          <Text>Administered by: Us</Text>
          <Text>
            Purpose: These Cookies are essential to provide You with services
            available through the Website and to enable You to use some of its
            features. They help to authenticate users and prevent fraudulent use
            of user accounts. Without these Cookies, the services that You have
            asked for cannot be provided, and We only use these Cookies to
            provide You with those services.
          </Text>
        </VStack>
      </ListItem>

      <ListItem>
        <Text textStyle="bodyBold">
          Cookies Policy / Notice Acceptance Cookies
        </Text>
        <VStack gap="1rem" mt="1rem" alignItems="flex-start">
          <Text>Type: Persistent Cookies</Text>
          <Text>Administered by: Us</Text>
          <Text>
            Purpose: These Cookies identify if users have accepted the use of
            cookies on the Website.
          </Text>
        </VStack>
      </ListItem>

      <ListItem>
        <Text textStyle="bodyBold">Functionality Cookies </Text>
        <VStack gap="1rem" mt="1rem" alignItems="flex-start">
          <Text>Type: Persistent Cookies</Text>
          <Text>Administered by: Us</Text>
          <Text>
            Purpose: These Cookies allow us to remember choices You make when
            You use the Website, such as remembering your login details or
            language preference. The purpose of these Cookies is to provide You
            with a more personal experience and to avoid You having to re-enter
            your preferences every time You use the Website.
          </Text>
        </VStack>
      </ListItem>
    </UnorderedList>
  );
};

export const ListSeven = () => {
  return (
    <UnorderedList spacing="0.5rem">
      <ListItem>
        <Text as="span" textStyle="bodyBold">
          To provide and maintain our Service,{" "}
        </Text>{" "}
        including to monitor the usage of our Service.
      </ListItem>{" "}
      <ListItem>
        <Text as="span" textStyle="bodyBold">
          To manage Your Account:{" "}
        </Text>{" "}
        to manage Your registration as a user of the Service. The Personal Data
        You provide can give You access to different functionalities of the
        Service that are available to You as a registered user.{" "}
      </ListItem>{" "}
      <ListItem>
        <Text as="span" textStyle="bodyBold">
          For the performance of a contract:{" "}
        </Text>{" "}
        the development, compliance and undertaking of the purchase contract for
        the products, items or services You have purchased or of any other
        contract with Us through the Service.
      </ListItem>{" "}
      <ListItem>
        <Text as="span" textStyle="bodyBold">
          To contact You:
        </Text>{" "}
        To contact You by email, telephone calls, SMS, or other equivalent forms
        of electronic communication, such as a mobile application's push
        notifications regarding updates or informative communications related to
        the functionalities, products or contracted services, including the
        security updates, when necessary or reasonable for their implementation.
      </ListItem>{" "}
      <ListItem>
        <Text as="span" textStyle="bodyBold">
          To provide You
        </Text>{" "}
        other goods, services and events which we offer that are similar to
        those that you have already purchased or enquired about unless You have
        opted not to receive such information.
      </ListItem>{" "}
      <ListItem>
        <Text as="span" textStyle="bodyBold">
          To manage Your requests:{" "}
        </Text>{" "}
        To attend and manage Your requests to Us.
      </ListItem>{" "}
      <ListItem>
        <Text as="span" textStyle="bodyBold">
          For business transfers:{" "}
        </Text>{" "}
        We may use Your information to evaluate or conduct a merger,
        divestiture, restructuring, reorganization, dissolution, or other sale
        or transfer of some or all of Our assets, whether as a going concern or
        as part of bankruptcy, liquidation, or similar proceeding, in which
        Personal Data held by Us about our Service users is among the assets
        transferred.
      </ListItem>{" "}
      <ListItem>
        <Text as="span" textStyle="bodyBold">
          For other purposes:{" "}
        </Text>{" "}
        We may use Your information for other purposes, such as data analysis,
        identifying usage trends, determining the effectiveness of our
        promotional campaigns and to evaluate and improve our Service, products,
        services, marketing and your experience.
      </ListItem>{" "}
    </UnorderedList>
  );
};

export const ListEight = () => {
  return (
    <UnorderedList spacing="0.5rem">
      <ListItem>
        <Text as="span" textStyle="bodyBold">
          With Service Providers:{" "}
        </Text>{" "}
        We may share Your personal information with Service Providers to monitor
        and analyze the use of our Service, to contact You.{" "}
      </ListItem>{" "}
      <ListItem>
        <Text as="span" textStyle="bodyBold">
          For business transfers:{" "}
        </Text>{" "}
        We may share or transfer Your personal information in connection with,
        or during negotiations of, any merger, sale of Company assets,
        financing, or acquisition of all or a portion of Our business to another
        company.
      </ListItem>{" "}
      <ListItem>
        <Text as="span" textStyle="bodyBold">
          With Affiliates:
        </Text>{" "}
        We may share Your information with Our affiliates, in which case we will
        require those affiliates to honor this Privacy Policy. Affiliates
        include Our parent company and any other subsidiaries, joint venture
        partners or other companies that We control or that are under common
        control with Us.
      </ListItem>{" "}
      <ListItem>
        <Text as="span" textStyle="bodyBold">
          With business partners:{" "}
        </Text>{" "}
        We may share Your information with Our business partners to offer You
        certain products, services or promotions.
      </ListItem>{" "}
      <ListItem>
        <Text as="span" textStyle="bodyBold">
          With other users:{" "}
        </Text>{" "}
        when You share personal information or otherwise interact in the public
        areas with other users, such information may be viewed by all users and
        may be publicly distributed outside. If You interact with other users or
        register through a Third-Party Social Media Service, Your contacts on
        the Third-Party Social Media Service may see Your name, profile,
        pictures and description of Your activity. Similarly, other users will
        be able to view descriptions of Your activity, communicate with You and
        view Your profile.
      </ListItem>{" "}
      <ListItem>
        <Text as="span" textStyle="bodyBold">
          With Your consent:{" "}
        </Text>{" "}
        We may disclose Your personal information for any other purpose with
        Your consent.
      </ListItem>{" "}
    </UnorderedList>
  );
};

export const ListNine = () => {
  return (
    <UnorderedList spacing="0.5rem">
      <ListItem>Comply with a legal obligation</ListItem>
      <ListItem>
        Protect and defend the rights or property of the Company
      </ListItem>
      <ListItem>
        Prevent or investigate possible wrongdoing in connection with the
        Service
      </ListItem>
      <ListItem>
        Protect the personal safety of Users of the Service or the public
      </ListItem>
      <ListItem>Protect against legal liability</ListItem>
    </UnorderedList>
  );
};

export const ListTen = () => {
  return (
    <UnorderedList spacing="0.5rem">
      <ListItem>
        By email:{" "}
        <Text as="span" textStyle="bodyBold">
          {searchiveEmail}
        </Text>
      </ListItem>
      <ListItem>
        By visiting this page on our website:{" "}
        <Text
          as={Link}
          href={searchiveContact}
          isExternal
          color="limeText"
          textStyle="bodyMedium"
        >
          https://searchive.co.uk/contact
        </Text>
      </ListItem>
    </UnorderedList>
  );
};
