import { Flex, VStack } from "@chakra-ui/react";

export const SectionWrapper = ({ children }) => {
  return (
    <VStack alignItems="flex-start" gap="1.5rem" mb="1.875rem" color="darkGrey">
      {children}
    </VStack>
  );
};

export const ListWrapper = ({ children }) => {
  return (
    <Flex
      flexDirection="column"
      gap="1rem"
      alignItems="flex-start"
      justifyContent="center"
      color="darkGrey"
    >
      {children}
    </Flex>
  );
};
