import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Text, useToast } from '@chakra-ui/react';
import { subscribeNewsLetter } from '../../services/apiServices';

const NewsLetter = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast();

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("email", email);
        if (email.trim().length <= 0) {
            setMessage("Please enter email");
            return;
        }

        if (!validateEmail(email)) {
            setMessage("Please enter a valid email.");
            return;
        }
        // const dataCenter = process.env.REACT_APP_MAILCHIMP_DC;
        // const listId = process.env.REACT_APP_MAILCHIMP_LIST_ID;
        // // const segmentId = process.env.REACT_APP_MAILCHIMP_SEGMENT_ID;
        // const apiKey = process.env.REACT_APP_MAILCHIMP_API_KEY;

        // const url = `https://${dataCenter}.api.mailchimp.com/3.0/lists/${listId}/members`;
        // const config = {
        //     auth: {
        //         username: 'anystring',
        //         password: apiKey,
        //     }
        // };

        // const data = {
        //     email_address: email,
        //     status: "subscribed"
        // };

        // try {
        //     const response = await axios.post(url, data, config);
        //     if (response.status === 200) {
        //         setMessage('');
        //         setEmail('');
        //         toast({
        //             title: 'Success',
        //             description: "Newsletter Subscribed.",
        //             status: 'success',
        //             duration: 9000,
        //             isClosable: true,
        //         })
        //     } else {
        //         setMessage(' ');
        //         setEmail(' ');
        //         // setMessage('Subscription failed. Please try again.');
        //         toast({
        //             title: 'Error',
        //             description: "Newsletter Subscription failed. Please try again.",
        //             status: 'error',
        //             duration: 9000,
        //             isClosable: true,
        //         })

        //     }
        // } catch (error) {
        //     setMessage('');
        //     setEmail('');
        //     console.error('Error subscribing to newsletter:', error);
        //     toast({
        //         title: 'Error',
        //         description: "Subscription failed. Please try again.",
        //         status: 'error',
        //         duration: 9000,
        //         isClosable: true,
        //     })
        // }
        const passData = {
            email: email,
            status: "subscribed"
        }
        setIsLoading(true);
        subscribeNewsLetter(passData)
            .then((resp) => {
                setMessage('');
                setEmail('');
                console.log("resp", resp);
                if (resp.success) {
                    toast({
                        title: 'Success',
                        description: "Newsletter Subscribed.",
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    })
                } else {
                    toast({
                        title: 'Error',
                        description: "Newsletter Subscription failed. Please try again.",
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    })
                }
            })
            .catch((err) => {
                setMessage('');
                setEmail('');
                console.log("err", err?.response?.data?.message)
                toast({
                    title: 'Error',
                    description: err?.response?.data?.message ?? "Newsletter Subscription failed. Please try again.",
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            })
            .finally(function () {
                setIsLoading(false);
            })
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        if (message) {
            setMessage('');
        }
    };

    useEffect(() => {

    }, [])
    return (
        <div className="fancy-banner-two">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="title-one text-center text-lg-start">
                            <Text className='newsletter_title' fontSize="5xl">Join our newsletter</Text>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-wrapper me-auto ms-auto me-lg-0">
                            <form onSubmit={(e) => handleSubmit(e)}>
                                <input
                                    placeholder="Email address"
                                    className="rounded-0"
                                    value={email}
                                    onChange={handleEmailChange}
                                />
                                {/* <button className="rounded-0" type="submit">Get Started</button> */}
                                <Button
                                    type="submit"
                                    isLoading={isLoading}
                                    alt="Get Started"
                                    className="rounded-0"
                                >
                                    Get Started
                                </Button>
                            </form>
                            {message && <Text className='message' mt="2" style={{ color: "red" }}>{message}</Text>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewsLetter;
