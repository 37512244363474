import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import ClientCandidateChatContext from "../../context/ClientCandidateChatContext";

function LogoutPage() {
  const {
    authState: {
      user: { uuid, roles, membershipStatus, type },
    },
    logout,
  } = useContext(AuthContext);
  const { pusherObj, channelValue } = useContext(ClientCandidateChatContext);

  const isAdmin = roles.includes("ROLE_ADMIN");
  const isClientNotActivated = type === "type_client" && !membershipStatus;

  const navigate = useNavigate();

  useEffect(() => {
    if (!isAdmin && !isClientNotActivated) {
      pusherObj.unsubscribe(`private-messages${uuid}`);
      channelValue.unbind();
    }
    logout();
    navigate("/login", { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logout, navigate]);

  return null;
}

export default LogoutPage;
