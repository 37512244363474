import { Icon } from "@chakra-ui/react";

export const CustomPendingIcon = (props) => (
  <Icon viewBox="0 0 21 21" fill="none" {...props}>
    <path
      d="M10.4688 20.9584C10.1444 20.9584 9.88108 20.6959 9.88108 20.3725C9.88108 20.049 10.1444 19.7865 10.4688 19.7865C15.352 19.7865 19.3245 15.8263 19.3245 10.9582C19.3245 6.09014 15.352 2.12991 10.4688 2.12991C10.1444 2.12991 9.88108 1.8674 9.88108 1.54396C9.88108 1.22051 10.1444 0.958008 10.4688 0.958008C13.1483 0.958008 15.6674 1.99826 17.562 3.88697C19.4565 5.77568 20.5 8.28706 20.5 10.9582C20.5 13.6294 19.4565 16.1407 17.562 18.0294C15.6674 19.9182 13.1483 20.9584 10.4688 20.9584Z"
      fill={props.color ? props.color : "#A6A6A6"}
    />
    <path
      d="M15.1905 16.2513C15.0401 16.2513 14.8896 16.1943 14.7748 16.0798L10.0531 11.3727C9.94299 11.2629 9.88108 11.1137 9.88108 10.9582V5.07411C9.88108 4.75066 10.1444 4.48816 10.4688 4.48816C10.7933 4.48816 11.0566 4.75066 11.0566 5.07411V10.7156L15.6063 15.2513C15.8359 15.4802 15.8359 15.8513 15.6063 16.0798C15.4915 16.1943 15.341 16.2513 15.1905 16.2513Z"
      fill={props.color ? props.color : "#A6A6A6"}
    />
    <path
      d="M7.05394 2.76508C7.12643 2.76508 7.20049 2.7514 7.2718 2.72328C7.27376 2.7225 7.483 2.63929 7.70831 2.56937C7.9489 2.49437 8.23338 2.41429 8.23652 2.41351C8.54881 2.32561 8.73063 2.00178 8.64246 1.69044C8.5543 1.37911 8.22986 1.19786 7.91716 1.28575C7.90502 1.28926 7.61466 1.37091 7.35801 1.45099C7.08764 1.53536 6.84548 1.63146 6.83529 1.63576C6.53396 1.75607 6.38741 2.0967 6.5081 2.3971C6.60018 2.62601 6.82079 2.76508 7.05394 2.76508Z"
      fill={props.color ? props.color : "#A6A6A6"}
    />
    <path
      d="M4.68055 2.7889C4.67036 2.79633 4.42703 2.97446 4.21504 3.13891C3.9913 3.31235 3.79851 3.48697 3.79028 3.49439C3.55048 3.71236 3.53284 4.08268 3.75149 4.32175C3.86748 4.4487 4.02656 4.51316 4.18644 4.51316C4.3275 4.51316 4.46895 4.46277 4.5818 4.36081C4.58337 4.35925 4.7503 4.20846 4.93642 4.06393C5.13548 3.90963 5.37372 3.73502 5.37607 3.73345C5.63782 3.54204 5.69424 3.17524 5.50224 2.9143C5.31024 2.65335 4.9423 2.59749 4.68055 2.7889Z"
      fill={props.color ? props.color : "#A6A6A6"}
    />
    <path
      d="M2.99093 5.1577C2.71938 4.98036 2.35497 5.05614 2.17707 5.32685C2.17002 5.33739 2.00466 5.58896 1.86399 5.81709C1.71509 6.05772 1.59597 6.28859 1.59087 6.29835C1.44276 6.58625 1.55639 6.93899 1.84518 7.08704C1.93099 7.13118 2.02268 7.15188 2.1132 7.15188C2.32636 7.15188 2.53208 7.03587 2.63631 6.83391C2.63748 6.83196 2.74054 6.63234 2.86436 6.43195C2.9968 6.21788 3.15863 5.97139 3.1602 5.96866C3.3381 5.69795 3.26247 5.33505 2.99093 5.1577Z"
      fill={props.color ? props.color : "#A6A6A6"}
    />
    <path
      d="M1.03132 10.3434C1.05444 10.3461 1.07756 10.3473 1.10068 10.3473C1.39456 10.3473 1.64847 10.1277 1.68374 9.82967C1.68374 9.82733 1.71039 9.60662 1.75623 9.37381C1.80482 9.12732 1.86987 8.83981 1.87065 8.83668C1.94236 8.52105 1.74369 8.20738 1.42669 8.13589C1.11008 8.0644 0.795434 8.26245 0.723727 8.57809C0.720984 8.59059 0.654371 8.88395 0.602648 9.14685C0.54779 9.42381 0.517227 9.68201 0.516051 9.69256C0.478042 10.0141 0.708837 10.3055 1.03132 10.3434Z"
      fill={props.color ? props.color : "#A6A6A6"}
    />
    <path
      d="M1.84361 12.968C1.84322 12.9661 1.78993 12.75 1.75153 12.5157C1.71039 12.2676 1.67042 11.9754 1.67003 11.9727C1.62614 11.652 1.3303 11.4274 1.0082 11.4715C0.686502 11.5153 0.461585 11.8106 0.505471 12.1313C0.507039 12.1438 0.548182 12.4418 0.591677 12.7063C0.637522 12.9852 0.699825 13.2376 0.702176 13.2481C0.768006 13.5153 1.0082 13.6942 1.27231 13.6942C1.31894 13.6942 1.36596 13.6887 1.41337 13.677C1.7288 13.5997 1.9212 13.2821 1.84361 12.968Z"
      fill={props.color ? props.color : "#A6A6A6"}
    />
    <path
      d="M2.85065 15.4606C2.72526 15.2427 2.58498 14.9829 2.58341 14.9806C2.42981 14.6954 2.07323 14.5888 1.78719 14.7423C1.50114 14.8954 1.39417 15.2509 1.54816 15.536C1.55404 15.5474 1.69706 15.8122 1.83107 16.0443C1.97214 16.2892 2.11869 16.504 2.12495 16.513C2.23859 16.679 2.42315 16.7685 2.61084 16.7685C2.72487 16.7685 2.84007 16.7353 2.94155 16.6665C3.20957 16.4841 3.27893 16.1196 3.09594 15.8525C3.09476 15.8505 2.9682 15.6646 2.85065 15.4606Z"
      fill={props.color ? props.color : "#A6A6A6"}
    />
    <path
      d="M5.28203 18.1166C5.28203 18.1166 5.09629 17.9857 4.91448 17.8353C4.72013 17.6748 4.49795 17.4806 4.4956 17.4787C4.25148 17.2654 3.88002 17.2896 3.66607 17.5333C3.45212 17.7767 3.47681 18.147 3.72054 18.3603C3.72994 18.3685 3.95721 18.567 4.1641 18.7377C4.38236 18.9177 4.59513 19.0678 4.60414 19.074C4.70719 19.1463 4.82514 19.181 4.9423 19.181C5.12686 19.181 5.30867 19.0947 5.42309 18.9326C5.61 18.6681 5.5473 18.3029 5.28203 18.1166Z"
      fill={props.color ? props.color : "#A6A6A6"}
    />
    <path
      d="M8.12484 19.474C8.12249 19.4736 7.90698 19.417 7.68206 19.3392C7.44382 19.2568 7.16718 19.1525 7.16444 19.1513C6.86115 19.0369 6.52182 19.1892 6.40661 19.492C6.2918 19.7947 6.44462 20.133 6.7483 20.2475C6.76005 20.2518 7.04257 20.3584 7.29649 20.4463C7.56412 20.5389 7.81607 20.6049 7.82665 20.608C7.87641 20.6209 7.92657 20.6272 7.97594 20.6272C8.23652 20.6272 8.47476 20.4529 8.54411 20.1896C8.6264 19.8768 8.4391 19.556 8.12484 19.474Z"
      fill={props.color ? props.color : "#A6A6A6"}
    />
  </Icon>
);
