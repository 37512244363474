import { Icon } from "@chakra-ui/react";

export const CustomMobileFiltersIcon = (props) => (
  <Icon viewBox="0 0 44 45" fill="none" {...props}>
    <circle cx="22" cy="22.6953" r="22" fill="#CDEE24" />
    <g clipPath="url(#clip0_2352_15496)">
      <path
        d="M11.0347 17.1033H22.9372C23.2655 18.4298 24.4655 19.4164 25.8921 19.4164C27.3186 19.4164 28.5186 18.4298 28.8469 17.1033H32.9653C33.369 17.1033 33.6964 16.776 33.6964 16.3723C33.6964 15.9686 33.369 15.6413 32.9653 15.6413H28.8468C28.5186 14.3147 27.3186 13.3281 25.892 13.3281C24.4654 13.3281 23.2654 14.3147 22.9372 15.6413H11.0347C10.631 15.6413 10.3036 15.9686 10.3036 16.3723C10.3036 16.776 10.631 17.1033 11.0347 17.1033ZM25.8921 14.7902C26.7644 14.7902 27.4742 15.4999 27.4742 16.3722C27.4742 17.2446 26.7644 17.9544 25.8921 17.9544C25.0197 17.9544 24.3099 17.2446 24.3099 16.3722C24.3099 15.4999 25.0197 14.7902 25.8921 14.7902ZM11.0347 23.4261H15.1531C15.4815 24.7526 16.6814 25.7392 18.108 25.7392C19.5346 25.7392 20.7345 24.7526 21.0628 23.4261H32.9653C33.369 23.4261 33.6964 23.0988 33.6964 22.6951C33.6964 22.2914 33.369 21.9641 32.9653 21.9641H21.0628C20.7345 20.6375 19.5345 19.6509 18.1079 19.6509C16.6814 19.6509 15.4814 20.6375 15.1531 21.9641H11.0347C10.631 21.9641 10.3036 22.2914 10.3036 22.6951C10.3036 23.0988 10.6309 23.4261 11.0347 23.4261ZM18.1079 21.113C18.9803 21.113 19.6901 21.8227 19.6901 22.6951C19.6901 23.5674 18.9803 24.2772 18.1079 24.2772C17.2356 24.2772 16.5258 23.5674 16.5258 22.6951C16.5258 21.8227 17.2356 21.113 18.1079 21.113ZM32.9653 28.2869H28.8468C28.5185 26.9603 27.3186 25.9737 25.892 25.9737C24.4654 25.9737 23.2655 26.9603 22.9372 28.2869H11.0347C10.631 28.2869 10.3036 28.6142 10.3036 29.0179C10.3036 29.4216 10.631 29.7489 11.0347 29.7489H22.9372C23.2655 31.0754 24.4655 32.062 25.8921 32.062C27.3186 32.062 28.5186 31.0754 28.8469 29.7489H32.9653C33.369 29.7489 33.6964 29.4216 33.6964 29.0179C33.6964 28.6142 33.3691 28.2869 32.9653 28.2869ZM25.8921 30.6C25.0197 30.6 24.3099 29.8903 24.3099 29.0179C24.3099 28.1455 25.0197 27.4358 25.8921 27.4358C26.7644 27.4358 27.4742 28.1455 27.4742 29.0179C27.4742 29.8903 26.7644 30.6 25.8921 30.6Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_2352_15496">
        <rect
          width="23.3927"
          height="23.3927"
          fill="white"
          transform="translate(10.3036 11)"
        />
      </clipPath>
    </defs>
  </Icon>
);
