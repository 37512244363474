import {
  HStack,
  Flex,
  Center,
  Link,
  Image,
  Spacer,
  Button,
  useMediaQuery,
} from "@chakra-ui/react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import logoUrl from "../../../../assets/vectors/logo.svg";
import SidebarNavigation from "./SidebarNavigation";

const HeaderNavigation = () => {
  const location = useLocation();

  const [isLargerThanLG] = useMediaQuery("(min-width: 62em)");

  const navList = [
    {
      link: "/clients",
      title: "For Companies",
    },
    {
      link: "/candidates",
      title: "For Candidates",
    },
    // {
    //   link: "/membership",
    //   title: "Pricing for companies",
    // },
    {
      link: "/faq",
      title: "FAQ",
    },
    // {
    //   link: "https://freetrial.searchive.co.uk/#join-the-hive",
    //   title: "Free trial",
    // },
  ];

  if (!isLargerThanLG) {
    return <SidebarNavigation navLinksList={navList} />;
  }

  return (
    <Flex
      position="absolute"
      top="0"
      left="0"
      bgColor="#FFFFFF"
      w="full"
      px="3.75rem"
      zIndex="6"
      borderBottom="1px solid #EAE8E8"
      overflow="hidden"
    >
      <Center flexShrink="0">
        <Link as={RouterLink} to="/" py="1.1rem" pr="2rem">
          <Image src={logoUrl} w="8.8125rem" />
        </Link>
      </Center>

      <Spacer />

      <HStack gap="2rem">
        {navList.map((item, ind) => {
          return (
            <Link
              as={item.title === "Free trial" ? Link : RouterLink}
              key={ind}
              to={item.title === "Free trial" ? null : item.link}
              href={
                item.title === "Free trial"
                  ? "https://freetrial.searchive.co.uk/#join-the-hive"
                  : null
              }
              isExternal={item.title === "Free trial" ? true : null}
              fontFamily="Plus Jakarta Sans Bold, sans-serif"
              fontSize="1rem"
              borderBottom={
                location.pathname === item.link
                  ? "1px solid #CDEE24"
                  : "1px solid transparent"
              }
              className={location.pathname !== item.link && "animateLink"}
              _hover={{ textDecoration: "none" }}
              whiteSpace="nowrap"
            >
              {item.title}
            </Link>
          );
        })}
        <HStack gap="1.375rem">
          <Link
            as={RouterLink}
            to="/login"
            fontFamily="Plus Jakarta Sans Bold, sans-serif"
            fontSize="1rem"
            color="limeText"
            className="animateLink"
            _hover={{ textDecoration: "none" }}
          >
            Log in
          </Link>
          <Button as={RouterLink} to="/get-started" size="sm">
            Get started
          </Button>
        </HStack>
      </HStack>
    </Flex>
  );
};

export default HeaderNavigation;
