import { Box, Image, useMediaQuery } from "@chakra-ui/react";
import blackLinesSrc from "../../../../assets/vectors/blackLines.svg";
import ringLime from "../../../../assets/vectors/ringLime.svg";
import ringBlack from "../../../../assets/vectors/ringBlack.svg";
import elipseLime from "../../../../assets/vectors/elipseLime.svg";

const PseudoElementsTypeOne = () => {
  const [isLargerThanMD] = useMediaQuery("(min-width: 48em)");
  const [isSmallerThanMD] = useMediaQuery("(max-width: 30em)");
  if (isSmallerThanMD) return null;
  return (
    <Box w="full" position="absolute" top="0" left="0">
      {isLargerThanMD && (
        <Image
          src={blackLinesSrc}
          loading="lazy"
          position="absolute"
          width="21rem"
          top={{ base: "0", sm: "0" }}
          left={{ base: "0", sm: "0" }}
          transform="rotate(180deg)"
          objectFit="cover"
        />
      )}

      <Image
        src={ringLime}
        loading="lazy"
        width="2.375rem"
        position="absolute"
        top={{ base: "1rem", sm: "1.5rem" }}
        left="6rem"
        objectFit="cover"
      />
      <Image
        src={ringBlack}
        loading="lazy"
        width="1.6875rem"
        position="absolute"
        top="0"
        left="13rem"
        objectFit="cover"
      />
      <Image
        src={elipseLime}
        loading="lazy"
        width="4rem"
        position="absolute"
        top="12rem"
        left="-1rem"
        transform="rotate(-90deg)"
        objectFit="cover"
      />
    </Box>
  );
};

export default PseudoElementsTypeOne;
