import { Box, Image } from "@chakra-ui/react";
import blackLinesFullSrc from "../../../../assets/vectors/blackLinesFull.svg";
import elipseLimeFullSrc from "../../../../assets/vectors/elipseLimeFull.svg";

const PageHeaderLeftPseudoElementsTypeTwo = (props) => {
  return (
    <Box w="full" position="absolute" top="3rem" left="0">
      <Image
        src={elipseLimeFullSrc}
        loading="lazy"
        maxW="3rem"
        position="absolute"
        top={{ base: "2rem", sm: "5rem" }}
        left="3rem"
      />
      <Image
        src={blackLinesFullSrc}
        loading="lazy"
        position="absolute"
        width="500px"
        top={{ base: "-80px", sm: "-120px" }}
        left={{ base: "-100px", sm: "-160px" }}
        transform="rotate(120deg)"
      />
    </Box>
  );
};

export default PageHeaderLeftPseudoElementsTypeTwo;
