import {
  Flex,
  Link,
  Image,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
  Hide,
  Heading,
  Box,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import {
  CustomEmptyProfileIcon,
  CustomDropdownIcon,
  CustomLogoutIcon,
} from "../../../../assets/icons";
import logoUrl from "../../../../assets/vectors/logo.svg";
import logoSymbolUrl from "../../../../assets/vectors/logoSymbol.svg";

const HeaderNavigation = () => {
  const [isLargerThan480px] = useMediaQuery("(min-width: 480px)");

  return (
    <Flex
      as="header"
      w="full"
      borderBottom="1px solid #EAE8E8"
      alignItems="center"
      justifyContent="space-between"
      p={{ base: "1rem 1rem", lg: "1.5rem 3.75rem" }}
      position="absolute"
      top="0"
      left="0"
      bg="white"
      zIndex={1}
    >
      <Link as={RouterLink} to="/">
        <Image
          src={isLargerThan480px ? logoUrl : logoSymbolUrl}
          loading="lazy"
          boxSize={{base: "2.5rem", sm: "auto"}}
        />
      </Link>
      <Menu>
        <MenuButton>
          <Flex justifyContent="center" alignItems="center" gap="0.625rem">
            <CustomEmptyProfileIcon boxSize="2.625rem" />
            <Hide below="sm">
              <Heading fontSize="1rem">My profile</Heading>
            </Hide>
            <CustomDropdownIcon />
          </Flex>
        </MenuButton>
        <MenuList border="10px" minWidth="166px">
          <Box className="myProfileMenuTriangle" />

          <MenuItem
            as={RouterLink}
            to="/logout"
            borderTopRightRadius={"10px"}
            borderTopLeftRadius={"10px"}
            borderBottomRightRadius="10px"
            borderBottomLeftRadius="10px"
            p="1rem"
          >
            <Flex justifyContent="center" alignItems="center" gap="0.625rem">
              <CustomLogoutIcon boxSize="16px" />
              <Text fontSize="0.875rem">Log out</Text>
            </Flex>
          </MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  );
};

export default HeaderNavigation;
