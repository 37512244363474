import { Flex } from "@chakra-ui/react";
import Footer from "../../components/public/candidates/Footer";
import Landing from "../../components/public/candidates/Landing";
import ThreeSymbolsSection from "../../components/public/shared/ThreeSymbolsSection";
import NumberedSection from "../../components/public/home/NumberedSection";
import whySecX3Src from "../../assets/images/public/clients/why_x3.webp";
import whySecFallbacksrc from "../../assets/images/public/clients/why_x3.png";
import registerSecX3Src from "../../assets/images/public/clients/register_x3.webp";
import registerSecFallbacksrc from "../../assets/images/public/clients/register_x3.png";
import sitBackSecX1Src from "../../assets/images/public/clients/sit_back_x1.webp";
import sitBackSecX3Src from "../../assets/images/public/clients/sit_back_x3.webp";
import sitBackSecFallbacksrc from "../../assets/images/public/clients/sit_back_x3.png";
import talkSecX3Src from "../../assets/images/public/clients/talk_x3.webp";
import talkSecFallbacksrc from "../../assets/images/public/clients/talk_x3.png";

const CandidatesPage = () => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      w="full"
      minH="100vh"
      overflow="hidden"
    >
      <Landing />
      <NumberedSection
        bgColor="backgroundGrey"
        imageBlockSize="27.875rem"
        textBlockSize="36.3125rem"
        gap={{ base: "3rem", md: "5rem", xl: "7.5rem" }}
        stepNumber=""
        imageFirst
        lineOneText="why"
        noCircle={true}
        bodyText="A modern approach to finding your next role. Making your job search painless, you are in control. No third party recruiters ever involved. Choose a company that works for you."
        separatorText="job search painless,"
        srcX1={whySecX3Src}
        srcX2={whySecX3Src}
        srcX3={whySecX3Src}
        fallbackSrc={whySecFallbacksrc}
        hasBottomElement
        pseudoElementsTypeThree
      />
      <ThreeSymbolsSection
        heading1="Easy"
        description1="Join for free. Sit back and wait to be approached by market leading organisations."
        heading2="Relevant"
        description2="You choose the roles you hear about, at the companies you like, and at the salary you specify."
        heading3="Secure"
        description3="With our unique AI your confidentiality is assured. A more controlled way to put yourself out there."
        maxTextW="20rem"
      />
      <NumberedSection
        bgColor="white"
        imageBlockSize="41.4375rem"
        textBlockSize="31.5625rem"
        gap={{ base: "3rem", md: "5rem", xl: "5.5rem" }}
        stepNumber="01"
        lineOneText="Register for free"
        bodyText="Fill out our candidate form, upload your CV and one of our friendly team will be in touch."
        srcX1={registerSecX3Src}
        srcX2={registerSecX3Src}
        srcX3={registerSecX3Src}
        fallbackSrc={registerSecFallbacksrc}
        pseudoElementsTypeFour
      />
      <NumberedSection
        bgColor="backgroundGrey"
        imageBlockSize="52rem"
        textBlockSize={{ base: "25.1875rem", "2xl": "30.875rem" }}
        gap={{ base: "3rem", md: "4.375rem", "2xl": "9.3125rem" }}
        stepNumber="02"
        imageFirst
        lineOneText="Sit back"
        bodyText="Once your profile is complete you can sit back and relax while being approached by some of the world’s leading businesses."
        srcX1={sitBackSecX1Src}
        srcX2={sitBackSecX3Src}
        srcX3={sitBackSecX3Src}
        fallbackSrc={sitBackSecFallbacksrc}
      />
      <NumberedSection
        bgColor="white"
        imageBlockSize="47.875rem"
        textBlockSize={{ base: "25.1875rem", "2xl": "35.3125rem" }}
        gap={{ base: "3rem", md: "5rem", xl: "6.0625rem" }}
        stepNumber="03"
        lineOneText="talk directly"
        bodyText="Enjoy direct interaction with hiring managers making it easy to accept or reject potential roles. Real-time messaging, no middleman, putting you in control."
        srcX1={talkSecX3Src}
        srcX2={talkSecX3Src}
        srcX3={talkSecX3Src}
        fallbackSrc={talkSecFallbacksrc}
      />
      <Footer />
    </Flex>
  );
};

export default CandidatesPage;
