import { Box, Image, useMediaQuery } from "@chakra-ui/react";
import ringLimeSrc from "../../../../assets/vectors/ringLime.svg";
import triangleDotSrc from "../../../../assets/vectors/triangleDot.svg";
import elipseLimeFullSrc from "../../../../assets/vectors/elipseLimeFull.svg";

const PageHeaderRightPseudoElementsTypeTwo = (props) => {
  const [isLargerThanMD] = useMediaQuery("(min-width: 48em)");
  const [isLargerThanLG] = useMediaQuery("(min-width: 62em)");
  return (
    <Box w="full" position="absolute" top="0" right="0">
      <Image
        src={ringLimeSrc}
        loading="lazy"
        w="6.5625rem"
        h="auto"
        position="absolute"
        top="1.5rem"
        right="-3rem"
        objectFit="cover"
      />
      {isLargerThanLG && (
        <Image
          src={triangleDotSrc}
          loading="lazy"
          width="11.125rem"
          position="absolute"
          right="-4.3rem"
          top="7rem"
          transform="rotate(-20deg)"
          objectFit="cover"
        />
      )}
      {isLargerThanMD && (
        <Image
          src={elipseLimeFullSrc}
          loading="lazy"
          width="8.5625rem"
          h="auto"
          position="absolute"
          top="-2rem"
          right="30vw"
          objectFit="cover"
        />
      )}
    </Box>
  );
};

export default PageHeaderRightPseudoElementsTypeTwo;
