import colors from "../../../theme/foundations/colors";
import Select, { components } from "react-select";
import { Flex, useMediaQuery } from "@chakra-ui/react";
import { CustomReactSelectDropdownIndicator } from "../../../assets/icons";

// custom components
const DropdownIndicatorCustom = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <CustomReactSelectDropdownIndicator
        boxSize="0.6rem"
        _hover={{ cursor: "pointer" }}
      />
    </components.DropdownIndicator>
  );
};

const OptionCustom = (props) => {
  const { data } = props;
  const onClick = (e) => {
    props.selectOption({ ...data });
    e.stopPropagation();
    e.preventDefault();
  };

  return <components.Option {...props} />;
};

// to hijack rendering the menu on every select, we create a custom component
const MenuListCustom = ({ ...props }) => (
  <components.MenuList {...props}>{props.children}</components.MenuList>
);

const HearAboutUsSelectInput = (props) => {
  const [isSmallerThan480px] = useMediaQuery("(max-width: 480px)");
  const [isSmallerThan768px] = useMediaQuery("(max-width: 768px)");

  const reactSelectComponentStyle = {
    container: (provided, state) => {
      return {
        ...provided,
        width: "100%",
      };
    },
    control: (provided, state) => {
      return {
        ...provided,
        padding: "0 0.5rem",
        minHeight: "2.5rem", // 40px
        borderRadius: "1.875rem",
        borderColor: state.isFocused
          ? colors.electricLime
          : props.errorBorder
            ? colors.error
            : colors.semiDarkGrey,
        backgroundColor: "white",
        boxShadow: "none",
        ":hover": {
          borderColor: colors.electricLime,
          transition: "all .2s",
          cursor: "pointer",
        },
      };
    },
    placeholder: (provided, state) => ({
      ...provided,
      color: "#bcbcbc",
      fontSize: "1rem",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    }),

    indicatorSeparator: (provided, state) => ({
      display: "none",
    }),

    dropdownIndicator: (provided, state) => {
      return {
        ...provided,
        transform: state.selectProps.menuIsOpen ? "" : "rotate(180deg)",
        transition: "color 150ms, transform 150ms",
      };
    },

    menu: (provided, state) => ({
      ...provided,
      borderRadius: "6px",
      border: colors.fieldStroke,
      boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
      width: isSmallerThan480px ? "18rem" : "23rem",
      zIndex: 5,
      //   -ms-overflow-style: none;
      ".menu-btn": {
        backgroundColor: "transparent",
        padding: "1rem",
        color: colors.limeText,
        display: "block",
        ":hover": {
          color: colors.darkGrey,
          backgroundColor: "transparent",
        },
      },
    }),

    option: (provided, state) => {
      return {
        ...provided,
        color: "inherit",
        padding: "0.7rem 1rem",
        wordBreak: "break-word",
        backgroundColor:
          !props.isMulti && state.isSelected ? colors.electricLime : "inherit",
        transition: "all 0.2s",
        ":hover": {
          backgroundColor:
            !props.isMulti && state.isSelected
              ? colors.electricLime
              : colors.backgroundGrey,
        },
      };
    },
    menuList: (provided, state) => {
      const moreThan6Options = props?.options?.length > 6;
      return {
        ...provided,
        padding: "0px",
        height:
          isSmallerThan480px && moreThan6Options ? "400px" : "fit-content",
        maxHeight: isSmallerThan480px ? "500px" : "300px",
      };
    },
    clearIndicator: (provided, state) => {
      return {
        ...provided,
        color: colors.mediumGrey,
        paddingRight: "0",
        cursor: "pointer",
        ":hover": {
          color: colors.mediumGrey,
          opacity: "0.8",
        },
      };
    },
  };

  const customComponents = {
    MenuList: MenuListCustom,
    Option: OptionCustom,
    DropdownIndicator: DropdownIndicatorCustom,
  };

  const sourceList = [
    {
      value: "LinkedIn",
      label: "LinkedIn",
    },
    {
      value: "Google Search",
      label: "Google Search",
    },
    {
      value: "Word of Mouth",
      label: "Word of Mouth",
    },
    {
      value: "Reed",
      label: "Reed",
    },
    {
      value: "Facebook",
      label: "Facebook",
    },
    {
      value: "Instagram",
      label: "Instagram",
    },
    {
      value: "Other",
      label: "Other",
    },
  ];

  return (
    <Select
      {...props}
      options={sourceList}
      placeholder={"Select the source"}
      noOptionsMessage={() => ""}
      styles={reactSelectComponentStyle}
      components={customComponents}
    />
  );
};

export default HearAboutUsSelectInput;
