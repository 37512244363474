import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Link,
  Text,
  VStack,
  useMediaQuery,
  HStack,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { CustomLetterIcon } from "../../assets/icons";
import { useMutation } from "@tanstack/react-query";
import FormSuccessfulySent from "../../components/public/shared/FormSuccessfulySent";
import { sendContactMessageFn } from "../../services/apiServices";
import { getErrorResponsePayload } from "../../utils/helpers";
import { CustomTextarea } from "../../components/private/shared/UI/CustomTextarea";
import HearAboutUsSelectInput from "../../components/public/shared/HearAboutUsSelectInput";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Please enter your full name"),
  email: Yup.string()
    .required("Please enter your email address")
    .email("Please enter a valid email address"),
  phoneNumber: Yup.string().required("Please enter your phone number"),
  hearAboutUs: Yup.string().required("Please choose one option"),
  message: Yup.string().required("Please enter your message"),
});

const formOptions = {
  resolver: yupResolver(validationSchema),
  mode: "onBlur",
};

function ContactUsPage() {
  const [isLargerThanMD] = useMediaQuery("(min-width: 48em)");
  const textareaRows = isLargerThanMD ? "7" : "5";
  const [successfulMessageSend, setSuccessfulMessageSend] = useState(false);

  const [sourceField, setSourceField] = useState(null);

  useEffect(() => {
    if (sourceField !== null) {
      setValue("hearAboutUs", sourceField.value);
      clearErrors("hearAboutUs");
    }
  }, [sourceField]);

  // const sourceFieldErrorHandler = () => {
  //   if (!sourceField) {
  //     setError("hearAboutUs", "Please enter notice period");
  //   } else {
  //     clearErrors("hearAboutUs");
  //   }
  // };

  const sourceOnChangeHandler = (item) => {
    setSourceField(item);
    clearErrors("hearAboutUs");
  };
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty, isValid },
    setError,
    setValue,
    clearErrors,
  } = useForm(formOptions);

  const { mutate, isLoading } = useMutation({
    mutationFn: (data) => sendContactMessageFn(data),
    onSuccess: (data) => {
      setSuccessfulMessageSend(true);
    },
    onError: (error) => {
      const { errors } = getErrorResponsePayload(error);

      if (errors?.name?.errors) {
        const message = errors.name.errors[0];
        setError("name", { type: "server", message });
      }
      if (errors?.email?.errors) {
        const message = errors.email.errors[0];
        setError("email", { type: "server", message });
      }
      if (errors?.phoneNumber?.errors) {
        const message = errors.phoneNumber.errors[0];
        setError("phoneNumber", { type: "server", message });
      }
      if (errors?.message?.errors) {
        const message = errors.message.errors[0];
        setError("message", { type: "server", message });
      }
    },
  });

  const onSubmit = async (data) => {
    mutate(data);
  };

  if (successfulMessageSend) return <FormSuccessfulySent form="contact" />;

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <VStack spacing={8} alignItems="flex-start">
        <Heading
          as="h1"
          alignSelf={{ base: "center", sm: "flex-start" }}
          fontSize={{ base: "2.5rem", sm: "3.125rem" }} //50px
          lineHeight={{ base: "3rem", sm: "3.9rem" }} //63px
          mb="0.5rem"
        >
          Contact us
        </Heading>

        <FormControl isRequired isInvalid={errors.name}>
          <FormLabel
            textStyle="bodySemiBold"
            fontWeight="600"
            htmlFor="name"
            requiredIndicator=""
            mb="0.5rem"
          >
            Full name
          </FormLabel>
          <Input
            id="name"
            type="text"
            placeholder="Enter your full name"
            {...register("name")}
          />
          <FormErrorMessage fontSize="12px">
            {errors.name?.message || errors.name}
          </FormErrorMessage>
        </FormControl>

        <FormControl isRequired isInvalid={errors.email}>
          <FormLabel
            textStyle="bodySemiBold"
            fontWeight="600"
            htmlFor="email"
            requiredIndicator=""
            mb="0.5rem"
          >
            Email address
          </FormLabel>
          <Input
            id="email"
            name="email"
            type="email"
            placeholder="Enter your email address"
            {...register("email")}
          />
          <FormErrorMessage fontSize="12px">
            {errors.email?.message || errors.email}
          </FormErrorMessage>
        </FormControl>

        <FormControl isRequired isInvalid={errors.phoneNumber}>
          <FormLabel
            textStyle="bodySemiBold"
            fontWeight="600"
            htmlFor="phoneNumber"
            requiredIndicator=""
            mb="0.5rem"
          >
            Phone number
          </FormLabel>
          <Input
            id="phoneNumber"
            type="number"
            placeholder="Enter your phone number"
            {...register("phoneNumber")}
          />
          <FormErrorMessage fontSize="12px">
            {errors.phoneNumber?.message || errors.phoneNumber}
          </FormErrorMessage>
        </FormControl>
        <FormControl isRequired isInvalid={errors.hearAboutUs}>
          <FormLabel
            textStyle="bodySemiBold"
            fontWeight="600"
            htmlFor="hearAboutUs"
            requiredIndicator=""
            mb="0.5rem"
          >
            How did you hear about us?
          </FormLabel>
          <Input type="hidden" {...register("hearAboutUs")} />
          <HearAboutUsSelectInput
            onChange={sourceOnChangeHandler}
            value={sourceField}
            errorBorder={errors.hearAboutUs}
            isSearchable={false}
            blurInputOnSelect={false}
          //onBlur={sourceFieldErrorHandler}
          />
          <FormErrorMessage fontSize="12px">
            {errors.hearAboutUs?.message || errors.hearAboutUs}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={errors.message} mt={{ base: "-10px", sm: 0 }}>
          <FormLabel
            textStyle="bodySemiBold"
            fontWeight="600"
            htmlFor="message"
            mb="0.5rem"
          >
            Message
          </FormLabel>
          <CustomTextarea
            placeholder="Enter your message"
            id="message"
            type="text"
            minRows={textareaRows}
            resize="none"
            rows={4}
            {...register("message")}
          />
          <FormErrorMessage fontSize="12px">
            {errors.message?.message || errors.message}
          </FormErrorMessage>
        </FormControl>

        <Button
          type="submit"
          w="full"
          isLoading={isSubmitting || isLoading}
          disabled={!isDirty || !isValid || isSubmitting || isLoading}
          alt="Send message"
        >
          Contact us
        </Button>

        <Link
          href="mailto:hello@searchive.co.uk"
          color="darkGrey"
          _hover={{ color: "limeText" }}
          w="full"
          pt={{ base: "0.8125rem" }}
        >
          <HStack justifyContent="center" w="full">
            <CustomLetterIcon w="1.5625rem" />
            <Text fontSize="0.875rem">hello@searchive.co.uk</Text>
          </HStack>
        </Link>
      </VStack>
    </form>
  );
}

export default ContactUsPage;
