import { Icon } from "@chakra-ui/react";

export const CustomLinesIcon = (props) => (
  <Icon viewBox="0 0 509 277" fill="none" {...props}>
    <path
      d="M104.203 0.671965L103.835 2.67402C142.068 9.76671 181.637 17.104 219.133 29.5966C261.064 43.5517 295.571 62.6547 321.803 86.4188C328.239 92.2535 334.432 98.5733 340.381 104.711C347.788 112.307 355.438 120.145 363.636 127.136C404.626 161.965 459.447 176.059 507.764 188.446L508.253 186.444C460.118 174.118 405.541 160.146 364.915 125.56C356.778 118.63 349.128 110.852 341.782 103.256C335.771 97.1187 329.579 90.7383 323.082 84.9035C296.668 60.9571 261.857 41.6717 219.682 27.6555C182.127 15.1021 142.496 7.76474 104.203 0.671965Z"
      fill="black"
    />
    <path
      d="M83.206 16.1778L82.8384 18.1799C121.071 25.2726 160.64 32.6099 198.135 45.1024C240.067 59.0576 274.574 78.1606 300.806 101.925C307.242 107.759 313.435 114.079 319.384 120.217C326.791 127.812 334.441 135.651 342.639 142.641C383.629 177.471 438.45 191.565 486.767 203.952L487.256 201.95C439.121 189.624 384.544 175.651 343.918 141.065C335.78 134.136 328.131 126.358 320.785 118.762C314.774 112.625 308.582 106.244 302.084 100.409C275.731 76.463 240.981 57.1777 198.807 43.1615C161.129 30.608 121.499 23.2706 83.206 16.1778Z"
      fill="black"
    />
    <path
      d="M62.3879 34.1113L62.0203 36.1134C100.253 43.2061 139.822 50.5434 177.317 63.036C219.249 76.9911 253.756 96.0941 279.987 119.858C286.424 125.693 292.617 132.013 298.566 138.15C305.973 145.746 313.623 153.585 321.821 160.575C362.811 195.404 417.632 209.499 465.949 221.885L466.438 219.883C418.303 207.558 363.726 193.585 323.1 158.999C314.962 152.07 307.313 144.291 299.967 136.696C293.956 130.558 287.764 124.178 281.266 118.343C254.852 94.3965 220.042 75.111 177.867 61.0948C140.311 48.5415 100.681 41.2041 62.3879 34.1113Z"
      fill="black"
    />
    <path
      d="M43.0855 55.1418L42.7179 57.1439C80.9502 64.2366 120.52 71.5739 158.015 84.0664C199.946 98.0216 234.453 117.125 260.685 140.889C267.122 146.723 273.314 153.043 279.264 159.181C286.671 166.776 294.32 174.615 302.518 181.605C343.509 216.435 398.329 230.469 446.647 242.855L447.136 240.853C399.001 228.528 344.423 214.555 303.797 179.969C295.66 173.039 288.01 165.261 280.664 157.665C274.654 151.528 268.461 145.147 261.964 139.313C235.55 115.366 200.739 96.0808 158.565 82.0646C121.009 69.572 81.3786 62.2346 43.0855 55.1418Z"
      fill="black"
    />
    <path
      d="M22.0884 70.6477L21.7208 72.6498C59.9531 79.7425 99.5227 87.0798 137.018 99.5723C178.949 113.527 213.456 132.63 239.688 156.395C246.124 162.229 252.317 168.549 258.267 174.686C265.674 182.282 273.323 190.121 281.521 197.111C322.512 231.941 377.332 245.974 425.65 258.361L426.139 256.359C378.004 244.033 323.426 230.061 282.8 195.475C274.663 188.545 267.013 180.767 259.667 173.171C253.657 167.034 247.464 160.653 240.967 154.819C214.553 130.872 179.742 111.587 137.568 97.5705C100.012 85.0779 60.3815 77.7405 22.0884 70.6477Z"
      fill="black"
    />
    <path
      d="M1.27034 88.5812L0.902762 90.5833C39.1351 97.676 78.7046 105.013 116.2 117.506C158.131 131.461 192.638 150.564 218.87 174.328C225.306 180.163 231.499 186.483 237.449 192.62C244.855 200.216 252.505 208.055 260.703 215.045C301.694 249.874 356.514 263.908 404.892 276.294L405.382 274.292C357.246 261.967 302.669 247.994 262.043 213.408C253.906 206.479 246.256 198.701 238.91 191.105C232.899 184.967 226.707 178.587 220.21 172.752C193.796 148.806 158.985 129.52 116.811 115.504C79.1938 103.011 39.5635 95.674 1.27034 88.5812Z"
      fill="black"
    />
  </Icon>
);
