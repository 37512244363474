import { Icon } from "@chakra-ui/react";

export const CustomLogoSymbolIcon = (props) => (
  <Icon viewBox="0 0 34 35" fill="none" {...props}>
    <path
      d="M12.6905 29.707H21.2267V34.1718C19.8742 34.5168 18.4595 34.7031 17.0035 34.7031C15.5474 34.7031 14.0637 34.5099 12.6905 34.1511V29.707Z"
      fill={props.color ? props.color : "white"}
    />
    <path
      d="M17.0035 0.703125C7.61153 0.703125 0 8.31465 0 17.7066C0 23.8206 3.22955 29.1756 8.06698 32.1705V18.4312C8.06698 17.1545 9.10209 16.1194 10.3787 16.1194C11.0136 16.1194 11.5933 16.3747 12.0073 16.7957C12.4283 17.2097 12.6836 17.7894 12.6836 18.4312V25.5596H21.2198V14.7393C21.2198 13.4626 22.2549 12.4275 23.5316 12.4275C24.8082 12.4275 25.8433 13.4626 25.8433 14.7393V32.2258C30.7359 29.2446 34 23.8551 34 17.6997C34 8.31465 26.3885 0.703125 17.0035 0.703125ZM10.358 15.036C8.86056 15.036 7.59773 13.8767 7.59773 12.4137C7.59773 10.9507 8.86056 9.81902 10.358 9.81902C11.8555 9.81902 13.1183 10.9507 13.1183 12.4137C13.1183 13.8767 11.89 15.036 10.358 15.036ZM23.642 11.282C22.1445 11.282 20.8817 10.1227 20.8817 8.65969C20.8817 7.19673 22.1445 6.07191 23.642 6.07191C25.1394 6.07191 26.4023 7.20363 26.4023 8.65969C26.4023 10.1157 25.167 11.282 23.642 11.282Z"
      fill={props.color ? props.color : "white"}
    />
  </Icon>
);
