import { Box, Heading } from "@chakra-ui/react";
import { FOR_COMPANIES, FOR_CANDIDATES } from "../../../utils/faqData";
import FaqAccordionItem from "./FaqAccordionItem";

const FaqAccordionList = ({ listType }) => {
  const title = listType === "company" ? "For Companies" : "For Candidates";
  const list = listType === "company" ? FOR_COMPANIES : FOR_CANDIDATES;
  return (
    <Box maxWidth={{ base: "46.875rem" }} width="100%">
      <Heading
        fontSize={{ base: "1.5rem", lg: "1.875rem" }}
        lineHeight={{ base: "2.375rem" }}
        textAlign="left"
        mb={{ base: "1.5rem", lg: "2rem" }}
      >
        {title}
      </Heading>
      {list.map((item, ind) => {
        return (
          <FaqAccordionItem
            key={ind}
            question={item.question}
            answer={item.answer}
            addition={item?.addition}
          />
        );
      })}
    </Box>
  );
};

export default FaqAccordionList;
