import { useNavigate, useParams } from "react-router";
import { useContext, useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { verifyEmailFn } from "../../services/apiServices";
import { AuthContext } from "../../context/AuthContext";
import { getErrorResponsePayload } from "../../utils/helpers";
import Loading from "../../components/shared/Loading";
import CustomToast from "../../components/private/shared/UI/CustomToast";
import { useToast } from "@chakra-ui/react";

function VerifyingYourEmail() {
  const navigate = useNavigate();
  const { confirmationToken: token } = useParams();
  const { setAuthState } = useContext(AuthContext);
  const toast = useToast();

  const { mutate: verifyEmail } = useMutation({
    mutationFn: () => verifyEmailFn(token),
    onSuccess: (response) => {
      const { token, refresh_token: refreshToken, user } = response?.data;
      setAuthState({ token, refreshToken, user });
      navigate("/");
    },
    onError: (error) => {
      const { message, errors } = getErrorResponsePayload(error);
      const msg = message || "Not found";
      let desc;
      if (errors?.token) {
        desc = errors.token?.errors[0];
      }
      toast({
        position: "top",
        render: (props) => (
          <CustomToast
            status="error"
            title={msg}
            description={desc}
            onClose={props.onClose}
            id={props.id}
          />
        ),
      });
      navigate("/login", { replace: true });
    },
  });

  useEffect(() => {
    verifyEmail(token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return <Loading type="verifyEmail" />;
}

export default VerifyingYourEmail;
