import landingX2Src from "../../../assets/images/public/home/landing_x2.webp";
import landingMobileX2Src from "../../../assets/images/public/home/landing_mobile_x2.webp";
import landingMobileX1FallbackSrc from "../../../assets/images/public/home/landing_mobile_x1_min.png";
import { Box, Flex, useMediaQuery } from "@chakra-ui/react";
import LandingTextContent from "../shared/LandingTextContent";
import PictureOptimized from "../../shared/PictureOptimized";

const Landing = () => {
  const [isSmallerThanMD] = useMediaQuery("(max-width: 48em)");
  const [isSmallerThanSM] = useMediaQuery("(max-width: 30em)");

  return (
    <Box
      w="full"
      h="full"
      alignSelf="flex-start"
      mt={{ base: "3.9rem", lg: "4.5rem" }}
      position="relative"
      bg="white"
    >
      {isSmallerThanSM && (
        <PictureOptimized
          srcX1={landingMobileX2Src}
          srcX2={landingMobileX2Src}
          srcX3={landingMobileX2Src}
          fallbackSrc={landingMobileX1FallbackSrc}
          height="505px"
          objectFit="cover"
          width="100%"
        />
      )}
      {!isSmallerThanSM && (
        <PictureOptimized
          srcX1={landingX2Src}
          srcX2={landingX2Src}
          srcX3={landingX2Src}
          fallbackSrc={landingX2Src}
          height={isSmallerThanMD && "65vh"}
          objectFit={isSmallerThanMD && "cover"}
          width="100%"
        />
      )}
      <Box position="absolute" w="full" h="full" top="0" left="0">
        <Flex
          w="full"
          h="full"
          alignItems="center"
          justifyContent="flex-start"
          pl={{ base: "1.25rem", lg: "6.75rem" }}
        >
          <LandingTextContent
            type="home"
            ctaText="Hire talents"
            ctaW="10.125rem"
            ctaTwo={true}
            ctaTo="/membership"
            description="Easily discover talent. Quickly recruit unlimited finance candidates. No recruitment fees."
            descriptionW={{ base: "13.5rem", sm: "25.25rem", lg: "36.25rem" }}
          />
        </Flex>
      </Box>
    </Box>
  );
};

export default Landing;
