import { Box, Image } from "@chakra-ui/react";
import circleLimeDots from "../../../../assets/vectors/circle_lime_dots.svg";

const PseudoElementsTypeThree = () => {
  return (
    <Box
      w={{ base: "10rem", lg: "19rem" }}
      position="absolute"
      top={{ base: "-10rem", lg: "-16rem" }}
      right="0"
    >
      <Image
        src={circleLimeDots}
        loading="lazy"
        position="absolute"
        width={{ base: "10rem", lg: "19rem" }}
        top={{ base: "0", sm: "0" }}
        left={{ base: "0", sm: "0" }}
        objectFit="cover"
      />
    </Box>
  );
};

export default PseudoElementsTypeThree;
