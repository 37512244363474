import {
  Flex,
  Image,
  Link,
  useMediaQuery,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Hide,
  Heading,
  Box,
  Text,
  Center,
} from "@chakra-ui/react";
import { useContext } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import logoUrl from "../../../../assets/vectors/logo.svg";
import logoSymbolUrl from "../../../../assets/vectors/logoSymbol.svg";
import {
  CustomSearchIconFilled,
  CustomMyProfileIcon,
  CustomLogoutIcon,
  CustomChatBubbleIcon,
  CustomEmptyProfileIcon,
  CustomDropdownIcon,
} from "../../../../assets/icons";
import { useQuery } from "@tanstack/react-query";
import { AuthContext } from "../../../../context/AuthContext";
import ClientCandidateChatContext from "../../../../context/ClientCandidateChatContext";
import { getMeMetaFn } from "../../../../services/apiServices";

const ClientCandidateHeader = ({ type }) => {
  const [isLargerThan480px] = useMediaQuery("(min-width: 480px)");
  const location = useLocation();
  const {
    authState: { user },
  } = useContext(AuthContext);

  const {
    numOfUnreadMessages,
    updateNumOfUnreadMessages,
    updateChatType,
    updatePageNumber,
    updateItemsPerPage,
  } = useContext(ClientCandidateChatContext);

  useQuery({
    queryKey: ["GetMeMeta"],
    queryFn: getMeMetaFn,
    onSuccess: (response) => {
      updateNumOfUnreadMessages(response?.totalUnreadMessages);
    },
    refetchOnMount: "always",
  });

  let indicatorDynamicValues = {
    radius: numOfUnreadMessages < 10 ? "50%" : "30px",
    width: numOfUnreadMessages < 10 ? "1.375rem" : "2.375rem",
  };

  const clientNavLinks = [
    {
      link: "/find-candidates",
      title: "Find candidates",
      icon: <CustomSearchIconFilled boxSize="2.75rem" />,
    },
    {
      link: "/messages?status=all&page=1&items=10",
      title: "Messages",
      icon: <CustomChatBubbleIcon boxSize="1.5rem" />,
    },
  ];

  const candidateNavLinks = [
    {
      link: "/messages?status=all&page=1&items=10",
      title: "Messages",
      icon: <CustomChatBubbleIcon boxSize="1.5rem" />,
    },
  ];
  const startFreshOnFindCandidatesPageHandler = () => {
    localStorage.removeItem("filters");
    // while you are on the pending / accepted / rejected list load All chats list
    updateChatType({ title: "All chats", status: "all" });
    updatePageNumber(0);
    updateItemsPerPage(10);
  };

  return (
    <Flex
      as="header"
      w="full"
      borderBottom="1px solid #EAE8E8"
      alignItems="center"
      justifyContent="space-between"
      p={{ base: "1rem 1rem", lg: "0.875rem 3.75rem" }}
      position="fixed"
      top="0"
      left="0"
      bg="#ffffff"
      zIndex="5"
    >
      <Link
        as={RouterLink}
        to="/"
        onClick={startFreshOnFindCandidatesPageHandler}
      >
        <Image
          src={isLargerThan480px ? logoUrl : logoSymbolUrl}
          loading="lazy"
          height={{ base: "2.5rem" }}
        />
      </Link>
      <Flex
        flexDir="row"
        alignItems="center"
        justifyContent="center"
        gap="2.938rem"
      >
        {type === "client" &&
          clientNavLinks.map((item, ind) => {
            if (item.title === "Messages")
              return (
                <Box
                  as={RouterLink}
                  to={item.link}
                  key={ind}
                  onClick={startFreshOnFindCandidatesPageHandler}
                  position="relative"
                >
                  {item.icon}
                  {numOfUnreadMessages !== 0 && (
                    <Center
                      bg="electricLime"
                      color="#000"
                      position="absolute"
                      top="-30%"
                      right="-40%"
                      left="50%"
                      borderRadius={indicatorDynamicValues.radius}
                      h="1.375rem"
                      w={indicatorDynamicValues.width}
                      fontSize="0.875rem"
                      textStyle="bodySemiBold"
                      p="0.25rem"
                    >
                      {numOfUnreadMessages > 99 ? "99+" : numOfUnreadMessages}
                    </Center>
                  )}
                </Box>
              );

            if (isLargerThan480px) {
              return (
                <Link
                  as={RouterLink}
                  key={ind}
                  to={item.link}
                  fontFamily="Plus Jakarta Sans Bold, sans-serif"
                  fontSize="1rem"
                  borderBottom={
                    location.pathname === item.link
                      ? "1px solid #CDEE24"
                      : "1px solid transparent"
                  }
                  className={location.pathname !== item.link && "animateLink"}
                  _hover={{ textDecoration: "none" }}
                  onClick={startFreshOnFindCandidatesPageHandler}
                >
                  {item.title}
                </Link>
              );
            }
            return (
              <Box
                as={RouterLink}
                to={item.link}
                key={ind}
                onClick={startFreshOnFindCandidatesPageHandler}
              >
                {item.icon}
              </Box>
            );
          })}
        {type === "candidate" &&
          candidateNavLinks.map((item, ind) => {
            return (
              <Box as={RouterLink} to={item.link} key={ind} position="relative">
                {item.icon}
                {numOfUnreadMessages !== 0 && (
                  <Center
                    bg="electricLime"
                    color="#000"
                    position="absolute"
                    top="-30%"
                    right="-40%"
                    left="50%"
                    borderRadius={indicatorDynamicValues.radius}
                    h="1.375rem"
                    w={indicatorDynamicValues.width}
                    fontSize="0.875rem"
                    textStyle="bodySemiBold"
                    p="0.25rem"
                  >
                    {numOfUnreadMessages > 99 ? "99+" : numOfUnreadMessages}
                  </Center>
                )}
              </Box>
            );
          })}

        <Menu>
          <MenuButton>
            <Flex justifyContent="center" alignItems="center" gap="0.625rem">
              {user?.profile?.photo?.small ? (
                <Image
                  src={user?.profile?.photo?.small}
                  loading="lazy"
                  boxSize="2.625rem"
                  borderRadius="100%"
                  objectFit="cover"
                />
              ) : (
                <CustomEmptyProfileIcon boxSize="2.625rem" />
              )}
              <Hide below="sm">
                <Heading fontSize="1rem">My profile</Heading>
              </Hide>
              <CustomDropdownIcon />
            </Flex>
          </MenuButton>
          <MenuList border="10px" minWidth="166px">
            <Box className="myProfileMenuTriangle" />

            <MenuItem
              as={RouterLink}
              to="/profile"
              borderTopRightRadius="10px"
              borderTopLeftRadius="10px"
              p="1rem"
            >
              <Flex justifyContent="center" alignItems="center" gap="0.625rem">
                <CustomMyProfileIcon boxSize="18px" />
                <Text fontSize="0.875rem">Profile</Text>
              </Flex>
            </MenuItem>
            <MenuItem
              as={RouterLink}
              to="/logout"
              borderTopRightRadius=""
              borderTopLeftRadius=""
              borderBottomRightRadius="10px"
              borderBottomLeftRadius="10px"
              p="1rem"
            >
              <Flex justifyContent="center" alignItems="center" gap="0.625rem">
                <CustomLogoutIcon boxSize="16px" />
                <Text fontSize="0.875rem">Log out</Text>
              </Flex>
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
  );
};

export default ClientCandidateHeader;
