import { ListItem, Text, UnorderedList, Link } from "@chakra-ui/react";
const searchiveLink = "https://searchive.co.uk/";
const searchiveEmail = "hello@searchive.co.uk";
const searchiveContact = "https://searchive.co.uk/contact";

export const ListOne = () => {
  return (
    <UnorderedList spacing="0.5rem">
      <ListItem>
        <Text as="span" textStyle="bodyBold">
          Application{" "}
        </Text>{" "}
        means the software program provided by the Company downloaded by You on
        any electronic device, named SearcHive
      </ListItem>
      <ListItem>
        <Text as="span" textStyle="bodyBold">
          Application Store{" "}
        </Text>{" "}
        means the digital distribution service operated and developed by Apple
        Inc. (Apple App Store) or Google Inc. (Google Play Store) in which the
        Application has been downloaded.
      </ListItem>{" "}
      <ListItem>
        <Text as="span" textStyle="bodyBold">
          Affiliate{" "}
        </Text>{" "}
        means an entity that controls, is controlled by or is under common
        control with a party, where "control" means ownership of 50% or more of
        the shares, equity interest or other securities entitled to vote for
        election of directors or other managing authority.
      </ListItem>
      <ListItem>
        <Text as="span" textStyle="bodyBold">
          Account{" "}
        </Text>{" "}
        means a unique account created for You to access our Service or parts of
        our Service.
      </ListItem>
      <ListItem>
        <Text as="span" textStyle="bodyBold">
          Country{" "}
        </Text>{" "}
        refers to: United Kingdom
      </ListItem>
      <ListItem>
        <Text as="span" textStyle="bodyBold">
          Company{" "}
        </Text>{" "}
        (referred to as either "the Company", "We", "Us" or "Our" in this
        Agreement) refers to Searchive Ltd, Rotten Row Farmhouse, Rotten Row,
        Hambleden, Oxon RG9 6NB.
      </ListItem>{" "}
      <ListItem>
        <Text as="span" textStyle="bodyBold">
          Content{" "}
        </Text>{" "}
        refers to content such as text, images, or other information that can be
        posted, uploaded, linked to or otherwise made available by You,
        regardless of the form of that content.
      </ListItem>{" "}
      <ListItem>
        <Text as="span" textStyle="bodyBold">
          Device{" "}
        </Text>{" "}
        means any device that can access the Service such as a computer, a
        cellphone or a digital tablet.
      </ListItem>{" "}
      <ListItem>
        <Text as="span" textStyle="bodyBold">
          Feedback{" "}
        </Text>{" "}
        means feedback, innovations or suggestions sent by You regarding the
        attributes, performance or features of our Service.
      </ListItem>{" "}
      <ListItem>
        <Text as="span" textStyle="bodyBold">
          Goods{" "}
        </Text>{" "}
        refer to the items offered for sale on the Service.
      </ListItem>{" "}
      <ListItem>
        <Text as="span" textStyle="bodyBold">
          Orders{" "}
        </Text>{" "}
        mean a request by You to purchase Goods from Us.
      </ListItem>{" "}
      <ListItem>
        <Text as="span" textStyle="bodyBold">
          Service{" "}
        </Text>{" "}
        refers to the Application or the Website or both.
      </ListItem>{" "}
      <ListItem>
        <Text as="span" textStyle="bodyBold">
          Subscriptions{" "}
        </Text>{" "}
        refer to the services or access to the Service offered on a subscription
        basis by the Company to You.
      </ListItem>{" "}
      <ListItem>
        <Text as="span" textStyle="bodyBold">
          Terms and Conditions{" "}
        </Text>{" "}
        (also referred as "Terms") mean these Terms and Conditions that form the
        entire agreement between You and the Company regarding the use of the
        Service.
      </ListItem>{" "}
      <ListItem>
        <Text as="span" textStyle="bodyBold">
          Third-party Social Media Service
        </Text>{" "}
        means any services or content (including data, information, products or
        services) provided by a third-party that may be displayed, included or
        made available by the Service.
      </ListItem>{" "}
      <ListItem>
        <Text as="span" textStyle="bodyBold">
          Website
        </Text>{" "}
        refers to SearcHive, accessible from{" "}
        <Text
          as={Link}
          href={searchiveLink}
          isExternal
          color="limeText"
          textStyle="bodyMedium"
        >
          http://www.searchive.co.uk{" "}
        </Text>
      </ListItem>{" "}
      <ListItem>
        <Text as="span" textStyle="bodyBold">
          You
        </Text>{" "}
        means the individual accessing or using the Service, or the company, or
        other legal entity on behalf of which such individual is accessing or
        using the Service, as applicable.
      </ListItem>
    </UnorderedList>
  );
};

export const ListTwo = () => {
  return (
    <UnorderedList spacing="0.5rem">
      <ListItem>Goods availability</ListItem>
      <ListItem>Errors in the description or prices for Goods</ListItem>
      <ListItem>Errors in Your Order</ListItem>
    </UnorderedList>
  );
};

export const ListThree = () => {
  return (
    <UnorderedList spacing="0.5rem">
      <ListItem>
        The supply of Goods made to Your specifications or clearly personalized.
      </ListItem>
      <ListItem>
        The supply of Goods which according to their nature are not suitable to
        be returned, deteriorate rapidly or where the date of expiry is over.
      </ListItem>
      <ListItem>
        The supply of Goods which are not suitable for return due to health
        protection or hygiene reasons and were unsealed after delivery.
      </ListItem>
      <ListItem>
        The supply of Goods which are, after delivery, according to their
        nature, inseparably mixed with other items.
      </ListItem>
      <ListItem>
        The supply of digital content which is not supplied on a tangible medium
        if the performance has begun with Your prior express consent and You
        have acknowledged Your loss of cancellation right.
      </ListItem>
    </UnorderedList>
  );
};
export const ListFour = () => {
  return (
    <UnorderedList spacing="0.5rem">
      <ListItem>Unlawful or promoting unlawful activity. </ListItem>
      <ListItem>
        Defamatory, discriminatory, or mean-spirited content, including
        references or commentary about religion, race, sexual orientation,
        gender, national/ethnic origin, or other targeted groups.
      </ListItem>
      <ListItem>
        Spam, machine - or randomly - generated, constituting unauthorized or
        unsolicited advertising, chain letters, any other form of unauthorized
        solicitation, or any form of lottery or gambling.
      </ListItem>
      <ListItem>
        Containing or installing any viruses, worms, malware, trojan horses, or
        other content that is designed or intended to disrupt, damage, or limit
        the functioning of any software, hardware or telecommunications
        equipment or to damage or obtain unauthorized access to any data or
        other information of a third person.
      </ListItem>
      <ListItem>
        Infringing on any proprietary rights of any party, including patent,
        trademark, trade secret, copyright, right of publicity or other rights.
      </ListItem>
      <ListItem>Violating the privacy of any third person.</ListItem>
      <ListItem>False information and features.</ListItem>
    </UnorderedList>
  );
};
export const ListFive = () => {
  return (
    <UnorderedList spacing="0.5rem">
      <ListItem>
        An electronic or physical signature of the person authorized to act on
        behalf of the owner of the copyright's interest.
      </ListItem>
      <ListItem>
        A description of the copyrighted work that You claim has been infringed,
        including the URL (i.e., web page address) of the location where the
        copyrighted work exists or a copy of the copyrighted work.
      </ListItem>
      <ListItem>
        Identification of the URL or other specific location on the Service
        where the material that You claim is infringing is located.
      </ListItem>
      <ListItem>Your address, telephone number, and email address.</ListItem>
      <ListItem>
        A statement by You that You have a good faith belief that the disputed
        use is not authorized by the copyright owner, its agent, or the law.
      </ListItem>
      <ListItem>
        A statement by You, made under penalty of perjury, that the above
        information in Your notice is accurate and that You are the copyright
        owner or authorized to act on the copyright owner's behalf.
      </ListItem>
    </UnorderedList>
  );
};
export const ListSix = () => {
  return (
    <UnorderedList spacing="0.5rem">
      <ListItem>
        {" "}
        By email:{" "}
        <Text as="span" textStyle="bodyBold">
          {searchiveEmail}
        </Text>
      </ListItem>
      <ListItem>
        By visiting this page on our website: {}
        <Text
          as={Link}
          href={searchiveContact}
          isExternal
          color="limeText"
          textStyle="bodyMedium"
        >
          https://searchive.co.uk/contact{" "}
        </Text>
      </ListItem>
    </UnorderedList>
  );
};
