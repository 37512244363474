import { Icon } from "@chakra-ui/react";

export const CustomPaperClipIcon = (props) => (
  <Icon viewBox="0 0 19 18" fill="none" {...props}>
    <path
      d="M17.2212 1.77766C14.8532 -0.592552 10.9998 -0.592552 8.63146 1.77766L1.26858 9.14756C-0.422861 10.8406 -0.422861 13.5956 1.26858 15.2887C2.11431 16.1352 3.22499 16.5587 4.33642 16.5587C5.4471 16.5587 6.55853 16.1352 7.40425 15.2887L14.1533 8.53318C15.1683 7.51727 15.1686 5.86433 14.1533 4.84842C13.1384 3.83251 11.4867 3.83251 10.4721 4.84842L6.05866 9.26605C5.71985 9.60518 5.71985 10.1549 6.05866 10.4944C6.39747 10.8336 6.94669 10.8336 7.28587 10.4944L11.6997 6.0768C12.0381 5.73804 12.5884 5.73804 12.9269 6.0768C13.2653 6.41556 13.2653 6.96641 12.9269 7.30518L6.17778 14.0611C5.16284 15.077 3.51147 15.077 2.49653 14.0611C1.48159 13.0452 1.48159 11.3922 2.49653 10.3763L9.85866 3.00604C11.5501 1.31298 14.3025 1.31298 15.9943 3.00604C16.8137 3.82619 17.265 4.91676 17.265 6.0768C17.265 7.23683 16.8137 8.3274 15.9943 9.14756L8.63183 16.5175C8.29302 16.8566 8.29302 17.4063 8.63183 17.7458C8.80142 17.9156 9.02333 18.0003 9.24524 18.0003C9.46716 18.0003 9.68944 17.9156 9.85866 17.7458L17.2212 10.3759C18.3686 9.22779 19.0002 7.70114 19.0002 6.0768C19.0002 4.45283 18.3686 2.92617 17.2212 1.77766Z"
      fill={props.color ? props.color : "#D7D7D7"}
    />
  </Icon>
);
