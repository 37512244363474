import { Icon } from "@chakra-ui/react";

export const CustomSingleUserDeactivate = (props) => (
  <Icon viewBox="0 0 20 19" {...props}>
    <path
      d="M9.55679 10.3583C8.55876 10.3583 7.58307 10.0623 6.7532 9.50785C5.92334 8.95328 5.27654 8.16518 4.8946 7.24309C4.51265 6.321 4.41272 5.30635 4.60744 4.32746C4.80215 3.34857 5.28277 2.4494 5.9885 1.74366C6.69424 1.03792 7.59341 0.557302 8.57227 0.362594C9.55117 0.167876 10.5659 0.267811 11.4879 0.64976C12.41 1.0317 13.1981 1.6785 13.7527 2.50836C14.3072 3.33823 14.6031 4.31388 14.6031 5.31194C14.6008 6.64961 14.0684 7.93182 13.1225 8.87763C12.1767 9.82353 10.8945 10.356 9.55679 10.3583ZM9.55679 1.58206C8.81906 1.58206 8.09795 1.80081 7.48458 2.21066C6.8712 2.6205 6.39313 3.20303 6.11082 3.88458C5.82851 4.56612 5.75465 5.31609 5.89857 6.03961C6.04249 6.76314 6.39773 7.42774 6.91936 7.94937C7.44099 8.47103 8.1056 8.82629 8.82915 8.97013C9.55266 9.11406 10.3026 9.04025 10.9841 8.75792C11.6657 8.47559 12.2483 7.99755 12.658 7.38416C13.0679 6.77079 13.2867 6.04965 13.2867 5.31194C13.2844 4.32343 12.8906 3.37606 12.1917 2.67708C11.4926 1.97809 10.5453 1.58437 9.55679 1.58206Z"
      fill="#A6A6A6"
      stroke="#A6A6A6"
      strokeWidth="0.5"
    />
    <path
      d="M1.65822 17.3791C1.48435 17.3769 1.31825 17.3068 1.1953 17.1839C1.07235 17.0609 1.00227 16.8948 1 16.7209C1.00463 14.6857 1.81518 12.7352 3.2543 11.2961C4.69342 9.85697 6.64396 9.0464 8.67918 9.04175H10.4344C10.609 9.04175 10.7764 9.11108 10.8998 9.23456C11.0233 9.35795 11.0926 9.5254 11.0926 9.69996C11.0926 9.87452 11.0233 10.042 10.8998 10.1654C10.7764 10.2888 10.609 10.3582 10.4344 10.3582H8.67918C6.99239 10.3605 5.37534 11.0316 4.1826 12.2244C2.98986 13.4171 2.31876 15.0341 2.31643 16.7209C2.31416 16.8948 2.24408 17.0609 2.12113 17.1839C1.99818 17.3068 1.83208 17.3769 1.65822 17.3791Z"
      fill="#A6A6A6"
      stroke="#A6A6A6"
      strokeWidth="0.5"
    />
    <path
      d="M16.4685 14.5898L18.8157 12.2426C18.9271 12.1314 18.9898 11.9805 18.9899 11.8231C18.9901 11.6657 18.9277 11.5146 18.8165 11.4032C18.7053 11.2918 18.5544 11.2292 18.397 11.229C18.2396 11.2289 18.0885 11.2913 17.9771 11.4025L15.6299 13.7498L13.2826 11.4025C13.1712 11.2911 13.0201 11.2285 12.8626 11.2285C12.705 11.2285 12.5539 11.2911 12.4425 11.4025C12.3311 11.5139 12.2686 11.665 12.2686 11.8225C12.2686 11.9801 12.3311 12.1312 12.4425 12.2426L14.7898 14.5898L12.4425 16.9371C12.3311 17.0485 12.2686 17.1996 12.2686 17.3571C12.2686 17.5147 12.3311 17.6658 12.4425 17.7772C12.5539 17.8886 12.705 17.9512 12.8626 17.9512C13.0201 17.9512 13.1712 17.8886 13.2826 17.7772L15.6299 15.4299L17.9771 17.7772C18.0885 17.8886 18.2396 17.9512 18.3972 17.9512C18.5547 17.9512 18.7058 17.8886 18.8172 17.7772C18.9286 17.6658 18.9912 17.5147 18.9912 17.3571C18.9912 17.1996 18.9286 17.0485 18.8172 16.9371L16.4685 14.5898Z"
      fill="#A6A6A6"
      stroke="#A6A6A6"
      strokeWidth="0.5"
    />
  </Icon>
);
