import {
  Flex,
  Spacer,
  Stack,
  HStack,
  Link,
  Container,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { CustomLogoSymbolIcon } from "../../../../assets/icons/CustomLogoSymbolIcon";
import { CustomLinkedinTypeTwoIcon } from "../../../../assets/icons/CustomLinkedinTypeTwoIcon";

const searchiveLinkedin = "https://www.linkedin.com/company/searchiveapp/";
// three types of public footer -> white scheme(landing, clients, candidates public page etc), black scheme (membership, faq, terms, privacy, etc), mixed scheme (when there is image on the right and white bg on the left)

const FooterNavigation = ({ scheme, bgColor }) => {
  const location = useLocation();
  const [isSmallerThanSM] = useMediaQuery("(max-width: 30em)");
  const [isSmallerThanLG] = useMediaQuery("(max-width: 62em)");

  const footerLinks = [
    {
      segment: [
        { title: "Privacy policy", link: "/privacy" },
        { title: "Terms and conditions", link: "/terms" },
      ],
    },
    {
      segment: [
        { title: "FAQ", link: "/faq" },
        { title: "Contact us", link: "/contact" },
      ],
    },
  ];

  const footerLinksMobile = [
    { title: "Privacy policy", link: "/privacy" },
    { title: "Terms and conditions", link: "/terms" },
    { title: "FAQ", link: "/faq" },
    { title: "Contact us", link: "/contact" },
  ];

  const fgColor =
    scheme === "white" ? "white" : scheme === "black" ? "mediumGrey" : "red";
  const logoColor =
    scheme === "white" ? "white" : scheme === "black" ? "black" : "red";

  return isSmallerThanSM ? (
    <Container
      as="footer"
      bg="white"
      px="3.75rem"
      borderTop="1px solid #EAE8E8"
    >
      <Flex
        direction="column"
        alignItems="center"
        justifyContent="center"
        color="darkGrey"
        h="full"
        w="full"
        py="2rem"
        textAlign="center"
        gap="1rem"
      >
        <HStack
          as={Link}
          href={searchiveLinkedin}
          isExternal
          _hover={{ textDecoration: "none" }}
        >
          <Text fontSize="0.68rem">Join us on</Text>{" "}
          <CustomLinkedinTypeTwoIcon
            boxSize="1.5rem"
            color="#CDEE24"
            pb="3px"
          />
        </HStack>
        {footerLinksMobile.map((item, index) => {
          return (
            <Link
              as={RouterLink}
              key={index}
              to={item.link}
              fontSize="0.68rem"
              borderBottom={
                location.pathname === item.link
                  ? "1px solid #CDEE24"
                  : "1px solid transparent"
              }
            >
              {item.title}
            </Link>
          );
        })}
        <Text fontSize="0.68rem" color="mediumGrey">
          &copy; {new Date().getFullYear()} SearcHive
        </Text>
      </Flex>
    </Container>
  ) : (
    <Container
      as="footer"
      bg={bgColor || "transparent"}
      px={{ base: "1rem", lg: "3.75rem" }}
      position="absolute"
      bottom="0"
      left="0"
      zIndex="5"
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        color={scheme !== "mixed" && fgColor}
        h="full"
        w="full"
        pt="0.625rem"
        pb="1.25rem"
        borderTop="1px solid #EAE8E8"
        gap={{ base: "0.5rem", md: 0 }}
        textAlign="center"
      >
        <Stack
          gap={{ base: "0.5rem", md: "1.25rem" }}
          direction={{ base: "column", md: "row" }}
          justifyContent="center"
          alignItems="center"
          color={scheme === "mixed" && "mediumGrey"}
        >
          <HStack>
            <Link as={RouterLink} to="/">
              <CustomLogoSymbolIcon
                boxSize="2.125rem"
                color={scheme !== "mixed" ? logoColor : "black"}
              />
            </Link>
            <Text fontSize="0.68rem">
              &copy; {new Date().getFullYear()} SearcHive
            </Text>
          </HStack>
          <HStack
            as={Link}
            href={searchiveLinkedin}
            isExternal
            _hover={{ textDecoration: "none" }}
          >
            <Text fontSize="0.68rem">Join us on</Text>{" "}
            <CustomLinkedinTypeTwoIcon
              boxSize="1.5rem"
              color="#CDEE24"
              pb="3px"
            />
          </HStack>
        </Stack>
        <Spacer />
        <Stack
          gap={{ base: "0.5rem", md: "1.25rem" }}
          direction={{ base: "column", md: "row" }}
          justifyContent="center"
          alignItems="flex-start"
        >
          {footerLinks.map((footerSegment, index) => {
            return (
              <HStack key={index} gap={{ base: "0.5rem", md: "1.25rem" }}>
                {footerSegment.segment.map((item, ind) => {
                  return (
                    <Link
                      as={RouterLink}
                      key={ind}
                      to={item.link}
                      fontSize="0.68rem"
                      borderBottom={
                        location.pathname === item.link
                          ? "1px solid #CDEE24"
                          : "1px solid transparent"
                      }
                      className={
                        location.pathname !== item.link && "animateLink"
                      }
                      _hover={{ textDecoration: "none" }}
                      color={
                        isSmallerThanLG
                          ? "mediumGrey"
                          : scheme === "mixed" || scheme === "white"
                            ? "white"
                            : "mediumGrey"
                      }
                    >
                      {item.title}
                    </Link>
                  );
                })}
              </HStack>
            );
          })}
        </Stack>
      </Flex>
    </Container>
  );
};

export default FooterNavigation;
