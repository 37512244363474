import { Icon } from "@chakra-ui/react";

export const CustomSearchIconFilled = (props) => (
  <Icon viewBox="0 0 44 44" fill="none" {...props}>
    <circle cx="22" cy="22" r="22" fill="#CDEE24" />
    <path
      d="M25.7955 25.8111L31 31M28 20.5C28 24.6421 24.6421 28 20.5 28C16.3579 28 13 24.6421 13 20.5C13 16.3579 16.3579 13 20.5 13C24.6421 13 28 16.3579 28 20.5Z"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
