import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";

const KEYS = {
  baseUrl: process.env.REACT_APP_API_URL,
  token: process.env.REACT_APP_TOKEN,
  refreshToken: process.env.REACT_APP_REFRESH_TOKEN,
  user: process.env.REACT_APP_USER,
};

const getAccessToken = () => localStorage.getItem(KEYS.token);

const getRefreshToken = () => {
  return localStorage.getItem(KEYS.refreshToken);
};

const publicAxios = axios.create({
  baseURL: KEYS.baseUrl,
  headers: { "Content-type": "application/json" },
});
const publicAxiosMultipart = axios.create({
  baseURL: KEYS.baseUrl,
  headers: { "Content-type": "multipart/form-data" },
});

const authAxios = axios.create({
  baseURL: KEYS.baseUrl,
  headers: {
    "X-Auth-Token": getAccessToken(),
    "Content-Type": "application/json",
  },
});

authAxios.interceptors.request.use(
  async (config) => {
    // config.headers.Authorization = `Bearer ${authContext.getAccessToken()}`;
    config.headers[`X-Auth-Token`] = getAccessToken();
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

authAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (
      error.response.status === 401 &&
      error.response.data.message === "Expired JWT Token"
    ) {
      console.log("401 expired jwt token");
    }
    return Promise.reject(error);
  }
);

const getNewTokenForRequest = async (failedRequest) => {
  try {
    const { data } = await publicAxios.post("/token/refresh", {
      refresh_token: getRefreshToken(),
    });

    const { token, refresh_token } = data;

    localStorage.setItem(KEYS.token, token);
    localStorage.setItem(KEYS.refreshToken, refresh_token);

    // failedRequest.response.config.headers['Authorization'] = `Bearer ${data.token}`;
    failedRequest.response.config.headers[`X-Auth-Token`] = token;

    //setTokens({ token, refreshToken: refresh_token });

    return Promise.resolve();
  } catch (onError) {
    localStorage.removeItem(KEYS.token);
    localStorage.removeItem(KEYS.refreshToken);
    localStorage.removeItem(KEYS.user);
    window.location.reload();
  }
};

createAuthRefreshInterceptor(authAxios, getNewTokenForRequest, {
  pauseInstanceWhileRefreshing: true,
});

const loginFn = async (user) => {
  const response = await publicAxios.post("login", user);
  return response.data;
};

const registerFn = async (user) => {
  const response = await publicAxios.post("users/clients", user);
  return response.data;
};

const bookADemoFn = async (user) => {
  const response = await publicAxios.post('/users/clients/book-a-demo', user)
  return response.data
}

// export const logoutUserFn = async () => {
//     const response = (await authApi.get) < GenericResponse > "auth/logout";
//     return response.data;
// };

const verifyEmailFn = async (token) => {
  const response = await publicAxios.post(`users/confirm`, { token });
  return response.data;
};

const getMeFn = async () => {
  const response = await authAxios.get("users/me");
  return response?.data;
};

const getMeMetaFn = async () => {
  const response = await authAxios.get("users/me/meta");
  return response?.data;
};

const getCompaniesListFn = async (term) => {
  const response = await authAxios.get(`companies/search?name=${term}`);
  return response.data;
};

const forgotPasswordFn = async (data) => {
  const response = await publicAxios.post(`users/password/forgot`, data);
  return response.data;
};

const resetPasswordFn = async (data) => {
  const response = await publicAxios.post("users/password/reset", data);
  return response.data;
};

const toggleClientMembershipFn = async ({ uuid, membershipStatus }) => {
  const response = await authAxios.put(
    `users/clients/${uuid}/membership-status`,
    {
      membershipStatus,
    }
  );
  return response.data;
}

const getAdminCompaniesListFn = async (pageSize, pageIndex) => {
  const response = await authAxios.get(
    `companies?limit=${pageSize}&offset=${pageIndex * pageSize}`
  );
  return response.data;
};

const getAdminClientsListFn = async (pageSize, pageIndex) => {
  const response = await authAxios.get(
    `users/clients?limit=${pageSize}&offset=${pageIndex * pageSize}`
  );
  return response.data;
};

const getAdminCandidatesListFn = async ({ pageSize, pageIndex, filter }) => {
  const filterString = filter !== null ? `&enabled=${filter}` : "";
  const response = await authAxios.get(
    `users/candidates?limit=${pageSize}&offset=${pageIndex * pageSize
    }${filterString}`
  );
  return response.data;
};

const addAdminNewCompanyFn = async (data) => {
  const response = await authAxios.post("/companies", data);
  return response.data;
};

const adminRegisterCandidateFn = async (data) => {
  const response = await authAxios.post("users/candidates", data);
  return response.data;
};

const editAdminCompanyFn = async ({ data, id }) => {
  const response = await authAxios.patch(`/companies/${id}`, data);
  return response.data;
};

const setupPasswordFn = async (data) => {
  const response = await publicAxios.post("users/password/setup", data);
  return response.data;
};

const deactivateCandidateMembershipFn = async (data) => {
  const {
    candidateUuid: uuid,
    membershipStatus,
    reasonMembershipDeactivation,
  } = data;
  const payload = {
    membershipStatus,
    reasonMembershipDeactivation,
  };
  const response = await authAxios.put(
    `users/candidates/${uuid}/membership-status`,
    payload
  );
  return response.data;
};

const activateCandidateMembershipFn = async (uuid) => {
  const response = await authAxios.put(
    `users/candidates/${uuid}/membership-status`,
    {
      membershipStatus: true,
    }
  );
  return response?.data;
};

const adminLoginAsUserFn = async ({ uuid }) => {
  const response = await authAxios.get(`users/${uuid}/login`);
  return response.data;
};

const clientUpdateProfileFn = async (data) => {
  const response = await authAxios.patch(`profiles/clients`, data);
  return response.data;
};

const fileDownloadFn = async ({ type, identifier }) => {
  const response = await authAxios.get(
    `downloads?type=${type}&identifier=${identifier}`,
    {
      responseType: "blob",
    }
  );
  return { data: response.data, headers: response.headers };
};

const candidateUpdateProfileFn = async (data) => {
  const response = await authAxios.patch(`profiles/candidates`, data);
  return response.data;
};

const clientGetCandidatesFn = async (data) => {
  const response = await authAxios.get(`users/candidates${data}`);
  return response?.data;
};

const getTaxonomyFn = async (data) => {
  const response = await authAxios.get(`taxonomies?type=${data}`);
  return response?.data;
};

const startAThreadFn = async (data) => {
  const response = await authAxios.post("/threads", data);
  return response.data;
};

const getAllThreadsFn = async ({ pageSize, pageIndex, chatType }) => {
  let statusString = chatType === "all" ? "" : `&status=${chatType}`;
  const response = await authAxios.get(
    `/threads?limit=${pageSize}&offset=${pageIndex * pageSize}${statusString}`
  );
  return response.data;
};

const getProfileFn = async (data) => {
  const response = await authAxios.get(`/profiles/${data}`);
  return response.data;
};

const getThreadDetailsFn = async ({ uuid, pageSize, pageIndex }) => {
  const response = await authAxios.get(
    `/threads/${uuid}/messages?limit=${pageSize}&offset=${pageIndex * pageSize}`
  );
  return response.data;
};

const getThreadBasicFn = async (uuid) => {
  const response = await authAxios.get(`/threads/${uuid}`);
  return response.data;
};

const sendMessageFn = async ({ data, uuid }) => {
  const response = await authAxios.post(`/threads/${uuid}/messages`, data);
  return response.data;
};

const sendContactMessageFn = async (data) => {
  const response = await publicAxios.post("/contact", data);
  return response.data;
};
const candidateRegisterFn = async (data) => {
  const response = await publicAxiosMultipart.post("/users/candidates/join", data);
  return response.data;
};

const subscribeNewsLetter = async (data) => {
  const response = await publicAxios.post("/subscribe", data);
  return response.data;
};

export {
  registerFn,
  loginFn,
  getMeFn,
  getMeMetaFn,
  getCompaniesListFn,
  verifyEmailFn,
  forgotPasswordFn,
  resetPasswordFn,
  getAdminCompaniesListFn,
  addAdminNewCompanyFn,
  editAdminCompanyFn,
  getAdminClientsListFn,
  getAdminCandidatesListFn,
  publicAxios,
  authAxios,
  toggleClientMembershipFn,
  adminRegisterCandidateFn,
  setupPasswordFn,
  deactivateCandidateMembershipFn,
  activateCandidateMembershipFn,
  adminLoginAsUserFn,
  clientUpdateProfileFn,
  fileDownloadFn,
  candidateUpdateProfileFn,
  clientGetCandidatesFn,
  getTaxonomyFn,
  startAThreadFn,
  getAllThreadsFn,
  getProfileFn,
  getThreadDetailsFn,
  getThreadBasicFn,
  sendMessageFn,
  sendContactMessageFn,
  candidateRegisterFn,
  bookADemoFn,
  subscribeNewsLetter
};
