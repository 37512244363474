import { Center, Flex } from "@chakra-ui/react";
import HeaderNavigation from "../../../private/shared/navs/HeaderNavigation";
import ScrollToTop from "../../../private/shared/UI/ScrollToTop";
import FooterNavigation from "../navs/FooterNavigation";

// used for public->home, clients, candidates
const LayoutHome = ({ children }) => {
  return (
    <>
      <ScrollToTop />
      <Flex
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        minH="100vh"
        position="relative"
      >
        <HeaderNavigation />
        <Center flex="1" w="full">
          {children}
        </Center>
        <FooterNavigation scheme="white" />
      </Flex>
    </>
  );
};

export default LayoutHome;
