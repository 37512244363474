import { Icon } from "@chakra-ui/react";

export const CustomDocxIcon = (props) => (
  <Icon width="35" height="33" viewBox="0 0 35 33" fill="none" {...props}>
    <path
      d="M34.0156 9.10092L25.8841 0.651444C25.7683 0.531253 25.6086 0.463303 25.4418 0.463303H13.3223C11.966 0.463303 10.8625 1.56634 10.8625 2.92211V11.5118H5.95093C4.93616 11.5118 4.1106 12.3372 4.1106 13.3517V22.562C4.1106 23.5765 4.93616 24.4018 5.95093 24.4018H10.8625V29.9268C10.8625 31.2802 11.966 32.3813 13.3223 32.3813H31.7274C33.0837 32.3813 34.1872 31.2808 34.1872 29.9281V9.52657C34.1872 9.36785 34.1256 9.21531 34.0156 9.10092ZM25.5939 2.12036L32.2692 9.05659H25.5939V2.12036ZM5.95093 23.1742C5.61305 23.1742 5.33825 22.8996 5.33825 22.562V13.3517C5.33825 13.0141 5.61311 12.7394 5.95093 12.7394H22.526C22.8638 12.7394 23.1386 13.0141 23.1386 13.3517V22.562C23.1386 22.8996 22.8638 23.1742 22.526 23.1742H5.95093ZM32.9595 29.9281C32.9595 30.6039 32.4067 31.1537 31.7273 31.1537H13.3223C12.6429 31.1537 12.0901 30.6033 12.0901 29.9268V24.4018H22.526C23.5407 24.4018 24.3663 23.5765 24.3663 22.562V13.3517C24.3663 12.3372 23.5407 11.5118 22.526 11.5118H12.0901V2.92211C12.0901 2.24317 12.6429 1.6909 13.3223 1.6909H24.3662V9.67039C24.3662 10.0094 24.6411 10.2842 24.98 10.2842H32.9595V29.9281Z"
      fill="#333333"
    />
    <rect
      x="0.0964355"
      y="11.4438"
      width="26.73"
      height="13.3125"
      rx="1"
      fill="#CDEE24"
    />
    <path
      d="M5.34644 20.6004H3.54175V15.5095H5.36135C5.87342 15.5095 6.31424 15.6114 6.68379 15.8152C7.05335 16.0174 7.33756 16.3082 7.53642 16.6877C7.73694 17.0672 7.8372 17.5213 7.8372 18.05C7.8372 18.5803 7.73694 19.036 7.53642 19.4171C7.33756 19.7983 7.05169 20.0908 6.67882 20.2946C6.30761 20.4985 5.86348 20.6004 5.34644 20.6004ZM4.6181 19.6781H5.30169C5.61987 19.6781 5.88751 19.6218 6.1046 19.5091C6.32335 19.3948 6.48742 19.2183 6.59679 18.9796C6.70782 18.7393 6.76334 18.4294 6.76334 18.05C6.76334 17.6738 6.70782 17.3664 6.59679 17.1277C6.48742 16.8891 6.32418 16.7134 6.10709 16.6007C5.89 16.488 5.62236 16.4317 5.30418 16.4317H4.6181V19.6781Z"
      fill="#333333"
    />
    <path
      d="M13.3128 18.0549C13.3128 18.6101 13.2076 19.0824 12.9971 19.4718C12.7883 19.8613 12.5032 20.1587 12.142 20.3642C11.7824 20.5681 11.378 20.67 10.9289 20.67C10.4765 20.67 10.0705 20.5672 9.71087 20.3617C9.35126 20.1562 9.06705 19.8588 8.85824 19.4693C8.64944 19.0799 8.54503 18.6084 8.54503 18.0549C8.54503 17.4998 8.64944 17.0275 8.85824 16.638C9.06705 16.2486 9.35126 15.9519 9.71087 15.7481C10.0705 15.5426 10.4765 15.4399 10.9289 15.4399C11.378 15.4399 11.7824 15.5426 12.142 15.7481C12.5032 15.9519 12.7883 16.2486 12.9971 16.638C13.2076 17.0275 13.3128 17.4998 13.3128 18.0549ZM12.2215 18.0549C12.2215 17.6953 12.1677 17.392 12.0599 17.1451C11.9539 16.8982 11.8039 16.7109 11.61 16.5833C11.4161 16.4557 11.1891 16.3919 10.9289 16.3919C10.6687 16.3919 10.4417 16.4557 10.2478 16.5833C10.0539 16.7109 9.90311 16.8982 9.79539 17.1451C9.68933 17.392 9.6363 17.6953 9.6363 18.0549C9.6363 18.4145 9.68933 18.7178 9.79539 18.9647C9.90311 19.2116 10.0539 19.3989 10.2478 19.5265C10.4417 19.6541 10.6687 19.7179 10.9289 19.7179C11.1891 19.7179 11.4161 19.6541 11.61 19.5265C11.8039 19.3989 11.9539 19.2116 12.0599 18.9647C12.1677 18.7178 12.2215 18.4145 12.2215 18.0549Z"
      fill="#333333"
    />
    <path
      d="M18.6019 17.2918H17.5132C17.4933 17.1509 17.4527 17.0258 17.3914 16.9164C17.33 16.8054 17.2513 16.7109 17.1552 16.633C17.0591 16.5552 16.9481 16.4955 16.8221 16.4541C16.6978 16.4126 16.5628 16.3919 16.4169 16.3919C16.1534 16.3919 15.9239 16.4574 15.7284 16.5883C15.5328 16.7176 15.3812 16.9065 15.2735 17.1551C15.1657 17.402 15.1119 17.7019 15.1119 18.0549C15.1119 18.4178 15.1657 18.7228 15.2735 18.9697C15.3828 19.2166 15.5353 19.4031 15.7308 19.529C15.9264 19.6549 16.1526 19.7179 16.4095 19.7179C16.5536 19.7179 16.687 19.6989 16.8097 19.6607C16.934 19.6226 17.0442 19.5671 17.1403 19.4942C17.2364 19.4196 17.316 19.3293 17.3789 19.2232C17.4436 19.1172 17.4883 18.9962 17.5132 18.8603L18.6019 18.8653C18.5738 19.099 18.5033 19.3243 18.3906 19.5414C18.2796 19.7569 18.1296 19.9499 17.9407 20.1206C17.7535 20.2897 17.5297 20.4239 17.2696 20.5233C17.011 20.6211 16.7185 20.67 16.3921 20.67C15.938 20.67 15.532 20.5672 15.174 20.3617C14.8177 20.1562 14.536 19.8588 14.3289 19.4693C14.1234 19.0799 14.0206 18.6084 14.0206 18.0549C14.0206 17.4998 14.125 17.0275 14.3338 16.638C14.5426 16.2486 14.826 15.9519 15.184 15.7481C15.5419 15.5426 15.9446 15.4399 16.3921 15.4399C16.687 15.4399 16.9605 15.4813 17.2124 15.5642C17.4659 15.647 17.6905 15.768 17.886 15.9271C18.0816 16.0845 18.2407 16.2776 18.3633 16.5063C18.4876 16.735 18.5671 16.9968 18.6019 17.2918Z"
      fill="#333333"
    />
    <path
      d="M20.2736 15.5095L21.3003 17.2446H21.34L22.3716 15.5095H23.5872L22.0336 18.0549L23.622 20.6004H22.3841L21.34 18.8628H21.3003L20.2562 20.6004H19.0233L20.6167 18.0549L19.0531 15.5095H20.2736Z"
      fill="#333333"
    />
  </Icon>
);
