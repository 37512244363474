import { Icon } from "@chakra-ui/react";

export const CustomExclamationMarkTriangleIcon = (props) => (
  <Icon viewBox="0 0 33 32" {...props}>
    <path
      d="M32.3216 27.2728L18.6928 1.96327C17.7268 0.168817 15.1535 0.168817 14.1867 1.96327L0.558747 27.2728C0.349004 27.6623 0.243848 28.0996 0.253544 28.542C0.26324 28.9843 0.387457 29.4166 0.614069 29.7966C0.840681 30.1766 1.16195 30.4913 1.54651 30.7101C1.93107 30.9289 2.36578 31.0442 2.80822 31.0448H30.0681C30.5109 31.0449 30.9461 30.9301 31.3312 30.7116C31.7163 30.4931 32.0382 30.1784 32.2652 29.7983C32.4923 29.4182 32.6169 28.9856 32.6268 28.543C32.6367 28.1003 32.5315 27.6626 32.3216 27.2728ZM16.4402 27.1265C16.1239 27.1265 15.8146 27.0327 15.5516 26.8569C15.2886 26.6812 15.0836 26.4314 14.9626 26.1392C14.8415 25.8469 14.8099 25.5253 14.8716 25.2151C14.9333 24.9049 15.0856 24.6199 15.3093 24.3962C15.5329 24.1725 15.8179 24.0202 16.1282 23.9585C16.4384 23.8968 16.76 23.9285 17.0522 24.0495C17.3444 24.1706 17.5942 24.3756 17.77 24.6386C17.9457 24.9016 18.0395 25.2108 18.0395 25.5271C18.0395 25.7371 17.9981 25.9451 17.9178 26.1392C17.8374 26.3332 17.7196 26.5095 17.5711 26.658C17.4226 26.8065 17.2462 26.9243 17.0522 27.0047C16.8582 27.0851 16.6502 27.1265 16.4402 27.1265ZM18.1771 11.0411L17.718 20.7971C17.718 21.1364 17.5832 21.4618 17.3433 21.7018C17.1033 21.9417 16.7779 22.0765 16.4386 22.0765C16.0992 22.0765 15.7738 21.9417 15.5338 21.7018C15.2939 21.4618 15.1591 21.1364 15.1591 20.7971L14.7001 11.0451C14.6898 10.8121 14.7265 10.5794 14.808 10.3608C14.8896 10.1423 15.0143 9.94238 15.1747 9.77306C15.3352 9.60374 15.528 9.46845 15.7419 9.37527C15.9557 9.28208 16.1861 9.23291 16.4194 9.23066H16.4362C16.671 9.23054 16.9034 9.278 17.1194 9.37018C17.3354 9.46235 17.5305 9.59734 17.6929 9.76698C17.8553 9.93662 17.9816 10.1374 18.0643 10.3572C18.1469 10.577 18.1842 10.8113 18.1739 11.0459L18.1771 11.0411Z"
      // #CDEE24 electricLime
      fill={props.color}
    />
  </Icon>
);
