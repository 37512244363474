import { Button, Center, Heading, Image, Link, Text } from "@chakra-ui/react";
import JoinAsClientSrc from "../../assets/vectors/JoinAsClient.svg";
import JoinAsCandidateSrc from "../../assets/vectors/JoinAsCandidate.svg";
import { Link as RouterLink } from "react-router-dom";

const GetStartedPage = () => {
  return (
    <Center
      // maxH={{ sm: "120vh", md: "100vh" }}
      //h={{ sm: "120vh", md: "full" }}
      minH="100vh"
      flex="1 1 0px"
      flexDir={{ base: "column", sm: "row" }}
    >
      <Center
        h="full"
        w="full"
        flexDir="column"
        gap="2.188rem"
        pt={{ base: "8rem", sm: "1rem", md: "8rem" }}
        pb={{ base: "3rem", md: "9rem" }}
      >
        <Image src={JoinAsClientSrc} loading="lazy" />
        <Heading
          fontSize={{ base: "2rem", lg: "3.125rem" }}
          lineHeight={{ base: "2.5rem", lg: "3.938rem" }}
          textAlign="center"
        >
          I'm looking for <br />
          new talent
        </Heading>
        <Button
          as={RouterLink}
          to="/join-as-client"
          w="231px"
          minH="3.063rem"
          alt="Join as a company"
        >
          Join as a company
        </Button>
        <Text alignSelf="center" fontSize="0.875rem" position="relative">
          You already have an account?{" "}
          <Link
            as={RouterLink}
            to="/login"
            color="limeText"
            fontSize="0.875rem"
            lineHeight="1.059rem"
          >
            Sign in
          </Link>
          <Link
            href="https://freetrial.searchive.co.uk/#join-the-hive"
            isExternal
            color="limeText"
            position="absolute"
            top="40px"
            left="112px"
            transform="translate(-50%, 0)"
          >
            Free trial
          </Link>
        </Text>
      </Center>

      <Center
        h="full"
        w="full"
        flexDir="column"
        gap="2.188rem"
        bg="backgroundGrey"
        pt={{ base: "3rem", sm: "1rem", md: "8rem" }}
        pb={{ base: "3rem", md: "9rem" }}
      >
        <Image src={JoinAsCandidateSrc} loading="lazy" />
        <Heading
          fontSize={{ base: "2rem", lg: "3.125rem" }}
          lineHeight={{ base: "2.5rem", lg: "3.938rem" }}
          textAlign="center"
        >
          I'm looking for <br /> a new job
        </Heading>
        <Button
          as={RouterLink}
          to="/join-as-candidate"
          w="231px"
          minH="3.063rem"
          alt="Join as a candidate"
        >
          Join as a candidate
        </Button>
        <Text alignSelf="center" fontSize="0.875rem">
          You already have an account?{" "}
          <Link
            as={RouterLink}
            to="/login"
            color="limeText"
            fontSize="0.875rem"
            lineHeight="1.059rem"
          >
            Sign in
          </Link>
        </Text>
      </Center>
    </Center>
  );
};

export default GetStartedPage;
