import { Box, Image, useMediaQuery } from "@chakra-ui/react";
import ringBlack from "../../../../assets/vectors/ringBlack.svg";
import elipseLimeFull from "../../../../assets/vectors/elipseLimeFull.svg";

const PseudoElementsTypeFive = () => {
  const [isLargerThanMD] = useMediaQuery("(min-width: 48em)");

  return (
    <Box
      w="full"
      position="absolute"
      top="0"
      left={{ base: 0, xl: "calc((100% - 92rem) / 2)" }}
    >
      {isLargerThanMD && (
        <Image
          src={ringBlack}
          loading="lazy"
          width="1.6875rem"
          position="absolute"
          top={{ base: "1rem", xl: "12rem" }}
          left="5rem"
          objectFit="cover"
        />
      )}

      <Image
        src={elipseLimeFull}
        loading="lazy"
        width="4rem"
        position="absolute"
        top={{ base: "-1.5rem", md: "3rem", xl: "16rem" }}
        left={{ base: "-2rem", xl: "0" }}
        objectFit="cover"
      />
    </Box>
  );
};

export default PseudoElementsTypeFive;
