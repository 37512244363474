import { Box, Text, Flex, VStack, CloseButton } from "@chakra-ui/react";
import {
  CustomExclamationMarkTriangleIcon,
  CustomExclamationMarkIcon,
  CustomCheckCircleIcon,
} from "../../../../assets/icons/";
import { newLineAfterPeriod } from "../../../../utils/helpers";

const CustomToast = ({ onClose, status, title, description, width }) => {
  const renderIcon = (status) => {
    switch (status) {
      case "warning":
        return (
          <CustomExclamationMarkTriangleIcon boxSize="1.8rem" color="#ED4337" />
        );
      case "error":
        return <CustomExclamationMarkIcon boxSize="1.8rem" color="#ED4337" />;
      case "success":
        return (
          <CustomCheckCircleIcon
            boxSize="1.875rem"
            color="#CDEE24;
"
          />
        );
      default:
        return null;
    }
  };

  return (
    <Flex
      boxShadow="0px 3.5px 5.5px rgba(0, 0, 0, 0.05)"
      bg="black"
      color="white"
      w={{ base: "100%", md: width ? width : "33rem" }}
      borderRadius="15px"
      position="relative"
      alignItems="center"
      justifyContent="space-between"
      textAlign="center"
      p="1.75rem 2rem"
      gap="0.5rem"
    >
      <Box alignSelf="flex-start" justifySelf="flex-start">
        {renderIcon(status)}
      </Box>
      <VStack>
        {title && <Text>{title}</Text>}
        <Text maxW="25rem">{newLineAfterPeriod(description)}</Text>
      </VStack>
      <Box>
        <CloseButton
          onClick={onClose}
          position="absolute"
          top="5%"
          right="2%"
        />
      </Box>
    </Flex>
  );
};

export default CustomToast;
