import {
  Box,
  Center,
  Circle,
  Flex,
  Heading,
  Image,
  Text,
} from "@chakra-ui/react";
import circleDotsSrc from "../../../assets/vectors/circleDot.svg";
import triangleDotsSrc from "../../../assets/vectors/triangleDotsStraight.svg";
import { CustomLinesIcon } from "../../../assets/icons";

const ThreeSymbolsSection = ({
  maxTextW,
  heading1,
  description1,
  heading2,
  description2,
  heading3,
  description3,
}) => {
  return (
    <Center bg="backgroundGrey" w="full" h="full">
      <Center
        w={{ md: "90%", xl: "70%" }}
        justifyContent="space-between"
        alignItems={{ base: "center", md: "flex-end" }}
        flexDir={{ base: "column", md: "row" }}
        rowGap="2rem"
        columnGap="2rem"
        py={{ base: "3rem", lg: "4rem" }}
      >
        <Flex
          flexDir="column"
          alignItems="center"
          justifyContent="flex-start"
          textAlign="center"
          gap="1rem"
        >
          <Image
            src={circleDotsSrc}
            loading="lazy"
            w={{ base: "7rem", sm: "11.5rem" }}
          />
          <Heading
            fontSize={{ base: "1.6rem", lg: "2.5rem" }}
            lineHeight={{ base: "2rem", lg: "3.5rem" }}
            position="relative"
            w="fit-content"
          >
            {heading1}
            <Box position="absolute" w="full" h="full" top="0" left="0">
              <Circle
                boxSize={{ base: "0.7rem", lg: "1.125rem" }}
                bgColor="electricLime"
                position="absolute"
                bottom={{ base: "14%", md: "18%" }}
                right={{ base: "-18px", md: "-25px" }}
              />
            </Box>
          </Heading>
          <Text
            color="darkGrey"
            maxW={maxTextW}
            h={{ md: "100px" }}
            fontSize={{ base: "1rem", lg: "1.25rem" }}
          >
            {description1}
          </Text>
        </Flex>
        <Flex
          flexDir="column"
          alignItems="center"
          justifyContent="flex-start"
          textAlign="center"
          gap="1rem"
        >
          <CustomLinesIcon
            w={{ base: "8rem", sm: "11.125rem" }}
            h="auto"
            transform="scale(-1,1) rotate(-26deg)"
          />
          <Heading
            fontSize={{ base: "1.6rem", lg: "2.5rem" }}
            lineHeight={{ base: "2rem", lg: "3.5rem" }}
            position="relative"
            w="fit-content"
          >
            {heading2}
            <Box position="absolute" w="full" h="full" top="0" left="0">
              <Circle
                boxSize={{ base: "0.7rem", lg: "1.125rem" }}
                bgColor="electricLime"
                position="absolute"
                bottom={{ base: "13%", md: "17%" }}
                right={{ base: "-18px", md: "-25px" }}
              />
            </Box>
          </Heading>
          <Text
            color="darkGrey"
            maxW={maxTextW}
            h={{ md: "100px" }}
            fontSize={{ base: "1rem", lg: "1.25rem" }}
          >
            {description2}
          </Text>
        </Flex>
        <Flex
          flexDir="column"
          alignItems="center"
          justifyContent="flex-start"
          textAlign="center"
          gap="1rem"
        >
          <Image src={triangleDotsSrc} loading="lazy" w="7rem" />
          <Heading
            fontSize={{ base: "1.6rem", lg: "2.5rem" }}
            lineHeight={{ base: "2rem", lg: "3.5rem" }}
            position="relative"
            w="fit-content"
            pt="1rem"
          >
            {heading3}
            <Box position="absolute" w="full" h="full" top="0" left="0">
              <Circle
                boxSize={{ base: "0.7rem", lg: "1.125rem" }}
                bgColor="electricLime"
                position="absolute"
                bottom={{ base: "13%", md: "17%" }}
                right={{ base: "-18px", md: "-25px" }}
              />
            </Box>
          </Heading>
          <Text
            color="darkGrey"
            maxW={maxTextW}
            h={{ md: "100px" }}
            fontSize={{ base: "1rem", lg: "1.25rem" }}
          >
            {description3}
          </Text>
        </Flex>
      </Center>
    </Center>
  );
};

export default ThreeSymbolsSection;
