import {
  Box,
  Flex,
  Button,
  Text,
  Card,
  CardBody,
  Image,
  Heading,
  Link,
  Spacer,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import annualIconSrc from "../../../assets/vectors/high_volume.svg";
import monthlyIconSrc from "../../../assets/vectors/low_volume.svg";
import outsourcedServiceIconSrc from "../../../assets/vectors/outsourcedService.svg";
import requestDemoIconSrc from "../../../assets/vectors/requestDemo.svg";
import { CustomCheckIcon } from "../../../assets/icons";

const monthlyPayData = {
  icon: monthlyIconSrc,
  title: "One time access for 30 days",
  // price: "3,000",
  price: '500',
  per: "month",
  paragraph: "Search for active candidates and make direct hires",
  featuresList: [
    "24/7 access to the SearcHive Talent Platform",
    "Instant setup, access & onboarding",
    "Single user account",
    "Unlimited searches, outbound messages & hires",
    "No recruitment fees",
    "One upfront payment",
  ],
  ctaText: "Get Started",
};
const outsourcedServiceData = {
  icon: outsourcedServiceIconSrc,
  title: "managed service",
  price: "5,000",
  per: "hire",
  paragraph:
    "Companies who lack the time and resources, our team of experts will do it for you",
  featuresList: [
    "SearcHive runs the hiring process",
    "Shortlist within 48 hrs",
    "Includes arranging interviews & offer management",
    "No additional recruitment fees",
    "Dedicated Account Manager",
  ],
  ctaText: "Get Started",
};
const annualData = {
  icon: annualIconSrc,
  title: "annual subscription",
  // price: "25,000",
  price: '199 per month',
  per: "12 months",
  paragraph: "Search for active candidates and make direct hires",
  featuresList: [
    "24/7 access to the SearcHive Talent Platform",
    "Instant setup, access & onboarding",
    "Unlimited user accounts",
    "Unlimited searches, outbound messages and hires",
    "No recruitment fees",
    "Pay monthly",
    "Dedicated Account Manager",
  ],
  ctaText: "Get Started",
};
const demoData = {
  icon: requestDemoIconSrc,
  title: "request a free demo",
  price: null,
  per: null,
  paragraph:
    "You're curious and just need to see how effective the platform is before you subscribe",
  featuresList: null,
  ctaText: "Book a demo",
};

const MembershipCard = ({ type }) => {
  return (
    <Card
      w={{ base: "full", sm: "19.875rem" }} // 270px
      borderWidth="1px"
      borderColor="fieldStroke"
      borderRadius="10px"
      boxShadow="0px 0px 5px rgba(0, 0, 0, 0.1)"
      bgColor='black'
      color='white'
      p={{ base: "1.5rem", sm: "1.5625rem" }}
      h={{ base: "37rem", sm: "42.625rem" }}
    >
      <CardBody p="0">
        <Flex direction="column" alignItems="center">
          {/* icon */}
          <Box mb="1.875rem">
            {type === "monthly" && (
              <Image
                src={monthlyPayData.icon}
                boxSize={{ base: "3.9rem", lg: "4.375rem" }}
              />
            )}
            {type === "outsourced" && (
              <Image
                src={outsourcedServiceData.icon}
                boxSize={{ base: "3.9rem", lg: "4.375rem" }}
              />
            )}
            {type === "annual" && (
              <Image
                src={annualData.icon}
                boxSize={{ base: "3.9rem", lg: "4.375rem" }}
              />
            )}
            {type === "demo" && (
              <Image
                src={demoData.icon}
                boxSize={{ base: "3.9rem", lg: "4.375rem" }}
              />
            )}
          </Box>

          {/* title */}
          <Box mb="1rem" textAlign="center">
            <Heading
              as="h2"
              textStyle="headingExtraBold"
              fontSize={{ base: "1.4rem", sm: "1.625rem" }}
              color="electricLime"
              textTransform="uppercase"
              fontWeight="800"
            >
              {type === "monthly" && <Text>{monthlyPayData.title}</Text>}
              {type === "outsourced" && (
                <Text>{outsourcedServiceData.title}</Text>
              )}
              {type === "annual" && <Text>{annualData.title}</Text>}
              {type === "demo" && <Text>{demoData.title}</Text>}
            </Heading>
          </Box>

          {/* price */}
          <Box mb={{ base: "1rem", sm: "1.875rem" }}>
            <Text
              fontSize={{ base: "1rem", sm: "1.25rem" }}
              lineHeight="1.6875rem"
              textAlign="center"
              fontWeight="700"
            >
              {type === "monthly" && `from £${monthlyPayData.price} `}
              {type === "outsourced" && `from £${outsourcedServiceData.price} `}
              {type === "annual" && `from £${annualData.price} `}
              {/* {type === "monthly" && (
                <Text
                  as="span"
                  fontSize={{ base: "1rem", sm: "1rem" }}
                  fontWeight="400"
                >
                  / {monthlyPayData.per}
                </Text>
              )} */}
              {type === "outsourced" && (
                <Text
                  as="span"
                  fontSize={{ base: "1rem", sm: "1rem" }}
                  fontWeight="400"
                >
                  / {outsourcedServiceData.per}
                </Text>
              )}
              {type === "annual" && (
                <Text
                  as="span"
                  fontSize={{ base: "1rem", sm: "1rem" }}
                  fontWeight="400"
                >
                  / {annualData.per}
                </Text>
              )}
            </Text>
          </Box>

          {/* spacer */}
          {/* {type === "outsourced" && <Box h="30px" />} */}
          {type === "demo" && <Box h="26px"></Box>}

          {/* paragraph */}
          <Box mb="1.25rem" textAlign="center">
            <Text
              fontSize="0.875rem"
              lineHeight="1.0625rem"
              textAlign={type === "demo" ? "center" : "left"}
            >
              {type === "monthly" && monthlyPayData.paragraph}
              {type === "outsourced" && outsourcedServiceData.paragraph}
              {type === "annual" && annualData.paragraph}
              {type === "demo" && demoData.paragraph}
            </Text>
          </Box>

          {/* List */}
          <Box mb="2.5rem" textAlign="left" w="full">
            <Flex
              direction="column"
              gap="0.6rem"
              fontSize="0.875rem"
              lineHeight="1.0625rem"
              w="full"
            >
              {type === "monthly" &&
                monthlyPayData.featuresList.map((feature) => {
                  return (
                    <Flex
                      key={feature}
                      direction="row"
                      alignItems="center"
                      justifyContent="flex-start"
                      gap="0.6875rem"
                      w="full"
                    >
                      <CustomCheckIcon boxSize="1rem" color="#CDEE24" />
                      <Text>{feature}</Text>
                    </Flex>
                  );
                })}
              {type === "outsourced" &&
                outsourcedServiceData.featuresList.map((feature) => {
                  return (
                    <Flex
                      key={feature}
                      direction="row"
                      alignItems="center"
                      justifyContent="flex-start"
                      gap="0.6875rem"
                      w="full"
                    >
                      <CustomCheckIcon boxSize="1rem" color="#CDEE24" />
                      <Text>{feature}</Text>
                    </Flex>
                  );
                })}
              {type === "annual" &&
                annualData.featuresList.map((feature) => {
                  return (
                    <Flex
                      key={feature}
                      direction="row"
                      alignItems="center"
                      justifyContent="flex-start"
                      gap="0.6875rem"
                      w="full"
                    >
                      <CustomCheckIcon boxSize="1rem" color="#CDEE24" />
                      <Text>{feature}</Text>
                    </Flex>
                  );
                })}
            </Flex>
          </Box>

          {/* spacing */}
          {type === "demo" && <Box h="72px"></Box>}

          {/* Button */}
          <Box mb="0rem" w="80%" sx={{
            position: 'absolute',
            bottom: '2rem',
          }}>
            {type === "demo" ? (
              <Button
                as={RouterLink}
                // href="https://freetrial.searchive.co.uk/#join-the-hive"
                // isExternal
                to='/book-a-demo'
                // variant="solid"
                // w="full"
                variant="solidWhite"
                size="sm"
                w="full"
                fontSize="1rem"
              >
                {demoData.ctaText}
              </Button>
            ) : (
              <Button
                as={RouterLink}
                to={`/join-as-client?clientPlan=${type === "monthly"
                  ? "low_volume"
                  : type === "outsourced"
                    ? "outsourced_services_volume"
                    : "medium_high_volume"
                  }`}
                variant="solidWhite"
                size="sm"
                w="full"
                fontSize="1rem"
              >
                {type === "monthly" && monthlyPayData.ctaText}
                {type === "outsourced" && outsourcedServiceData.ctaText}
                {type === "annual" && annualData.ctaText}
                {/* {type === "demo" && demoData.ctaText} */}
              </Button>
            )}
          </Box>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default MembershipCard;
