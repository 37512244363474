import { Icon } from "@chakra-ui/react";

export const CustomExperienceNoticeIcon = (props) => (
  <Icon viewBox="0 0 24 23" fill="none" {...props}>
    <path
      d="M0.921875 6.97656C0.921875 5.3197 2.26502 3.97656 3.92187 3.97656H20.2311C21.888 3.97656 23.2311 5.31971 23.2311 6.97656V19.9056C23.2311 21.5625 21.888 22.9056 20.2311 22.9056H3.92187C2.26502 22.9056 0.921875 21.5625 0.921875 19.9056V6.97656Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.2311 5.97656H3.92187C3.36959 5.97656 2.92188 6.42428 2.92188 6.97656V19.9056C2.92188 20.4579 3.36959 20.9056 3.92187 20.9056H20.2311C20.7834 20.9056 21.2311 20.4579 21.2311 19.9056V6.97656C21.2311 6.42427 20.7834 5.97656 20.2311 5.97656ZM3.92187 3.97656C2.26502 3.97656 0.921875 5.3197 0.921875 6.97656V19.9056C0.921875 21.5625 2.26502 22.9056 3.92187 22.9056H20.2311C21.888 22.9056 23.2311 21.5625 23.2311 19.9056V6.97656C23.2311 5.31971 21.888 3.97656 20.2311 3.97656H3.92187Z"
      fill={props.color ? props.color : "#CDEE24"}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.5551 9.98047H0.921875V7.98047H22.5551V9.98047Z"
      fill={props.color ? props.color : "#CDEE24"}
    />
    <path
      d="M5.41016 3.22541C5.41016 2.85714 5.7087 2.55859 6.07697 2.55859C6.44525 2.55859 6.74379 2.85714 6.74379 3.22541V5.14963C6.74379 5.5179 6.44525 5.81645 6.07697 5.81645C5.7087 5.81645 5.41016 5.5179 5.41016 5.14963V3.22541Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.15409 4.18752C5.23116 4.26147 5.3171 4.32625 5.41016 4.38011V5.14963C5.41016 5.5179 5.7087 5.81645 6.07697 5.81645C6.44525 5.81645 6.74379 5.5179 6.74379 5.14963V4.38011C6.83685 4.32625 6.92279 4.26147 6.99987 4.18752C6.92279 4.11357 6.83685 4.04879 6.74379 3.99494V3.22541C6.74379 2.85714 6.44525 2.55859 6.07697 2.55859C5.7087 2.55859 5.41016 2.85714 5.41016 3.22541C5.41016 3.22541 5.41016 3.22541 5.41016 3.22541V3.99493C5.3171 4.04879 5.23116 4.11357 5.15409 4.18752ZM8.74379 3.22541V5.14963C8.74379 6.62247 7.54982 7.81645 6.07697 7.81645C4.60413 7.81645 3.41016 6.62247 3.41016 5.14963V3.22541C3.41016 1.75257 4.60413 0.558594 6.07697 0.558594C7.54982 0.558594 8.74379 1.75257 8.74379 3.22541Z"
      fill={props.color ? props.color : "#CDEE24"}
    />
    <path
      d="M11.5039 3.22541C11.5039 2.85714 11.8025 2.55859 12.1707 2.55859C12.539 2.55859 12.8375 2.85714 12.8375 3.22541V5.13061C12.8375 5.49888 12.539 5.79743 12.1707 5.79743C11.8025 5.79743 11.5039 5.49888 11.5039 5.13061V3.22541Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.238 4.17801C11.3176 4.25593 11.4069 4.32396 11.5039 4.38011V5.13061C11.5039 5.49888 11.8025 5.79743 12.1707 5.79743C12.539 5.79743 12.8375 5.49888 12.8375 5.13061V4.38011C12.9346 4.32396 13.0239 4.25593 13.1034 4.17801C13.0239 4.10009 12.9346 4.03207 12.8375 3.97592V3.22541C12.8375 2.85714 12.539 2.55859 12.1707 2.55859C11.8025 2.55859 11.5039 2.85714 11.5039 3.22541C11.5039 3.22541 11.5039 3.22541 11.5039 3.22541V3.97592C11.4069 4.03207 11.3176 4.10009 11.238 4.17801ZM14.8375 3.22541V5.13061C14.8375 6.60345 13.6436 7.79743 12.1707 7.79743C10.6979 7.79743 9.50391 6.60345 9.50391 5.13061V3.22541C9.50391 1.75257 10.6979 0.558594 12.1707 0.558594C13.6436 0.558594 14.8375 1.75257 14.8375 3.22541Z"
      fill={props.color ? props.color : "#CDEE24"}
    />
    <path
      d="M17.4082 3.22541C17.4082 2.85714 17.7067 2.55859 18.075 2.55859C18.4433 2.55859 18.7418 2.85714 18.7418 3.22541V5.13061C18.7418 5.49888 18.4433 5.79743 18.075 5.79743C17.7067 5.79743 17.4082 5.49888 17.4082 5.13061V3.22541Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.1423 4.17801C17.2219 4.25593 17.3112 4.32396 17.4082 4.38011V5.13061C17.4082 5.49888 17.7067 5.79743 18.075 5.79743C18.4433 5.79743 18.7418 5.49888 18.7418 5.13061V4.38011C18.8389 4.32396 18.9282 4.25593 19.0077 4.17801C18.9282 4.10009 18.8389 4.03207 18.7418 3.97592V3.22541C18.7418 2.85714 18.4433 2.55859 18.075 2.55859C17.7067 2.55859 17.4082 2.85714 17.4082 3.22541C17.4082 3.22541 17.4082 3.22541 17.4082 3.22541V3.97592C17.3112 4.03207 17.2219 4.10009 17.1423 4.17801ZM20.7418 3.22541V5.13061C20.7418 6.60345 19.5479 7.79743 18.075 7.79743C16.6022 7.79743 15.4082 6.60345 15.4082 5.13061V3.22541C15.4082 1.75257 16.6022 0.558594 18.075 0.558594C19.5479 0.558594 20.7418 1.75257 20.7418 3.22541Z"
      fill={props.color ? props.color : "#CDEE24"}
    />
  </Icon>
);
