import { Heading } from "@chakra-ui/react";

export const HeadingPrimary = ({ heading }) => {
  return (
    <Heading
      fontSize="1.875rem"
      lineHeight="2.375rem"
      textAlign="left"
      color="black"
    >
      {heading}
    </Heading>
  );
};

export const HeadingSecondary = ({ heading }) => {
  return (
    <Heading
      textStyle="subHeadingText"
      fontSize="1.5rem"
      lineHeight="1.89rem"
      color="black"
      textAlign="left"
    >
      {heading}
    </Heading>
  );
};

export const HeadingTertiary = ({ heading }) => {
  return (
    <Heading
      textStyle="subHeadingText"
      fontSize="1.25rem"
      lineHeight="1.89rem"
      color="black"
      textAlign="left"
    >
      {heading}
    </Heading>
  );
};
