import { Box, Button, Flex, Text } from "@chakra-ui/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  CustomDocIcon,
  CustomDocxIcon,
  CustomImagePlaceholderIcon,
  CustomPdfIcon,
  CustomRemoveIcon,
} from "../../../../assets/icons";
import { getBase64Promise, urltoFile } from "../../../../utils/helpers";
import loadImage from "blueimp-load-image";

const TWENTY_MB = 20971520; // 20971520 = 20MB

const baseStyle = {
  // flex: 1,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  flexWrap: "wrap",
  padding: "2rem 2rem",
  border: "2px dashed #d7d7d7",
  borderRadius: "15px",
  backgroundColor: "#FFFFFF",
  outline: "none",
  transition: "border .24s ease-in-out",
  // position: "relative",
  minHeight: "8.75rem",
  width: "100%",
  // fontSize: "12px",
};

const acceptedProfilePhotoStyle = {
  // flex: 1,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "15px",
  textAlign: "center",
  flexWrap: "wrap",
  padding: "0.5rem",
  outline: "none",
  transition: "border .24s ease-in-out",
  width: "100%",
};

const acceptStyle = {
  border: "none",
  minHeight: "4.2rem",
  backgroundColor: "#F9F9F9",
  padding: "1.25rem 1rem",
};

const rejectStyle = {
  border: "2px dashed #ED4337",
};

const acceptDragStyle = {
  border: "2px dashed #CDEE24",
};

export const CreateCandidateDropzone = ({
  title,
  desc,
  accept,
  setError,
  setValue,
  value,
  valueObj,
  setValueObj,
  onBlurHandler,
  required,
  setIsFileDialogActive,
  rotateImg,
}) => {
  const [fileType, setFileType] = useState(null);

  const profilePhoto = title === "Drop a profile photo here";
  // when file is accepted
  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        if (rotateImg) {
          loadImage(
            file,
            function (img, data) {
              if (data?.imageHead && data?.exif) {
                // Reset Exif Orientation data:
                loadImage.writeExifData(data.imageHead, data, "Orientation", 1);
                img.toBlob(
                  function (blob) {
                    loadImage.replaceHead(
                      blob,
                      data.imageHead,
                      function (newBlob) {
                        file = newBlob;
                        setValueObj(file);
                      }
                    );
                  },
                  "image/jpeg",
                  "image/jpg",
                  "image/png"
                );
              } else {
                setValueObj(file);
              }
            },
            {
              meta: true,
              orientation: true,
              canvas: true,
            }
          );
        } else {
          setValueObj(file);
        }
        if (
          file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        )
          setFileType("docx");
        if (file.type === "application/msword") setFileType("doc");
        if (file.type === "application/pdf") setFileType("pdf");
      });
      setError(null);
    },
    [rotateImg, setError, setValueObj]
  );

  const callBase64Promise = useCallback(async () => {
    // client profile receives profile.photo.small (medium, big) params
    if (valueObj?.small) return;
    // candidate profile receives candidateCV.name (size, type) params
    // added prop flag to indicate that CV file already exists
    if (valueObj?.flag) return;
    // do this only on create candidate page
    if (valueObj && valueObj !== null) {
      const base64 = await getBase64Promise(valueObj);
      setValue(base64);
    }
  }, [valueObj, setValue]);

  useEffect(() => {
    callBase64Promise();
  }, [callBase64Promise, onDrop]);

  useEffect(() => {
    if (value && value !== null && profilePhoto) {
      urltoFile(value, valueObj?.name, valueObj?.type).then(function (file) {
        setValueObj(file);
      });
    } else {
      if (
        valueObj?.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      )
        setFileType("docx");
      if (valueObj?.type === "application/msword") setFileType("doc");
      if (valueObj?.type === "application/pdf") setFileType("pdf");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragReject,
    fileRejections,
    open,
    isFileDialogActive,
  } = useDropzone({
    onDrop,
    multiple: false,
    maxFiles: 1,
    maxSize: TWENTY_MB,
    accept,
    noClick: true,
    noKeyboard: true,
  });

  useEffect(() => {
    if (!profilePhoto) {
      setIsFileDialogActive(isFileDialogActive);
    }
  }, [isFileDialogActive, profilePhoto, setIsFileDialogActive]);

  // when file is rejected
  const rejectionCode = fileRejections[0]?.errors[0]?.code;
  useEffect(() => {
    let rejectionMsg = null;
    if (rejectionCode === "file-too-large") {
      rejectionMsg = "File is larger than 20mb";
      setError(rejectionMsg);
    }
    if (rejectionCode === "file-invalid-type" && title === "Drop a file here") {
      rejectionMsg = "File type must be pdf, doc or docx";
      setError(rejectionMsg);
    }
    if (
      rejectionCode === "file-invalid-type" &&
      title === "Drop a profile photo here"
    ) {
      rejectionMsg = "File type must be jpg or png";
      setError(rejectionMsg);
    }
  }, [rejectionCode, setError, title]);

  // remove file
  const removeDroppedFileHandler = () => {
    setValueObj(null);
    setValue(null);
    setError(null);
    if (required) setError(required);
  };

  const style = useMemo(
    () => ({
      ...(!profilePhoto ||
      (profilePhoto && (valueObj === null || valueObj === undefined))
        ? baseStyle
        : acceptedProfilePhotoStyle),
      ...(valueObj !== null && !profilePhoto ? acceptStyle : {}),
      ...(isDragAccept ? acceptDragStyle : {}),
      ...((isDragReject || fileRejections.length > 0) && !isDragAccept
        ? rejectStyle
        : {}),
    }),
    [profilePhoto, valueObj, isDragReject, fileRejections.length, isDragAccept]
  );

  const sizeInKb = Math.round(valueObj?.size / 1024);
  const sizeInMb = Math.floor(sizeInKb / 1024);
  const size = sizeInMb === 0 ? `${sizeInKb} KB` : `${sizeInMb} MB`;

  return (
    <div {...getRootProps({ style })}>
      <input {...getInputProps()} />
      {valueObj && valueObj !== null ? (
        profilePhoto ? (
          <Flex
            alignItems="center"
            justifyContent="center"
            flexDir={{ base: "column", sm: "row" }}
            gap={{ base: "1rem", sm: 0 }}
            mt={{ base: "0.2rem", sm: 0 }}
          >
            <Button
              fontSize="0.875rem"
              variant="solidBlack"
              className="cameraBtn"
              leftIcon={<CustomImagePlaceholderIcon boxSize="1.125rem" />}
              onClick={open}
              width={{ base: "11rem", md: "12rem" }}
              height={{ base: "2rem", md: "2.5rem" }}
            >
              Upload new image
            </Button>
            <Button
              height="fit-content"
              width="fit-content"
              variant="tableEditCompany"
              onClick={removeDroppedFileHandler}
            >
              Remove
            </Button>
          </Flex>
        ) : (
          <Flex justifyContent="space-between" alignItems="center" w="full">
            <Flex alignItems="center" justifyContent="center" gap="0.625rem">
              {title === "Drop a file here" && fileType === "pdf" && (
                <CustomPdfIcon boxSize="1.875rem" />
              )}
              {title === "Drop a file here" && fileType === "docx" && (
                <CustomDocxIcon boxSize="1.875rem" />
              )}
              {title === "Drop a file here" && fileType === "doc" && (
                <CustomDocIcon boxSize="1.875rem" />
              )}
              <Flex
                justifyContent="center"
                flexDir="column"
                alignItems="flex-start"
              >
                <Text
                  fontSize="0.875rem"
                  textStyle="bodyMedium"
                  className="truncate"
                  maxWidth={{ base: "200px", sm: "300px" }}
                >
                  {valueObj?.flag
                    ? `${valueObj.name}.${fileType}`
                    : valueObj.name}
                </Text>
                <Text
                  fontSize="0.875rem"
                  textStyle="bodyMedium"
                  color="mediumGrey"
                >
                  {size}
                </Text>
              </Flex>
            </Flex>
            <CustomRemoveIcon
              //py="0.2rem"
              boxSize="1.25rem"
              _hover={{ cursor: "pointer" }}
              onClick={removeDroppedFileHandler}
            />
          </Flex>
        )
      ) : (
        <Box>
          <Text color="mediumGrey">
            {title} or{" "}
            <Text
              as="span"
              color="limeText"
              onClick={open}
              transition="color 0.3s"
              _hover={{ cursor: "pointer", color: "#000" }}
              tabIndex="0"
              onBlur={onBlurHandler}
            >
              Browse
            </Text>
          </Text>
          <Box mt="0.875rem">
            <Text fontSize="0.688rem" color="mediumGrey">
              {desc}
            </Text>
            <Text fontSize="0.688rem" color="mediumGrey">
              Max file size 20mb.
            </Text>
          </Box>
        </Box>
      )}
    </div>
  );
};
