import { Icon } from "@chakra-ui/react";

export const CustomVerifiedCandidateIcon = (props) => (
  <Icon viewBox="0 0 18 20" fill="none" {...props}>
    <path
      d="M15.4667 17.3236L16.4616 17.4252C16.4871 17.1754 16.5001 16.9223 16.5001 16.6665C16.5001 14.2125 15.3052 12.0391 13.4678 10.6941C14.379 9.66597 14.9334 8.31242 14.9334 6.82913C14.9334 3.60979 12.3236 1 9.10429 1C5.88495 1 3.27516 3.60979 3.27516 6.82913C3.27516 8.3119 3.82923 9.66502 4.73981 10.693C2.90163 12.0379 1.70605 14.2119 1.70605 16.6665C1.70605 16.9223 1.71908 17.1754 1.74456 17.4252C1.82558 18.219 2.50381 18.7422 3.21856 18.7422H14.9876C15.7023 18.7422 16.3806 18.219 16.4616 17.4252L15.4667 17.3236Z"
      stroke="#CDEE24"
      strokeWidth="2"
    />
  </Icon>
);
