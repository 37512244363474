import { Box, Button, Circle, Flex, Text } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

const FooterTextContent = ({ type, ctaText, ctaW, alignment, ctaTo }) => {
  // can be centralized -> home, left aligned-> clients, candidates,
  // heading text, cta text, ctaW, ctaTo
  return (
    <Flex
      flexDir="column"
      w="full"
      gap="1.875rem"
      alignItems={{
        base:
          alignment === "center"
            ? "center"
            : alignment === "left"
            ? "flex-start"
            : null,
        lg:
          alignment === "center"
            ? "center"
            : alignment === "left"
            ? "flex-start"
            : null,
      }}
      mx={{ lg: "8rem" }}
      px="1rem"
    >
      {type === "home" ? (
        <Text
          textStyle="bodySemiBold"
          fontSize={{ base: "1.625rem", lg: "2.5rem" }}
          lineHeight={{ base: "1.95rem", lg: "3rem" }}
          color="white"
          textAlign="center"
          maxW={{ base: "18.25rem", lg: "48.226rem" }}
        >
          No need to hire a recruitment agency. Join SearcHive today.
        </Text>
      ) : type === "clients" ? (
        <Text
          textStyle="bodySemiBold"
          fontSize={{ base: "1.625rem", lg: "2.5rem" }}
          lineHeight={{ base: "1.95rem", lg: "3rem" }}
          color="white"
          textAlign="left"
          maxW={{ base: "10.5rem", lg: "36.563rem" }}
        >
          Hiring the best Finance & Banking talent has never been{" "}
          <Text as="span" position="relative">
            easier
            <Box
              as="span"
              position="absolute"
              w="full"
              h="full"
              top="0"
              left="0"
            >
              <Circle
                as="span"
                boxSize={{ base: "0.75rem", md: "1.125rem" }}
                bgColor="electricLime"
                position="absolute"
                bottom="20%"
                right={{ base: "-17px", md: "-25px" }}
              />
            </Box>
          </Text>
        </Text>
      ) : type === "candidates" ? (
        <Text
          textStyle="bodySemiBold"
          fontSize={{ base: "1.625rem", lg: "2.5rem" }}
          lineHeight={{ base: "1.95rem", lg: "3rem" }}
          color="white"
          textAlign="left"
          maxW={{ base: "13rem", lg: "50rem" }}
        >
          Join thousands of people who have secured their dream job with{" "}
          <Text as="span" position="relative">
            SearcHive
            <Box
              as="span"
              position="absolute"
              w="full"
              h="full"
              top="0"
              left="0"
            >
              <Circle
                as="span"
                boxSize={{ base: "0.75rem", md: "1.125rem" }}
                bgColor="electricLime"
                position="absolute"
                bottom="20%"
                right={{ base: "-17px", md: "-25px" }}
              />
            </Box>
          </Text>
        </Text>
      ) : null}
      <Button
        as={RouterLink}
        to={ctaTo}
        h={{ base: "2.6rem", lg: "3.063rem" }}
        w={ctaW}
        alt={ctaText}
      >
        {ctaText}
      </Button>
    </Flex>
  );
};

export default FooterTextContent;
