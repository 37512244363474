import { Grid, GridItem, Hide, Center } from "@chakra-ui/react";
import HeaderNavigation from "../../private/shared/navs/HeaderNavigation";
import ScrollToTop from "../../private/shared/UI/ScrollToTop";
import FooterNavigation from "../shared/navs/FooterNavigation";
import LoginImage from "./LoginImage";
import ClientCandidateHeaderNavigation from "../../private/shared/profile/ClientCandidateHeaderNavigation";
import ClientNotActivatedHeaderNavigation from "../../private/client/activation/HeaderNavigation";

const LayoutLogin = ({ children, scheme, page, userType, membershipStatus }) => {
  const templateColumns =
    page === "joinAsCandidate" ? { lg: "50vw auto" } : { lg: "1fr auto" };

  return (
    <>
      <ScrollToTop />
      <Grid
        templateColumns={templateColumns}
        position="relative"
        overflow={page === "joinAsCandidate" ? "hidden" : ""}
      >
        {userType === "client" && !membershipStatus ? <ClientNotActivatedHeaderNavigation /> : userType === "client" || userType === "candidate" ? (
          <ClientCandidateHeaderNavigation type={userType} />
        ) : (
          <HeaderNavigation />
        )}
        <GridItem
          m={{
            base: "8rem 2rem 3rem 2rem",
            sm: "8rem 2rem 10rem 2rem",
            lg: "8rem 1rem",
            xl: "11.4rem 1rem",
          }}
          maxW="23.25rem"
          w={{ base: "85%", lg: "25rem" }}
          //placeSelf="center"
          justifySelf="center"
          className="login_form"
        >
          {children}
        </GridItem>
        <Hide below="lg">
          {page === "joinAsCandidate" && (
            <GridItem
              placeSelf="center"
              bgColor="#f9f9f9"
              height="full"
              minHeight="100vh"
              width="full"
            >
              <Center height="full">
                <LoginImage page={page} />
              </Center>
            </GridItem>
          )}

          {page !== "joinAsCandidate" && (
            <GridItem>
              <LoginImage page={page} />
            </GridItem>
          )}
        </Hide>
        <FooterNavigation scheme={scheme} />
      </Grid>
    </>
  );
};

export default LayoutLogin;
