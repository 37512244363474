import { Icon } from "@chakra-ui/react";

export const CustomRejectedIcon = (props) => (
  <Icon viewBox="0 0 21 21" fill="none" {...props}>
    <path
      d="M10.5 0.958008C4.98977 0.958008 0.5 5.45115 0.5 10.9631C0.5 16.4751 4.98977 20.958 10.5 20.958C16.0102 20.958 20.5 16.4751 20.5 10.9631C20.5 5.45115 16.0102 0.958008 10.5 0.958008ZM10.5 3.04308C12.3543 3.04308 14.0567 3.67588 15.403 4.73723L4.25116 15.8311C3.20627 14.4897 2.58392 12.8019 2.58392 10.9627C2.58392 6.5775 6.11575 3.04259 10.4995 3.04259L10.5 3.04308ZM16.8568 6.23153C17.8372 7.54991 18.4161 9.18738 18.4161 10.9631C18.4161 15.3483 14.8843 18.8812 10.5005 18.8812C8.70966 18.8812 7.06148 18.2914 5.73765 17.2951L16.8563 6.23153H16.8568Z"
      fill={props.color}
    />
  </Icon>
);
