export const REGEX_CONTAINS_BOTH_LOWER_AND_UPPERCASE =
  /^(?=.*[a-z])(?=.*[A-Z]).+$/;
export const REGEX_HAS_SYMBOL = /(\W)+/;
export const REGEX_HAS_AT_LEAST_ONE_NUMBER = /[0-9]/;
export const REGEX_IS_VALID_URL =
  /^$|https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
// /^$|https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
export const REGEX_IS_VALID_DOMAIN =
  /^(https?:\/\/)?(www\.)?(?!www)([a-zA-Z0-9]+\.[a-zA-Z]{1,})(\/\S*)?$/;
  //  /^$|((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[ma-z0-9]{0,1}\.(xn--)?([a-z0-9-]{1,61}|[a-z0-9-]{1,30}\.[a-z]{2,})$/;

export const REGEX_IS_LESS_THAN_255 = /^.{0,255}$/;

export const REGEX_IS_MOBILE_NUMBER = /^(\+\d{1,3}[- ]?)?\d{10}$/;

export const REGEX_LINKEDIN_URL = "((https?://)?((www|ww).)?linkedin.com)";

export const addProtocolPrefixUrl = (url) => {
  if (url) {
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      url = "https://" + url;
    }
    return url;
  }
};

export function lengthInBetween(str, min, max) {
  return str === undefined || min === undefined || max === undefined
    ? false
    : str.trim().length >= min && str.trim().length <= max;
}

export function hasLowerAndUpperCase(str) {
  const pattern = new RegExp(REGEX_CONTAINS_BOTH_LOWER_AND_UPPERCASE);

  return pattern.test(str);
}

export function hasSymbol(str) {
  const pattern = new RegExp(REGEX_HAS_SYMBOL);

  return pattern.test(str);
}

export function hasDigit(str) {
  const pattern = new RegExp(REGEX_HAS_AT_LEAST_ONE_NUMBER);

  return pattern.test(str);
}
