import { Flex, Heading, Circle, Text, Box, Image } from "@chakra-ui/react";
import logoUrl from "../../../assets/vectors/logo.svg";
const SectionHeading = ({
  centered,
  twoLine,
  lineOneText,
  lineTwoText,
  lastWord,
  hasBottomElement,
  noCircle,
}) => {
  return twoLine ? (
    <Box>
      <Heading
        fontSize={{ base: "2rem", lg: "3.125rem" }} //50px
        lineHeight={{ base: "2.5rem", lg: "3.9375rem" }} //63px
        textTransform="uppercase"
        position="relative"
      >
        {lineOneText} <br /> {lineTwoText}{" "}
        <Text as="span" position="relative">
          {lastWord}
          <Box position="absolute" w="full" h="full" top="0" left="0">
            <Circle
              boxSize="1.125rem"
              bgColor="electricLime"
              position="absolute"
              bottom="15%"
              right="-25px"
            />
          </Box>
        </Text>
      </Heading>
    </Box>
  ) : (
    <Flex
      gap="1.125rem"
      mb="1.875rem"
      alignItems="flex-end"
      justifyContent={{
        base: "flex-start",
        lg: centered ? "center" : "flex-start",
      }}
      flexDirection="row"
    >
      <Heading
        fontSize={{ base: "2rem", lg: "3.125rem" }} //50px
        lineHeight={
          hasBottomElement ? "1" : { base: "2.5rem", lg: "3.9375rem" }
        } //63px
        textTransform="uppercase"
      >
        {lineOneText}{" "}
        <Text as="span" position="relative">
          {lastWord}
          {!noCircle && (
            <Box position="absolute" w="full" h="full" top="0" left="0">
              <Circle
                boxSize="1.125rem"
                bgColor="electricLime"
                position="absolute"
                bottom="15%"
                right="-25px"
              />
            </Box>
          )}
        </Text>
      </Heading>
      {hasBottomElement && (
        <Image src={logoUrl} w={{ base: "10rem", lg: "15.4375rem" }} />
      )}
      {hasBottomElement && (
        <Heading
          fontSize={{ base: "2rem", lg: "3.125rem" }} //50px
          lineHeight="1"
          ml="-15px"
        >
          ?
        </Heading>
      )}
    </Flex>
  );
};

export default SectionHeading;
