import { Icon } from "@chakra-ui/react";

export const CustomLogoutIcon = (props) => (
  <Icon viewBox="0 0 18 19" fill="none" {...props}>
    <path
      d="M16.375 10.1241C16.215 10.1241 16.0553 10.0632 15.9331 9.94102L11.7344 5.74258C11.4903 5.49852 11.4903 5.10289 11.7344 4.85883C11.9784 4.61477 12.3741 4.61477 12.6181 4.85883L16.8169 9.05727C17.0609 9.30133 17.0609 9.69695 16.8169 9.94102C16.6947 10.0629 16.535 10.1241 16.375 10.1241Z"
      fill="#CDEE24"
      stroke="#CDEE24"
      strokeWidth="0.8"
    />
    <path
      d="M12.1763 14.3234C12.0163 14.3234 11.8566 14.2624 11.7344 14.1402C11.4903 13.8962 11.4903 13.5005 11.7344 13.2565L15.9331 9.05805C16.1772 8.81398 16.5728 8.81398 16.8169 9.05805C17.0609 9.30211 17.0609 9.69773 16.8169 9.9418L12.6184 14.1402C12.4963 14.2621 12.3363 14.3234 12.1763 14.3234Z"
      fill="#CDEE24"
      stroke="#CDEE24"
      strokeWidth="0.8"
    />
    <path
      d="M16.375 10.125H4.5C4.155 10.125 3.875 9.845 3.875 9.5C3.875 9.155 4.155 8.875 4.5 8.875H16.375C16.72 8.875 17 9.155 17 9.5C17 9.845 16.72 10.125 16.375 10.125Z"
      fill="#CDEE24"
      stroke="#CDEE24"
      strokeWidth="0.8"
    />
    <path
      d="M2 17.625C1.31062 17.625 0.75 17.0644 0.75 16.375C0.75 16.03 1.03 15.75 1.375 15.75C1.72 15.75 2 16.03 2 16.375V16.3759C2.345 16.3759 2.625 16.6553 2.625 17.0006C2.625 17.3459 2.345 17.625 2 17.625Z"
      fill="#CDEE24"
      stroke="#CDEE24"
      strokeWidth="0.8"
    />
    <path
      d="M6.375 17.625H2C1.655 17.625 1.375 17.345 1.375 17C1.375 16.655 1.655 16.375 2 16.375H6.375C6.72 16.375 7 16.655 7 17C7 17.345 6.72 17.625 6.375 17.625Z"
      fill="#CDEE24"
      stroke="#CDEE24"
      strokeWidth="0.8"
    />
    <path
      d="M1.375 17C1.03 17 0.75 16.72 0.75 16.375V2.625C0.75 2.28 1.03 2 1.375 2C1.72 2 2 2.28 2 2.625V16.375C2 16.72 1.72 17 1.375 17Z"
      fill="#CDEE24"
      stroke="#CDEE24"
      strokeWidth="0.8"
    />
    <path
      d="M1.37469 3.25C1.02938 3.25 0.75 2.97 0.75 2.625C0.75 1.93562 1.31062 1.375 2 1.375C2.345 1.375 2.625 1.655 2.625 2C2.625 2.345 2.345 2.625 2 2.625H1.99906C1.99906 2.97 1.72 3.25 1.37469 3.25Z"
      fill="#CDEE24"
      stroke="#CDEE24"
      strokeWidth="0.8"
    />
    <path
      d="M6.375 2.625H2C1.655 2.625 1.375 2.345 1.375 2C1.375 1.655 1.655 1.375 2 1.375H6.375C6.72 1.375 7 1.655 7 2C7 2.345 6.72 2.625 6.375 2.625Z"
      fill="#CDEE24"
      stroke="#CDEE24"
      strokeWidth="0.8"
    />
  </Icon>
);
