import {
  Box,
  Center,
  Flex,
  Heading,
  Image,
  Link,
  Spinner,
  Text,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import logoUrl from "../../assets/vectors/logo.svg";
import logoSymbolUrl from "../../assets/vectors/logoSymbol.svg";
import verifyingEmailSrc from "../../assets/vectors/verifyingEmail.svg";

const Loading = ({ type }) => {
  const [isLargerThan480px] = useMediaQuery("(min-width: 480px)");

  const renderHeading = (type) => {
    switch (type) {
      case "verifyEmail":
        return "Verifying your email";
      default:
        return null;
    }
  };

  const renderDesc = (type) => {
    switch (type) {
      case "verifyEmail":
        return "Please be patient.";
      default:
        return null;
    }
  };

  return (
    <Box
      position="fixed"
      top="0"
      bottom="0"
      left="0"
      right="0"
      height="100vh"
      maxHeight="100%"
      overflow="hidden"
      bg="backgroundGrey"
      zIndex="1"
    >
      <Flex
        as="header"
        w="full"
        alignItems="center"
        justifyContent={{ base: "center", sm: "space-between" }}
        p={{ base: "1rem 1rem", lg: "1.5rem 3.75rem" }}
        position="absolute"
        top="0"
        left="0"
        zIndex="2"
      >
        <Link as={RouterLink} to="/">
          <Image
            src={isLargerThan480px ? logoUrl : logoSymbolUrl}
            loading="lazy"
          />
        </Link>
      </Flex>
      {/* 130px -> 8.2rem */}
      <Center w="full" h="full" position="relative">
        {type && (
          <VStack position="absolute" top="20%" textAlign="center">
            <Heading
              fontSize={{ base: "2rem", sm: "3.125rem" }} //50px
              lineHeight={{ base: "3rem", sm: "3.9rem" }} //63px
              mb="0.5rem"
            >
              {renderHeading(type)}
            </Heading>
            <Text fontSize="1.375rem" lineHeight="1.625rem">
              {renderDesc(type)}
            </Text>
          </VStack>
        )}
        {type === "verifyEmail" && (
          <Image
            src={verifyingEmailSrc}
            loading="lazy"
            boxSize="4.5rem"
            position="absolute"
          />
        )}
        <Spinner
          boxSize={type === "verifyEmail" ? "8.2rem" : "3.5rem"}
          thickness="4px"
          speed="1s"
          emptyColor="electricLime"
          mx="auto"
          position="absolute"
        />
      </Center>
    </Box>
  );
};

export default Loading;
