import { menuAnatomy as parts } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from "@chakra-ui/styled-system";

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyleList = defineStyle({
  //   color: "#000",
  color: "white",
  //   minW: "3xs",
  py: "0",
  //   zIndex: 1,
  borderRadius: "10px",
  borderWidth: "1px",
  borderColor: "semiDarkGrey",
  bg: "black",
  boxShadow: "0px 1px 8px rgba(0, 0, 0, 0.1);",
});

const baseStyleItem = defineStyle({
  //   py: "1.5",
  //   px: "3",
  //   transitionProperty: "background",
  //   transitionDuration: "ultra-fast",
  //   transitionTimingFunction: "ease-in",

  bg: "black",
  _focus: {
    bg: "darkGrey",
  },

  _active: {
    bg: "darkGrey",
  },

  //   _expanded: {
  //     color: "green",
  //   },
  //   _disabled: {
  //     opacity: 0.4,
  //     cursor: "not-allowed",
  //   },
});

const baseStyleGroupTitle = defineStyle({
  mx: 4,
  my: 2,
  fontWeight: "semibold",
  fontSize: "sm",
});

const baseStyleCommand = defineStyle({
  opacity: 0.6,
});

const baseStyleDivider = defineStyle({
  border: 0,
  borderBottom: "1px solid",
  borderColor: "inherit",
  my: "2",
  opacity: 0.6,
});

const baseStyleButton = defineStyle({
  transitionProperty: "common",
  transitionDuration: "normal",
});

const baseStyle = definePartsStyle({
  button: baseStyleButton,
  list: baseStyleList,
  item: baseStyleItem,
  groupTitle: baseStyleGroupTitle,
  command: baseStyleCommand,
  divider: baseStyleDivider,
});

export const Menu = defineMultiStyleConfig({
  baseStyle,
});
