import { useSearchParams, Navigate } from "react-router-dom";

const MembershipOriginCheck = ({ children }) => {
  let [searchParams] = useSearchParams();
  let membershipType = searchParams.get("clientPlan");

  return membershipType === "low_volume" ||
    membershipType === "medium_high_volume" || membershipType === "outsourced_services_volume" ? (
    children
  ) : (
    <Navigate to="/membership" />
  );
};

export default MembershipOriginCheck;
