import { Icon } from "@chakra-ui/react";

export const CustomLimeRingIcon = (props) => (
  <Icon viewBox="0 0 33 32" fill="none" {...props}>
    <path
      d="M16.3975 31.7114C7.77524 31.7114 0.720703 24.8158 0.720703 16.2808C0.720703 7.74575 7.72625 0.898438 16.3975 0.898438C25.0687 0.898438 32.0742 7.79397 32.0742 16.329C32.0742 24.864 25.0197 31.7114 16.3975 31.7114ZM16.3975 7.98686C11.7434 7.98686 7.92221 11.6998 7.92221 16.329C7.92221 20.9582 11.6944 24.6229 16.3975 24.6229C21.1005 24.6229 24.8237 20.91 24.8237 16.329C24.8237 11.7481 21.0515 7.98686 16.3975 7.98686Z"
      fill="#CDEE24"
    />
  </Icon>
);
