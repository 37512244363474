import { Flex, Box, Heading, Text, Center } from "@chakra-ui/react";
import MembershipCard from "../../components/public/membership/MembershipCard";
import MembershipFeatures from "../../components/public/membership/MembershipFeatures";
import MembershipServices from "../../components/public/membership/MembershipServices";

const MembershipPage = () => {
  return (
    <>
      <Box
        textAlign="center"
        mb={{ base: "2.5rem", lg: "3.75rem" }}
        px="1.5rem"
      >
        <Heading
          as="h2"
          fontSize={{ base: "2rem", sm: "3.125rem" }}
          lineHeight={{ base: "2.5rem", sm: "3.9rem" }}
          mb="1.875rem"
        >
          Join Now
        </Heading>
        <Center>
          {/* <Box maxWidth="53.125rem">
            <Text
              fontSize={{ base: "1.125rem", lg: "1.25rem" }}
              lineHeight={{ base: "1.45rem", lg: "1.625rem" }}
              color="darkGrey"
            >
              SearcHive has been built to{" "}
              <Text as="span" fontWeight="700">
                support direct hiring of Finance talent
              </Text>{" "}
              in companies of all shapes and sizes. Before creating your account
              please tell us the levels of Finance recruitment you anticipate
              over the next 12 months.
            </Text>
          </Box> */}
          <Box maxWidth="54rem">
            <Text
              fontSize={{ base: "0.9rem", md: "1rem" }}
              lineHeight="1.4rem"
              color="darkGrey"
            >
              You are now well on your way to discovering the best Finance and
              Banking talent here on SearcHive!
              <br />
              Regardless of company size,
              budget or resources we have a solution for you all.
            </Text>
          </Box>
        </Center>
      </Box>

      <Box mb={{ base: "5rem", md: "5rem" }}>
        <Flex
          direction={{ base: "column", lg: "row" }}
          alignItems={{ base: "center", lg: null }}
          justifyContent={{ base: null, lg: "center" }}
          gap={{ base: "2.5rem", md: "2.5rem" }} // 40px , 60px
          mx={{ base: "1rem", md: null }}
          flexWrap="wrap"
        >
          <MembershipCard type="annual" />
          <MembershipCard type="monthly" />
          <MembershipCard type="outsourced" />
          <MembershipCard type="demo" />
        </Flex>
      </Box>

      <MembershipFeatures />
      {/* <MembershipServices /> */}
    </>
  );
};

export default MembershipPage;
